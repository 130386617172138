import {nString, uNumber, uString} from "@hidat/huijs-interfaces";
import {BrandedResource} from "@splixer/interfaces";
import {
  PlayerHost,
  PlayerMix,
  PlayerProgram,
  PlayerProgramBase,
  PlayerSession,
  PlayerShow,
  PlayerShowBase
} from "@splixer/player";

/**
 * Class used to denormalize a Branded resource so we can display it consistently.
 * Focused more on the session/mix, but can be used for others as well...
 */
export class FlatBrandedContent {
  public loaded = false;

  public id: uString;
  public name: uString;
  public parentName: uString;
  public description: nString;
  public tagLine: nString;
  public hostName: nString;
  public primaryImageUrl: nString;
  public avatarImageUrl: nString;
  public bannerImageUrl: nString;
  public credits: nString;
  public videoLink: uString;
  public blogLink: uString;
  public contentId: uString;
  public duration: uNumber;

  public show: PlayerShowBase | undefined;
  public program: PlayerProgramBase | undefined;
  public host: PlayerHost | undefined;
  public relatedContent: PlayerMix [] | undefined;

  constructor(item?: BrandedResource) {
    if (item) {
      if (item instanceof PlayerMix) {
        this.fromMix(item);
      } else if (item instanceof PlayerSession) {
        this.fromSession(item);
      } else if (item instanceof PlayerProgram) {
        this.fromProgram(item)
      } else if (item instanceof PlayerShow) {
        this.fromShow(item);
      }
    }
  }

  public fromMix(item: PlayerMix) {
    //console.debug('flattening mix')
    this.id = item.uid;
    this.name = item.name;
    this.tagLine = item.tagLine;
    this.description = item.description;
    this.primaryImageUrl = item.imageUrl;
    if (item.branding) {
      this.bannerImageUrl = item.branding.avatarImageUrl;
      this.avatarImageUrl = item.branding.avatarImageUrl;
    }
    this.contentId = item.uid;
    this.duration = item.duration;

    this.program = item._program;
    if (!this.show) {
      this.show = item._show as PlayerShow;
    }

    if (this.show) {
      if (!this.tagLine) {
        this.tagLine = this.show.tagLine;
      }
      if (!this.description) {
        this.description = this.show.description;
      }
      if (!this.primaryImageUrl) {
        this.primaryImageUrl = this.show.imageUrl;
      }
      if (!this.hostName) {
        this.hostName = this.show.hostName;
      }

      if (this.show.branding) {
        const branding = this.show.branding;
        this.credits = branding.credits;
        if (!this.bannerImageUrl) {
          this.bannerImageUrl = branding.avatarImageUrl;
        }
        if (!this.avatarImageUrl) {
          this.avatarImageUrl = branding.avatarImageUrl;
        }

        if (branding.urls) {
          const urls = branding.urls;
          if (urls) {
            this.blogLink = urls['blog_link'];
            this.videoLink = urls['video_link']
          }
        }
      }
    }

    if (this.program) {
      this.parentName = this.program.name;
      if (this.program.branding) {
        const branding = this.program.branding;
        if (!this.bannerImageUrl) {
          this.bannerImageUrl = branding.avatarImageUrl;
        }
        if (!this.avatarImageUrl) {
          this.avatarImageUrl = branding.avatarImageUrl;
        }
      }
    }

    if (!this.hostName && item._host) {
      this.host = item._host;
      this.hostName = item._host.name;
    }

    if (!this.relatedContent) {
      this.relatedContent = item._relatedContent;
    }
    this.loaded = true;

  }

  /**
   * Loads from the session, which better have a current path, or we aren't going to have much info
   * @param item
   */
  public fromSession(item: PlayerSession) {
    if (item._relatedContent) {
      this.relatedContent = item._relatedContent;
    }

    if (item.currentPath) {
      this.fromMix(item.currentPath);
    }

    this.loaded = true;
  }

  public fromShow(item: PlayerShow) {
    this.show = item;
    this.id = item.id?.toString();
    this.name = item.name;
    this.tagLine = item.tagLine;
    this.description = item.description;
    this.primaryImageUrl = item.imageUrl;
    if (item.branding) {
      this.bannerImageUrl = item.branding.avatarImageUrl;
      this.avatarImageUrl = item.branding.avatarImageUrl;
    }

    /*
    this.program = item._program;
    if (this.program) {
      this.parentName = this.program.name;
    }*/
    this.relatedContent = item._mixes;

    this.loaded = true;
  }

  public fromProgram(item: PlayerProgram) {
    this.program = item;
    this.id = item.id?.toString();
    this.name = item.name;
    this.tagLine = item.tagLine;
    this.description = item.description;
    this.primaryImageUrl = item.imageUrl;
    if (item.branding) {
      this.bannerImageUrl = item.branding.avatarImageUrl;
      this.avatarImageUrl = item.branding.avatarImageUrl;
    }
    this.relatedContent = item._mixes;
    if (item._lastShowMix) {
      this.contentId = item._lastShowMix.uid;
      this.duration = item._lastShowMix.duration;
    }
    this.loaded = true;
  }

  public hasRelatedContent(): boolean {
    return !!this.relatedContent && this.relatedContent.length > 0
  }

}
