import {JsonObject, JsonProperty} from 'json2typescript';
import {PlayerHost} from "./host.model";
import {PlayerShowBase} from "./show-base.model";
import {PlayerMix} from "./player-mix.model";
import {PlayerProgramBase} from "./program-base.model";


@JsonObject('PlayerShow')
export class PlayerShow extends PlayerShowBase {
  @JsonProperty('_host', PlayerHost, true)
  _host: PlayerHost | undefined = undefined;
  //@JsonProperty('_program', PlayerProgramBase)
  //_program: PlayerProgramBase | undefined = undefined;
  @JsonProperty('_mixes', [PlayerMix], true)
  _mixes: PlayerMix [] = [];
  @JsonProperty('_primary_mix', PlayerMix, true)
  _primaryMix: PlayerMix | undefined = undefined;
}
