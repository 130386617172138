//import * as dayjs from "dayjs";
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);


/**
 * Date Utilities
 * Static class full of useful date handling routines.
 * * Convert Dates (any type) to strings
 * * Convert date types to other date types
 * * Parse Date Strings
 */
export class DayJsUtils {
  static timeFormat = "HH:mm";
  static timeWithSecondsFormat = "HH:mm:ss";
  static shortDateFormat = "MM/DD/YYYY";
  static mediumDateFormat = "MMM D YYYY";
  static longDateFormat = "MMMM D YYYY";

  /**
   * Format Date
   * Formats the given date type object.
   * First converts 'value' to a Dayjs object, and then applies the given format.
   * We only include the 'English' formatter in the based class, so all formatting will be in english.
   * @param value
   * @param formatStr  A dayjs formatting string.
   * @private
   */
  static formatDate(value: unknown, formatStr: string): string | undefined {
    let r: string | undefined;
    if (value) {
      const m = this.toDayjs(value);
      if (m) {
        r = m.format(formatStr);
      }
    }
    return r;
  }

  static longDate(value: unknown): string | undefined {
    return this.formatDate(value, this.longDateFormat);
  }

  static longDateTime(value: unknown, showSeconds = false): string | undefined {
    let fmt: string;

    if (showSeconds) {
      fmt = this.longDateFormat + ' ' + this.timeWithSecondsFormat;
    } else {
      fmt = this.longDateFormat + ' ' + this.timeFormat;
    }
    return this.formatDate(value, fmt);
  }

  static mediumDate(value: unknown): string | undefined {
    return this.formatDate(value, this.mediumDateFormat);
  }

  static shortDate(value: unknown): string | undefined {
    return this.formatDate(value, this.shortDateFormat);
  }

  static mediumDateTime(value: unknown, showSeconds = false): string | undefined {
    let fmt: string;
    if (showSeconds) {
      fmt = this.mediumDateFormat + ' ' + this.timeWithSecondsFormat;
    } else {
      fmt = this.mediumDateFormat + ' ' + this.timeFormat;
    }
    return this.formatDate(value, fmt);
  }

  /**
   * To Date Time String
   * Converts the given date to an ISO Date/Time string
   * @param val
   */
  static toIsoDateStr(val: unknown): string | undefined {
    let res: string | undefined;
    if (val) {
      const dj = this.toDayjs(val);
      if (dj) {
        res = dj.toISOString();
      }
    }
    return (res);
  }

  /**
   * To Javascript Date
   * Converts the given val to a javascript date.
   * @param val
   */
  static toJSDate(val: unknown): Date | undefined {
    let res: Date | undefined;
    if (val) {
      if (val instanceof Date) {
        res = val;
      } else {
        const dj = this.toDayjs(val);
        if (dj) {
          res = dj.toDate();
        }
      }
    }
    return (res);
  }

  /**
   * To Dayjs
   * Attempts to convert the given val to a Dayjs.
   * Current val can be a string, JS Date, or an existing Dayjs.
   * If val is a string, and dateFormat is given, this will attempt to parse with the given format.
   * @param val
   * @param dateFormat
   */
  static toDayjs(val: unknown, dateFormat?: string): dayjs.Dayjs | undefined {
    let res: dayjs.Dayjs | undefined;
    if (val) {
      if (dayjs.isDayjs(val)) {
        res = val;
      } else if (val instanceof Date) {
        res = dayjs(val);
      } else if (typeof val === 'string') {
        try {
          res = dayjs(val, dateFormat);
        } catch (e) {
          console.error('Error parsing Date for: ', val);
          if (e instanceof Error) {
            console.warn(e.toString());
          }
        }
      } else {
        console.warn("Invalid type passed into toDayjs");
      }
      // If we didn't get a valid dayjs, set to undefined
      if (res && !res.isValid()) {
        res = undefined;
      }

    }
    return (res);
  }

  /**
   * Are Equal
   * Checks to see if the two dates are 'equal'.
   * This is optimized for the standpoint of 'has anything changed', so if both dates are null/undefined, will return true.
   * @param d1
   * @param d2
   */
  static areEqual(d1: unknown, d2: unknown): boolean {
    return (this.compare(d1, d2) === 0)
  }

  /**
   * Not Equal
   * Convenience function to check if the two dates are NOT equal.
   * @param d1
   * @param d2
   */
  static notEqual(d1: unknown, d2: unknown): boolean {
    return (this.compare(d1, d2) !== 0);
  }

  /**
   * Less Than
   * Returns true if d1 < d2
   * @param d1
   * @param d2
   */
  static lessThan(d1: unknown, d2: unknown): boolean {
    return (this.compare(d1, d2) < 0);
  }

  /**
   * Greater Than
   * Returns true if d1 > d2
   * @param d1
   * @param d2
   */
  static greaterThan(d1: unknown, d2: unknown): boolean {
    return (this.compare(d1, d2) > 0);
  }

  /**
   * Compare
   * Compares two dates, and returns the standard -1/0/1.
   * If both dates are null/undefined, will return 0.
   * If one is not null, returns the side that is not null
   * @param d1
   * @param d2
   */
  static compare(d1: unknown, d2: unknown): number {
    let res = 0;
    let dt1: dayjs.Dayjs | undefined;
    let dt2: dayjs.Dayjs | undefined;

    if (d1) {
        dt1 = this.toDayjs(d1);
    }

    if (d2) {
        dt2 = this.toDayjs(d2);
    }

    // If both are undefined, then we treat them as equal
    if (!d1 && !d2) {
      return 0;
    } else if (!d1) {
      return -1;
    } else if (!d2) {
      return 1;
    }

    // If both are defined, then just do a simple comparison
    if (dt1 && dt2) {
      if (dt1.isBefore(dt2)) {
        res = -1;
      } else if (dt1.isAfter(dt2)) {
        res = 1;
      }
    }
    return res;
  }

}
