import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {AnyHash} from "@hidat/huijs-interfaces";

/**
 * Notify Event
 * Basic event for notifications coming through the service
 */
export interface NotifyEvent {
  source?: string;
  klass: string;
  target?: number;
  eventId?: number;
  level?: number;
  message?: string;
  extra?: AnyHash;
}

/**
 * Base notification klasses, feel free to expand on these
 */
export enum NotificationKlass {
  MESSAGE = 'Message',
  ALERT = 'Alert',
  EVENT = 'Event',
  HTTP = 'HTTP'
}

export enum MessageLevels {
  DEBUG,
  INFO,
  WARN,
  ERROR
}

/**
 * Message Notifier Service
 * A simple message bus that can be used globally to send generic events and semi-structured messages to anybody that
 * wants to listen.  This gives library services a generic way to communicate status with the application without
 * creating tons of subscriptions.
 * This service should only be used for irregular events, as there may be a lot of listeners and that could cause efficiency issues.
 *
 * In general, there will be one listener for messages that is implemented at the top of component hierarchy,
 * which will display toasts and inline messages based on the UI framework you are using (Bootstrap, Prime...).
 */
@Injectable({providedIn: "root"})
export class MessageNotifierService {
  public readonly eventSource = new Subject<NotifyEvent>();
  public readonly messageSource = new Subject<NotifyEvent>();

  // Need a way to config these
  private sendDebugMessages = true;  // Do you want debug messages sent over the wire
  private logMessages = true;  // Do you want all messages logged to the console

  /********** Standard Message Notifications **************************************************************************/

  /**
   * Send Event
   * Generic event sender.
   * @param notifyEvent
   */
  public sendEvent(notifyEvent: NotifyEvent): void {
    this.eventSource.next(notifyEvent);
  }

  /**
   * Send Message
   * Generic message sender.  Primarily used internally, but if you have something special that your app expects,
   * you can craft a non-standard message using this.
   * @param notifyEvent
   */
  public sendMessage(notifyEvent: NotifyEvent): void {
    if (this.logMessages) {
      console.log(notifyEvent.message);
    }
    this.messageSource.next(notifyEvent);
  }

  /**
   * Debug message
   * These are generally not displayed, but may be run through the console or an event logging service.
   * @param message  String Message
   * @param source  Optional source
   * @param eventId Optional source dependent event ID, that the consumer can use to add additional information to the message
   * @param extra  Optional generic extra information hash
   */
  debug(message: string, source?: string, eventId?: number, extra?: AnyHash) {
    if (this.sendDebugMessages) {
      this.sendMessage({
        klass: NotificationKlass.MESSAGE,
        level: MessageLevels.DEBUG,
        source,
        message,
        eventId,
        extra
      })
    } else if (this.logMessages) {
      console.log(message);
    }
  }

  /**
   * Info Message
   * Sends an information message, which in general will be displayed in a toast.
   * @param message  String Message
   * @param source  Optional source
   * @param eventId Optional source dependent event ID, that the consumer can use to add additional information to the message
   * @param extra  Optional generic extra information hash
   */
  info(message: string, source?: string, eventId?: number, extra?: AnyHash) {
    this.sendMessage({
      klass: NotificationKlass.MESSAGE,
      level: MessageLevels.INFO,
      source,
      message,
      eventId,
      extra
    })
  }

  /**
   * Warning Message
   * Sends an warning message, which in general will be displayed in a toast with a longer timeout.
   * @param message  String Message
   * @param source  Optional source
   * @param eventId Optional source dependent event ID, that the consumer can use to add additional information to the message
   * @param extra  Optional generic extra information hash
   **/
  warn(message: string, source?: string, eventId?: number, extra?: AnyHash) {
    this.sendMessage({
      klass: NotificationKlass.MESSAGE,
      level: MessageLevels.WARN,
      source,
      message,
      eventId,
      extra
    })
  }

  /**
   * Error Message
   * Sends an error message, which in general will be displayed as a toast that requires the user to acknowledge.
   * @param message  String Message
   * @param source  Optional source
   * @param eventId Optional source dependent event ID, that the consumer can use to add additional information to the message
   * @param extra  Optional generic extra information hash
   */
  error(message: string, source?: string, eventId?: number, extra?: AnyHash) {
    this.sendMessage({
      klass: NotificationKlass.MESSAGE,
      level: MessageLevels.ERROR,
      source,
      message,
      eventId,
      extra
    })
  }

  /**
   * Alert
   * Sends an alert, which is generally handled as an inline or pop over alert.
   * @param message  String Message
   * @param level   Level for alert, defaults to INFO
   * @param source  Optional source
   * @param eventId Optional source dependent event ID, that the consumer can use to add additional information to the message
   * @param extra  Optional generic extra information hash
   */
  alert(message: string, level = MessageLevels.INFO, source?: string, eventId?: number, extra?: AnyHash) {
    this.sendMessage({
      klass: NotificationKlass.ALERT,
      level,
      source,
      message,
      eventId,
      extra
    })
  }

}


