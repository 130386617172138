import {Component, inject, OnInit} from "@angular/core";
import {PlayerProgram} from "@splixer/player";
import {Title} from "@angular/platform-browser";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {PlayPageController} from "./play-page.controller";

/**
 * Play Program - fetches the given program and then starts playing the most recent mix
 * We should be able to do this all in a guard or resolver, but for now....
 */
@Component({
  selector: 'pv2-program-play-page',
  templateUrl: 'play.page.html'
})
export class Pv2ProgramPlayPage extends PlayPageController implements OnInit {
  public titleService = inject(Title);
  public item: PlayerProgram | undefined;
  public hostName: string | undefined;

  ngOnInit() {
    // Data should be in the router
    this.activatedRoute.data.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(({program}) => {
      this.loadProgram(program);
    })
  }

  private loadProgram(results: PlayerProgram) {
    if (results) {
      this.item = results as PlayerProgram;
      this.titleService.setTitle('Load Show');
      if (this.item._mixes && this.item._mixes.length > 0) {
        const mix = this.item._mixes[0];
        if (mix && mix.uid) {
          this.loadingMessage = "Starting playback of your requested show";
          this.playMix(mix.uid);
        }
      } else {
        this.loadingMessage = "We're sorry, but we could not find any available shows for " + this.item.name;
      }
    } else {
      this.loadingMessage = "We're sorry, but we could not find the given show."
    }
  }

}
