import {JsonObject, JsonProperty, PropertyConvertingMode} from 'json2typescript';
import {nString, uNumber} from '@hidat/huijs-interfaces';
import {PlayerProgramInterface, ProgramBrandingInterface, ProgramKlasses} from "@splixer/interfaces";
import {DayJSConverter} from "@hidat/ng-api";
import * as dayjs from "dayjs";
import {PlayerExtendedBranding} from "./extended-branding.model";

@JsonObject('PlayerPropertyBranding')
export class PlayerProgramBranding extends PlayerExtendedBranding implements ProgramBrandingInterface {
  @JsonProperty('broadcast_times', String, PropertyConvertingMode.IGNORE_NULLABLE)
  broadcastTimes?: nString = null;
  @JsonProperty('keyword', String, PropertyConvertingMode.IGNORE_NULLABLE)
  keyword?: nString = undefined;
}

/**
 * Player version of the program, without any includes.
 * This helps us avoid circular references
 */
@JsonObject('PlayerProgramBase')
export class PlayerProgramBase implements PlayerProgramInterface {
  @JsonProperty('uid', String, true)
  id: number | undefined = undefined;

  @JsonProperty('name', String, true)
  name: string | undefined = undefined;
  @JsonProperty('description', String, PropertyConvertingMode.IGNORE_NULLABLE)
  description: nString = undefined;
  @JsonProperty('tag_line', String, PropertyConvertingMode.IGNORE_NULLABLE)
  tagLine: nString = undefined;
  @JsonProperty('image_url', String, PropertyConvertingMode.IGNORE_NULLABLE)
  imageUrl: nString = undefined;
  @JsonProperty('extended', PlayerProgramBranding, true)
  branding: PlayerProgramBranding = {};
  @JsonProperty('short_code', String, PropertyConvertingMode.IGNORE_NULLABLE)
  shortCode: nString = undefined;
  @JsonProperty('priority', Number, PropertyConvertingMode.IGNORE_NULLABLE)
  priority: uNumber = undefined;
  @JsonProperty('url_stub', String, PropertyConvertingMode.PASS_NULLABLE)
  urlStub: nString = undefined;

  @JsonProperty('klass', String, PropertyConvertingMode.IGNORE_NULLABLE)
  klass: ProgramKlasses | undefined | null = undefined;
  @JsonProperty('licensing_klass', String, true)
  licensingKlass: string | undefined = undefined;
  @JsonProperty('valid_segments', Number, true)
  validSegments = 0;
  @JsonProperty('duration', Number, true)
  duration = 0;

  @JsonProperty('created_at', DayJSConverter, true)
  createdAt: dayjs.Dayjs | undefined = undefined;
  @JsonProperty('updated_at', DayJSConverter, true)
  updatedAt: dayjs.Dayjs | undefined = undefined;
  @JsonProperty('status', Number, true)
  status = 100;
}
