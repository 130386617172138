import {Component, Input, OnChanges} from "@angular/core";
import {PlayerMix} from "@splixer/player";
import {Pv2ContentMenuItem} from "../../menu/menu-item.interface";

const template = `
  @if (items && menuItems) {
    <pv2-playable-list [items]="menuItems"
      [displayPlayButtons]="displayPlayButtons" [displayDurations]="displayDurations"></pv2-playable-list>
  }
`

/**
 * Displays a list of playable links for the given list of mixes
 * No link to the associated content is provided
 */
@Component({
  selector: 'pv2-mix-list',
  template
})
export class MixListComponent implements OnChanges {
  @Input() items: PlayerMix [] | undefined;
  @Input() displayPlayButtons = true;
  @Input() displayTagLines = true;
  @Input() displayDurations = true;

  protected menuItems: Pv2ContentMenuItem [] = [];

  ngOnChanges() {
    this.buildMenuItems();
  }

  protected buildMenuItems() {
    const menuItems: Pv2ContentMenuItem [] = [];
    if (this.items) {
      for (const item of this.items) {
        if (item.uid) {
          const mi: Pv2ContentMenuItem = {
            id: item.uid,
            name: item.name ?? 'No Name!',
            duration: item.duration
          }
          if (this.displayPlayButtons) {
            mi.contentId = item.uid;
          }
          if (this.displayTagLines) {
            mi.tagLine = item.tagLine;
          }
          menuItems.push(mi);
        }
      }
    }
    this.menuItems = menuItems;
  }
}
