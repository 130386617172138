import {Injectable} from "@angular/core";
import {MessageLevels, MessageNotifierService, NotificationKlass, NotifyEvent} from "@hidat/ng-common";
import {Subscription} from "rxjs";

/**
 * Toast info, should only be used by the toast component.
 */
export interface ToastInfo {
  title?: string;
  message: string;
  delay: number;
  canClose?: boolean;
  className: string;
}

@Injectable({providedIn: "root"})
export class NotifyService {
  public toasts: ToastInfo[] = [];
  private defaultDelayMs = 5000;
  private messageNotifierSubscription: Subscription;

  constructor(private messageNotifier: MessageNotifierService) {
    this.messageNotifierSubscription = this.messageNotifier.messageSource.subscribe(this.handleMessage);
  }

  private handleMessage = (msg: NotifyEvent) => {
    console.log('Message received: ', msg.message)
    if (msg.klass === NotificationKlass.MESSAGE) {
      if (msg.message) {
        switch (msg.level) {
          case MessageLevels.INFO:
            this.info(msg.message);
            break;
          case MessageLevels.WARN:
            this.warn(msg.message);
            break;
          case MessageLevels.ERROR:
            this.error(msg.message);
            break;
        }
      }
    }
  }

  /**
   * Removes the given toast
   * @param toast
   */
  public remove(toast: ToastInfo) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  public info(message: string, title?: string, delay?: number): void {
    this.toasts.push({
      className: 'bg-info',
      message,
      title,
      delay: delay ?? this.defaultDelayMs,
    })
  }

  public success(message: string, title?: string, delay?: number): void {
    this.toasts.push({
      className: 'bg-success text-light',
      message,
      title,
      delay: delay ?? this.defaultDelayMs,
    })
  }

  public warn(message: string, title?: string, delay?: number): void {
    this.toasts.push({
      className: 'bg-warning',
      message,
      title,
      delay: delay ?? this.defaultDelayMs * 2,
      canClose: true
    })
  }

  public error(message: string, title?: string): void {
    this.toasts.push({
      className: 'bg-danger text-light',
      message,
      title,
      delay: 0,
      canClose: true
    })
  }

}
