@if (nowPlaying) {
  <div class="current-track">
    @if (imageUrl) {
      <div class="image-column">
        <img [src]="imageUrl" alt="Now Playing Image">
      </div>
    }

    <div class="content-column">
      <div class="headline">
        <div class="show-name">{{ headlineLeft }}</div>
        <div class="content-host-name">{{ headlineRight }}</div>
      </div>

      @if (content) {
        <pv2-track-info [content]="content"></pv2-track-info>
      } @else {
        <div class="alt-content">
          {{ altContent }}
        </div>
      }
    </div>
  </div>
}
