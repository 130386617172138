@if (flatItem) {
  <div class="mix-details branded-content">
    @if (displayImage && flatItem.primaryImageUrl) {
      <div class="primary-image">
        <img [src]="flatItem.primaryImageUrl" [title]="flatItem.name" [alt]="flatItem.name">
      </div>
    }
    @if (displayName === 'header') {
      <div class="section-divider">
        <h4>{{ flatItem.name }}</h4>
      </div>
    }

    <div class="details-container">
      @if (displayName === 'inline') {
        <div class="content-name">{{ flatItem.name }}</div>
      }
      @if (displayPlayButton && flatItem.contentId) {
        <div class="playback-button">
          <pv2-duration-play-button [duration]="flatItem.duration" [mixId]="flatItem.contentId" [handlePlayback]="true">
          </pv2-duration-play-button>
        </div>
      }
      <div class="content-tag-line"> {{ flatItem.tagLine }}</div>
      @if (flatItem.hostName) {
        <div class="content-host-name">
          Hosted by {{ flatItem.hostName }}
        </div>
      }
      @if (displayDescription) {
        <div class="content-description">{{ flatItem.description }}</div>
      }
    </div>

    @if (hasExtendedDetails) {
      <div class="section-divider">
        <div class="h4">More Info</div>
      </div>
      <div class="content-extended-info details-container">
        @if (flatItem.credits) {
          <span class="property-name">Credits:</span> {{ flatItem.credits }}
        }

        @if (flatItem.blogLink) {
          <div class="single-line-link"><span class="property-name">Read the Article:</span>
            <a [href]="flatItem.blogLink" target="_blank">Article Link</a>
          </div>
        }
        @if (flatItem.videoLink) {
          <div class="single-line-link"><span class="property-name">Watch the Video:</span>
            <a [href]="flatItem.videoLink" target="_blank">YouTube Link</a>
          </div>
        }
      </div>
    }
  </div>
}
