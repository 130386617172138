import {AppConfigInterface} from "./app-config.interface";

/**
 * App Configuration/Settings
 * Configuration settings used by various services.
 * This should be configured in your App Module, and passed as initializers for the services that need it.
 */
export class AppConfig implements AppConfigInterface {
  public appCode = 'app_code';
  public appName = 'App Name';
  public apiUrl = 'https://api.app.com/';
  public userApiUrl = "https://user.app.com";
  public gaCode?: string;
  public signInUrl?: string;
  public signOutUrl?: string;
}
