import {Component, EventEmitter, Output, ViewEncapsulation} from "@angular/core";
import {faPlay} from "@fortawesome/pro-regular-svg-icons/faPlay";

/**
 * Giant 'Press Play' button that should be displayed when we detect that audio playback has been blocked.
 */
@Component({
  selector: 'modal-play-button',
  templateUrl: 'modal-play-button.component.html',
  styleUrl: "modal-play-button.component.scss",
  encapsulation: ViewEncapsulation.None
})
export class ModalPlayButtonComponent {
  @Output() playClicked = new EventEmitter();
  protected readonly faPlay = faPlay;

  protected modalPlayEvent = () => {
    this.playClicked.emit();
  }

}
