import { Any } from "./any";
import { OperationMode, PropertyConvertingMode, PropertyMatchingRule, ValueCheckingMode } from "./json-convert-enums";
import { Settings } from "./json-convert-options";
/**
 * Offers a simple API for mapping JSON objects to TypeScript/JavaScript classes and vice versa.
 *
 * @see https://www.npmjs.com/package/json2typescript full documentation on NPM
 */
var JsonConvert = /** @class */function () {
  /////////////////
  // CONSTRUCTOR //
  /////////////////
  /**
   * Constructor.
   *
   * To learn more about the params, check the documentation of the equally named class properties.
   *
   * @param operationMode optional param (default: OperationMode.ENABLE)
   * @param valueCheckingMode optional param (default: ValueCheckingMode.ALLOW_OBJECT_NULL)
   * @param ignorePrimitiveChecks optional param (default: false)
   * @param propertyMatchingRule optional param (default: PropertyMatchingRule.CASE_STRICT)
   */
  function JsonConvert(operationMode, valueCheckingMode, ignorePrimitiveChecks, propertyMatchingRule) {
    ////////////////
    // PROPERTIES //
    ////////////////
    /**
     * Determines how the JsonConvert class instance should operate.
     *
     * You may assign three different values:
     * - OperationMode.DISABLE: json2typescript will be disabled, no type checking or mapping is done
     * - OperationMode.ENABLE: json2typescript is enabled, but only errors are logged
     * - OperationMode.LOGGING: json2typescript is enabled and detailed information is logged
     */
    this._operationMode = OperationMode.ENABLE;
    /**
     * Determines which types are allowed to be null.
     * This setting may be overridden by property settings (see PropertyConvertingMode).
     *
     * You may assign three different values:
     * - ValueCheckingMode.ALLOW_NULL: all given values are allowed to be null
     * - ValueCheckingMode.ALLOW_OBJECT_NULL: objects are allowed to be null, primitive types are not allowed to be null
     * - ValueCheckingMode.DISALLOW_NULL: no null values are tolerated
     */
    this._valueCheckingMode = ValueCheckingMode.ALLOW_OBJECT_NULL;
    /**
     * Determines whether a missing or undefined property value should be considered as null or not.
     *
     * If true, a missing JSON value will be added and set as null before deserialization.
     * For serialization, undefined values will be set to null before serialization.
     *
     * The ValueCheckingMode and PropertyConvertingMode determine whether an error will be thrown during
     * serialization or deserialization.
     */
    this._mapUndefinedToNull = false;
    /**
     * Determines whether primitive types should be checked.
     * If true, it will be allowed to assign primitive to other primitive types.
     */
    this._ignorePrimitiveChecks = false;
    /**
     * Determines the rule of how JSON properties shall be matched with class properties during deserialization.
     *
     * You may assign the following values:
     * - PropertyMatchingRule.CASE_STRICT: JSON properties need to match exactly the names in the decorators
     * - PropertyMatchingRule.CASE_INSENSITIVE: JSON properties need to match names in the decorators, but names they
     * are not case sensitive
     */
    this._propertyMatchingRule = PropertyMatchingRule.CASE_STRICT;
    /**
     * Determines how nullable property types should be serialized and deserialized.
     * Nullable types are either missing (in JSON), undefined (in TypeScript) or null (both).
     *
     * If the propertyConvertingMode has a non-undefined value, it overrides the individual settings of every property.
     *
     * The values should be used as follows:
     * Determines how nullable property types should be serialized and deserialized.
     * Nullable types are either missing (in JSON), undefined (in TypeScript) or null (both).
     *
     * If the propertyConvertingMode has a non-undefined value, it overrides the individual settings of every property.
     *
     * The values should be used as follows:
     * - MAP_NULLABLE: the mapper is applied, type is checked
     * - IGNORE_NULLABLE: the mapper is not applied if the property is missing, undefined or null; the property is
     * not added to the result
     * - PASS_NULLABLE: the mapper is not applied if the property is missing, undefined or null; the property is
     * added with its value to the result
     */
    this._propertyConvertingMode = undefined;
    /**
     * Determines if discriminators should be used.
     * If this option is set to true, all registered classes will be serialized with an additional discriminator
     * property (default: "$type"), which has the key of the class (given in the @JsonObject decorator) as value.
     * When deserializing an object containing the discriminator property, json2typescript will attempt to
     * automatically instantiate the correct type (by comparing the value of the discriminator property with the
     * registered classes).
     *
     * @see https://www.npmjs.com/package/json2typescript full documentation
     */
    this._useDiscriminator = false;
    /**
     * Defines the name of the discriminator property.
     *
     * @see https://www.npmjs.com/package/json2typescript full documentation
     */
    this._discriminatorPropertyName = "$type";
    /**
     * Determines all classes which should use the lazy-loading or discriminator feature.
     * Only classes provided here can be used with lazy-loading or the discriminator property.
     *
     * @see https://www.npmjs.com/package/json2typescript full documentation
     */
    this._classes = new Map();
    if (operationMode !== undefined && operationMode in OperationMode) this.operationMode = operationMode;
    if (valueCheckingMode !== undefined && valueCheckingMode in ValueCheckingMode) this.valueCheckingMode = valueCheckingMode;
    if (ignorePrimitiveChecks !== undefined) this.ignorePrimitiveChecks = ignorePrimitiveChecks;
    if (propertyMatchingRule !== undefined) this.propertyMatchingRule = propertyMatchingRule;
  }
  Object.defineProperty(JsonConvert.prototype, "operationMode", {
    /**
     * Determines how the JsonConvert class instance should operate.
     *
     * You may assign three different values:
     * - OperationMode.DISABLE: json2typescript will be disabled, no type checking or mapping is done
     * - OperationMode.ENABLE: json2typescript is enabled, but only errors are logged
     * - OperationMode.LOGGING: json2typescript is enabled and detailed information is logged
     *
     * @see https://www.npmjs.com/package/json2typescript full documentation
     */
    get: function () {
      return this._operationMode;
    },
    /**
     * Determines how the JsonConvert class instance should operate.
     *
     * You may assign three different values:
     * - OperationMode.DISABLE: json2typescript will be disabled, no type checking or mapping is done
     * - OperationMode.ENABLE: json2typescript is enabled, but only errors are logged
     * - OperationMode.LOGGING: json2typescript is enabled and detailed information is logged
     *
     * @see https://www.npmjs.com/package/json2typescript full documentation
     */
    set: function (value) {
      if (value in OperationMode) this._operationMode = value;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(JsonConvert.prototype, "valueCheckingMode", {
    /**
     * Determines which types are allowed to be null.
     * This setting may be overridden by property settings (see PropertyConvertingMode).
     *
     * You may assign three different values:
     * - ValueCheckingMode.ALLOW_NULL: all given values are allowed to be null
     * - ValueCheckingMode.ALLOW_OBJECT_NULL: objects are allowed to be null, primitive types are not allowed to be null
     * - ValueCheckingMode.DISALLOW_NULL: no null values are tolerated
     *
     * @see https://www.npmjs.com/package/json2typescript full documentation
     */
    get: function () {
      return this._valueCheckingMode;
    },
    /**
     * Determines which types are allowed to be null.
     * This setting may be overridden by property settings (see PropertyConvertingMode).
     *
     * You may assign three different values:
     * - ValueCheckingMode.ALLOW_NULL: all given values are allowed to be null
     * - ValueCheckingMode.ALLOW_OBJECT_NULL: objects are allowed to be null, primitive types are not allowed to be null
     * - ValueCheckingMode.DISALLOW_NULL: no null values are tolerated
     *
     * @see https://www.npmjs.com/package/json2typescript full documentation
     */
    set: function (value) {
      if (value in ValueCheckingMode) this._valueCheckingMode = value;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(JsonConvert.prototype, "mapUndefinedToNull", {
    /**
     * Determines whether a missing or undefined property value should be considered as null or not.
     *
     * If true, a missing JSON value will be added and set as null before deserialization.
     * For serialization, undefined values will be set to null before serialization.
     *
     * ValueCheckingMode and PropertyConvertingMode determine whether an error will be thrown during
     * serialization or deserialization.
     *
     * @see https://www.npmjs.com/package/json2typescript full documentation
     */
    get: function () {
      return this._mapUndefinedToNull;
    },
    /**
     * Determines whether a missing or undefined property value should be considered as null or not.
     *
     * If true, a missing JSON value will be added and set as null before deserialization.
     * For serialization, undefined values will be set to null before serialization.
     *
     * The ValueCheckingMode and PropertyConvertingMode determine whether an error will be thrown during
     * serialization or deserialization.
     *
     * @see https://www.npmjs.com/package/json2typescript full documentation
     */
    set: function (value) {
      this._mapUndefinedToNull = value;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(JsonConvert.prototype, "ignorePrimitiveChecks", {
    /**
     * Determines whether primitive types should be checked.
     * If true, it will be allowed to assign primitive to other primitive types.
     *
     * @see https://www.npmjs.com/package/json2typescript full documentation
     */
    get: function () {
      return this._ignorePrimitiveChecks;
    },
    /**
     * Determines whether primitive types should be checked.
     * If true, it will be allowed to assign primitive to other primitive types.
     *
     * @see https://www.npmjs.com/package/json2typescript full documentation
     */
    set: function (value) {
      this._ignorePrimitiveChecks = value;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(JsonConvert.prototype, "propertyMatchingRule", {
    /**
     * Determines the rule of how JSON properties shall be matched with class properties during deserialization.
     *
     * You may assign the following values:
     * - PropertyMatchingRule.CASE_STRICT: JSON properties need to match exactly the names in the decorators
     * - PropertyMatchingRule.CASE_INSENSITIVE: JSON properties need to match names in the decorators, but names they
     * are not case sensitive
     *
     * @see https://www.npmjs.com/package/json2typescript full documentation
     */
    get: function () {
      return this._propertyMatchingRule;
    },
    /**
     * Determines the rule of how JSON properties shall be matched with class properties during deserialization.
     *
     * You may assign the following values:
     * - PropertyMatchingRule.CASE_STRICT: JSON properties need to match exactly the names in the decorators
     * - PropertyMatchingRule.CASE_INSENSITIVE: JSON properties need to match names in the decorators, but names they
     * are not case sensitive
     *
     * @see https://www.npmjs.com/package/json2typescript full documentation
     */
    set: function (value) {
      if (value in PropertyMatchingRule) this._propertyMatchingRule = value;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(JsonConvert.prototype, "propertyConvertingMode", {
    /**
     * Determines how nullable property types should be serialized and deserialized.
     * Nullable types are either missing (in JSON), undefined (in TypeScript) or null (both).
     *
     * If the propertyConvertingMode has a non-undefined value, it overrides the individual settings of every property.
     *
     * The values should be used as follows:
     * - MAP_NULLABLE: the mapper is applied, type is checked
     * - IGNORE_NULLABLE: the mapper is not applied if the property is missing, undefined or null; the property is
     * not added to the result
     * - PASS_NULLABLE: the mapper is not applied if the property is missing, undefined or null; the property is
     * added with its value to the result
     *
     * @see https://www.npmjs.com/package/json2typescript full documentation
     */
    get: function () {
      return this._propertyConvertingMode;
    },
    /**
     * Determines how nullable property types should be serialized and deserialized.
     * Nullable types are either missing (in JSON), undefined (in TypeScript) or null (both).
     *
     * If the propertyConvertingMode has a non-undefined value, it overrides the individual settings of every property.
     *
     * The values should be used as follows:
     * - MAP_NULLABLE: the mapper is applied, type is checked
     * - IGNORE_NULLABLE: the mapper is not applied if the property is missing, undefined or null; the property is
     * not added to the result
     * - PASS_NULLABLE: the mapper is not applied if the property is missing, undefined or null; the property is
     * added with its value to the result
     *
     * @see https://www.npmjs.com/package/json2typescript full documentation
     */
    set: function (value) {
      this._propertyConvertingMode = value;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(JsonConvert.prototype, "ignoreRequiredCheck", {
    /**
     * @deprecated
     */
    get: function () {
      return this.propertyConvertingMode === PropertyConvertingMode.IGNORE_NULLABLE;
    },
    /**
     * @deprecated
     */
    set: function (value) {
      this.propertyConvertingMode = value ? PropertyConvertingMode.IGNORE_NULLABLE : undefined;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(JsonConvert.prototype, "useDiscriminator", {
    /**
     * Determines if discriminators should be used.
     * If this option is set to true, all registered classes will be serialized with an additional discriminator
     * property (default: "$type"), which has the key of the class (given in the @JsonObject decorator) as value.
     * When deserializing an object containing the discriminator property, json2typescript will attempt to
     * automatically instantiate the correct type (by comparing the value of the discriminator property with the
     * registered classes).
     *
     * @see https://www.npmjs.com/package/json2typescript full documentation
     */
    get: function () {
      return this._useDiscriminator;
    },
    /**
     * Determines if discriminators should be used.
     * If this option is set to true, all registered classes will be serialized with an additional discriminator
     * property (default: "$type"), which has the key of the class (given in the @JsonObject decorator) as value.
     * When deserializing an object containing the discriminator property, json2typescript will attempt to
     * automatically instantiate the correct type (by comparing the value of the discriminator property with the
     * registered classes).
     *
     * @see https://www.npmjs.com/package/json2typescript full documentation
     */
    set: function (value) {
      this._useDiscriminator = value;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(JsonConvert.prototype, "discriminatorPropertyName", {
    /**
     * Defines the name of the discriminator property.
     *
     * @see https://www.npmjs.com/package/json2typescript full documentation
     */
    get: function () {
      return this._discriminatorPropertyName;
    },
    /**
     * Defines the name of the discriminator property.
     *
     * @see https://www.npmjs.com/package/json2typescript full documentation
     */
    set: function (value) {
      this._discriminatorPropertyName = value;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(JsonConvert.prototype, "classes", {
    /**
     * Determines all classes which should use the lazy-loading or discriminator feature.
     * Only classes provided here can be used with lazy-loading or the discriminator property.
     *
     * @see https://www.npmjs.com/package/json2typescript full documentation
     */
    get: function () {
      return this._classes;
    },
    enumerable: false,
    configurable: true
  });
  ////////////////////
  // PUBLIC METHODS //
  ////////////////////
  /**
   * Registers a list of classes to be used in the discriminator feature.
   * After registering these classes, they may be used for the discriminator feature.
   *
   * @param classReferences the class references
   *
   * @see https://www.npmjs.com/package/json2typescript full documentation
   */
  JsonConvert.prototype.registerClasses = function () {
    var _this = this;
    var classReferences = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      classReferences[_i] = arguments[_i];
    }
    classReferences.forEach(function (classReference) {
      var key = classReference.prototype[Settings.CLASS_IDENTIFIER] || classReference.name;
      if (key) {
        _this.classes.set(key, classReference);
      }
    });
  };
  /**
   * Unregisters a list of classes from the discriminator feature.
   * After unregistering these classes, they cannot be used anymore for the discriminator feature.
   *
   * @param classReferences the class references
   *
   * @see https://www.npmjs.com/package/json2typescript full documentation
   */
  JsonConvert.prototype.unregisterClasses = function () {
    var _this = this;
    var classReferences = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      classReferences[_i] = arguments[_i];
    }
    classReferences.forEach(function (classReference) {
      var key = classReference.prototype[Settings.CLASS_IDENTIFIER] || classReference.name;
      _this.classes.delete(key);
    });
  };
  /**
   * Unregisters all classes from discriminator feature.
   *
   * @see https://www.npmjs.com/package/json2typescript full documentation
   */
  JsonConvert.prototype.unregisterAllClasses = function () {
    this.classes.clear();
  };
  /**
   * Tries to serialize a TypeScript object or array of objects to JSON using the mappings defined on
   * the specified class reference. Note that if a class reference is provided, it will be used as
   * the source of property mapping for serialization, even if the object or one of its elements is
   * an instance of a different class with its own mappings.  Also, ONLY the properties from the
   * class reference will be serialized - any additional properties on the object(s) will be silently
   * ignored.
   *
   * @param data object or array of objects
   * @param classReference the class reference which provides the property mappings to use
   *
   * @returns the JSON object
   *
   * @throws an Error in case of failure
   *
   * @see https://www.npmjs.com/package/json2typescript full documentation
   */
  JsonConvert.prototype.serialize = function (data, classReference) {
    if (this.operationMode === OperationMode.DISABLE) {
      return data;
    }
    // Call the appropriate method depending on the type
    if (data instanceof Array) {
      return this.serializeArray(data, classReference);
    } else if (typeof data === "object") {
      // careful: an array is an object in TypeScript!
      return this.serializeObject(data, classReference);
    } else {
      throw new Error("Fatal error in JsonConvert. " + "Passed parameter data in JsonConvert.serialize() is not in valid format (object or array)." + "\n");
    }
  };
  /**
   * Tries to serialize a TypeScript object to a JSON object using either the mappings on the
   * provided class reference, if present, or on the provided object. Note that if a class
   * reference is provided, it will be used as the source of property mapping for serialization,
   * even if the object is itself an instance of a different class with its own mappings.
   * Also, ONLY the properties from the class reference will be serialized - any additional
   * properties on the object will be silently ignored.
   *
   * @param data object containing the values to be mapped to a JSON object, must be an
   *             instance of a class with JSON mappings if no class reference is provided
   * @param classReference optional class reference which provides the property mappings to use
   *
   * @returns the JSON object
   *
   * @throws an Error in case of failure
   *
   * @see https://www.npmjs.com/package/json2typescript full documentation
   */
  JsonConvert.prototype.serializeObject = function (data, classReference) {
    if (this.operationMode === OperationMode.DISABLE) {
      return data;
    }
    data = this.mapUndefinedToNull && data === undefined ? null : data;
    // Check if the passed type is allowed
    if (data === undefined) {
      throw new Error("Fatal error in JsonConvert. " + "Passed parameter instance in JsonConvert.serializeObject() is undefined. This is not a valid JSON format." + "\n");
    } else if (data === null) {
      if (this.valueCheckingMode === ValueCheckingMode.DISALLOW_NULL) {
        throw new Error("Fatal error in JsonConvert. " + "Passed parameter instance in JsonConvert.serializeObject() is null. You have specified to " + "disallow null values." + "\n");
      } else {
        return data;
      }
    } else if (typeof data !== "object" || data instanceof Array) {
      throw new Error("Fatal error in JsonConvert. " + "Passed parameter instance in JsonConvert.serializeObject() is not of type object." + "\n");
    }
    // Now serialize and return the plain object
    if (this.operationMode === OperationMode.LOGGING) {
      console.log("----------");
      console.log("Receiving JavaScript instance:");
      console.log(data);
    }
    var jsonObject = {};
    var instance;
    if (!!classReference) {
      instance = new classReference();
    } else {
      instance = data;
    }
    // Loop through all initialized class properties on the mapping instance
    for (var _i = 0, _a = Object.keys(instance); _i < _a.length; _i++) {
      var propertyKey = _a[_i];
      try {
        this.serializeObject_loopProperty(data, instance, propertyKey, jsonObject);
      } catch (ex) {
        if (this.operationMode === OperationMode.LOGGING) {
          console.log("Failed to serialize property:");
          console.log(ex);
          console.log("----------");
        }
        throw ex;
      }
    }
    if (this.operationMode === OperationMode.LOGGING) {
      console.log("Returning JSON object:");
      console.log(jsonObject);
      console.log("----------");
    }
    return jsonObject;
  };
  /**
   * Tries to serialize a TypeScript array to a JSON array using either the mappings on the
   * provided class reference, if present, or on the provided object. Note that if a class
   * reference is provided, ALL objects in the array will be serialized using the mappings
   * from that class reference, even if they're actually instances of a different class.
   * Also, ONLY the properties from the class reference will be serialized - any additional
   * properties on the objects will be silently ignored.
   *
   * @param dataArray array of objects containing the values to be mapped to a JSON object, which
   *                  must be instances of classes with JSON mappings if no class reference is provided
   * @param classReference optional class reference which provides the property mappings to use
   *
   * @returns the JSON array
   *
   * @throws an Error in case of failure
   *
   * @see https://www.npmjs.com/package/json2typescript full documentation
   */
  JsonConvert.prototype.serializeArray = function (dataArray, classReference) {
    if (this.operationMode === OperationMode.DISABLE) {
      return dataArray;
    }
    dataArray = this.mapUndefinedToNull && dataArray === undefined ? null : dataArray;
    // Check if the passed type is allowed
    if (dataArray === undefined) {
      throw new Error("Fatal error in JsonConvert. " + "Passed parameter instanceArray in JsonConvert.serializeArray() is undefined. This is not a valid JSON format." + "\n");
    } else if (dataArray === null) {
      if (this.valueCheckingMode === ValueCheckingMode.DISALLOW_NULL) {
        throw new Error("Fatal error in JsonConvert. " + "Passed parameter instanceArray in JsonConvert.serializeArray() is null. You have specified to " + "disallow null values." + "\n");
      } else {
        return dataArray;
      }
    } else if (typeof dataArray !== "object" || dataArray instanceof Array === false) {
      throw new Error("Fatal error in JsonConvert. " + "Passed parameter instanceArray in JsonConvert.serializeArray() is not of type array." + "\n");
    }
    // Now serialize and return the plain object
    if (this.operationMode === OperationMode.LOGGING) {
      console.log("----------");
      console.log("Receiving JavaScript array:");
      console.log(dataArray);
    }
    var jsonArray = [];
    // Loop through all array elements
    for (var _i = 0, dataArray_1 = dataArray; _i < dataArray_1.length; _i++) {
      var dataObject = dataArray_1[_i];
      jsonArray.push(this.serializeObject(dataObject, classReference));
    }
    if (this.operationMode === OperationMode.LOGGING) {
      console.log("Returning JSON array:");
      console.log(jsonArray);
      console.log("----------");
    }
    return jsonArray;
  };
  /**
   * Tries to deserialize given JSON to a TypeScript object or array of objects.
   *
   * @param json the JSON as object or array
   * @param classReference the class reference
   *
   * @returns the deserialized data (TypeScript instance or array of TypeScript instances)
   *
   * @throws an Error in case of failure
   *
   * @see https://www.npmjs.com/package/json2typescript full documentation
   */
  JsonConvert.prototype.deserialize = function (json, classReference) {
    if (classReference === void 0) {
      classReference = null;
    }
    if (this.operationMode === OperationMode.DISABLE) {
      return json;
    }
    // Call the appropriate method depending on the type
    if (json instanceof Array) {
      return this.deserializeArray(json, classReference);
    } else if (typeof json === "object") {
      // careful: an array is an object in TypeScript!
      return this.deserializeObject(json, classReference);
    } else {
      throw new Error("Fatal error in JsonConvert. " + "Passed parameter json in JsonConvert.deserialize() is not in valid JSON format (object or array)." + "\n");
    }
  };
  /**
   * Tries to deserialize a JSON object to a TypeScript object.
   *
   * @param jsonObject the JSON object
   * @param classReference the class reference
   *
   * @returns the deserialized TypeScript instance
   *
   * @throws an Error in case of failure
   *
   * @see https://www.npmjs.com/package/json2typescript full documentation
   */
  JsonConvert.prototype.deserializeObject = function (jsonObject, classReference) {
    if (classReference === void 0) {
      classReference = null;
    }
    if (this.operationMode === OperationMode.DISABLE) {
      return jsonObject;
    }
    var realClassReference = this.getRealClassReference(jsonObject, classReference);
    jsonObject = this.mapUndefinedToNull && jsonObject === undefined ? null : jsonObject;
    // Check if the passed type is allowed
    if (jsonObject === undefined) {
      throw new Error("Fatal error in JsonConvert. " + "Passed parameter jsonObject in JsonConvert.deserializeObject() is undefined. This is not a valid JSON format." + "\n");
    } else if (jsonObject === null) {
      if (this.valueCheckingMode === ValueCheckingMode.DISALLOW_NULL) {
        throw new Error("Fatal error in JsonConvert. " + "Passed parameter jsonObject in JsonConvert.deserializeObject() is null. You have specified to " + "disallow null values." + "\n");
      } else {
        return jsonObject;
      }
    } else if (typeof jsonObject !== "object" || jsonObject instanceof Array) {
      throw new Error("Fatal error in JsonConvert. " + "Passed parameter jsonObject in JsonConvert.deserializeObject() is not of type object." + "\n");
    }
    // Now deserialize and return the instance
    if (this.operationMode === OperationMode.LOGGING) {
      console.log("----------");
      console.log("Receiving JSON object:");
      console.log(jsonObject);
    }
    var instance = new realClassReference();
    // Loop through all initialized class properties
    for (var _i = 0, _a = Object.keys(instance); _i < _a.length; _i++) {
      var propertyKey = _a[_i];
      try {
        this.deserializeObject_loopProperty(instance, propertyKey, jsonObject);
      } catch (ex) {
        if (this.operationMode === OperationMode.LOGGING) {
          console.log("Failed to deserialize property:");
          console.log(ex);
          console.log("----------");
        }
        throw ex;
      }
    }
    if (this.operationMode === OperationMode.LOGGING) {
      console.log("Returning CLASS instance:");
      console.log(instance);
      console.log("----------");
    }
    return instance;
  };
  /**
   * Tries to deserialize a JSON array to a TypeScript array.
   *
   * @param jsonArray the JSON array
   * @param classReference the object class
   *
   * @returns the deserialized array of TypeScript instances
   *
   * @throws an Error in case of failure
   *
   * @see https://www.npmjs.com/package/json2typescript full documentation
   */
  JsonConvert.prototype.deserializeArray = function (jsonArray, classReference) {
    if (classReference === void 0) {
      classReference = null;
    }
    if (this.operationMode === OperationMode.DISABLE) {
      return jsonArray;
    }
    jsonArray = this.mapUndefinedToNull && jsonArray === undefined ? null : jsonArray;
    // Check if the passed type is allowed
    if (jsonArray === undefined) {
      throw new Error("Fatal error in JsonConvert. " + "Passed parameter jsonArray in JsonConvert.deserializeObject() is undefined. This is not a valid JSON format." + "\n");
    } else if (jsonArray === null) {
      if (this.valueCheckingMode === ValueCheckingMode.DISALLOW_NULL) {
        throw new Error("Fatal error in JsonConvert. " + "Passed parameter jsonArray in JsonConvert.deserializeObject() is null. You have specified to " + "disallow null values." + "\n");
      } else {
        return jsonArray;
      }
    } else if (typeof jsonArray !== "object" || jsonArray instanceof Array === false) {
      throw new Error("Fatal error in JsonConvert. " + "Passed parameter jsonArray in JsonConvert.deserializeArray() is not of type array." + "\n");
    }
    // Now deserialize and return the array
    if (this.operationMode === OperationMode.LOGGING) {
      console.log("----------");
      console.log("Receiving JSON array:");
      console.log(jsonArray);
    }
    var array = [];
    // Loop through all array elements
    for (var _i = 0, jsonArray_1 = jsonArray; _i < jsonArray_1.length; _i++) {
      var jsonObject = jsonArray_1[_i];
      array.push(this.deserializeObject(jsonObject, classReference));
    }
    if (this.operationMode === OperationMode.LOGGING) {
      console.log("Returning array of CLASS instances:");
      console.log(array);
      console.log("----------");
    }
    return array;
  };
  /////////////////////
  // PRIVATE METHODS //
  /////////////////////
  /**
   * Returns the correct class reference for the provided JSON object.
   * If the provided class reference is null, the class reference is retrieved from the class map using the discriminator property.
   *
   * @param jsonObject the JSON object
   * @param classReference the class reference
   * @throws throws an Error in case of failure
   */
  JsonConvert.prototype.getRealClassReference = function (jsonObject, classReference) {
    var _a;
    // First determine if the discriminator is used or not
    if (this.useDiscriminator) {
      // Check if we find the $type property. If not, throw an error.
      if (jsonObject.hasOwnProperty(this.discriminatorPropertyName)) {
        var discriminatorValue = (_a = jsonObject[this.discriminatorPropertyName]) !== null && _a !== void 0 ? _a : "";
        var classReferenceNameFromMap = this.classes.get(discriminatorValue);
        if (classReferenceNameFromMap !== undefined && classReferenceNameFromMap !== null) {
          return classReferenceNameFromMap;
        } else {
          throw new Error("Fatal error in JsonConvert. " + "Discriminator value \"" + discriminatorValue + "\" could not be found in the registered classes. " + "Make sure you register the class using the method JsonConvert.registerClasses(" + discriminatorValue + ")" + "\n");
        }
      } else {
        throw new Error("Fatal error in JsonConvert. " + "Discriminator property \"" + this.discriminatorPropertyName + "\" is missing in JSON object." + "\n");
      }
    } else {
      // Make sure the class reference is given for if the discriminator is disabled
      if (classReference === null) {
        throw new Error("Fatal error in JsonConvert. " + "Passed parameter classReference in JsonConvert.deserialize() is null. " + "This is only allowed if discriminator feature is enabled." + "\n");
      }
      return classReference;
    }
  };
  /**
   * Tries to find the JSON mapping for a given class property from the given instance used for mapping,
   * and finally assign the value from the given dataObject
   *
   * @param dataObject the object containing the value to be assigned
   * @param instance the instance of the class used for mapping
   * @param classPropertyName the property name
   * @param json the JSON object
   * @throws throws an Error in case of failure
   */
  JsonConvert.prototype.serializeObject_loopProperty = function (dataObject, instance, classPropertyName, json) {
    var _this = this;
    var _a, _b;
    // Check if a JSON-object mapping is possible for a property
    var mappingOptions = this.getClassPropertyMappingOptions(instance, classPropertyName);
    if (mappingOptions === null) {
      return;
    }
    // Get expected and real values
    var jsonPropertyName = mappingOptions.jsonPropertyName;
    var expectedJsonType = mappingOptions.expectedJsonType;
    var convertingMode = (_a = this.propertyConvertingMode) !== null && _a !== void 0 ? _a : mappingOptions.convertingMode;
    var customConverter = mappingOptions.customConverter;
    var classInstancePropertyValue = dataObject[classPropertyName];
    // Check if we have a nullable type
    classInstancePropertyValue = this.mapUndefinedToNull && classInstancePropertyValue === undefined ? null : classInstancePropertyValue;
    if (classInstancePropertyValue === undefined || classInstancePropertyValue === null) {
      if (convertingMode === PropertyConvertingMode.IGNORE_NULLABLE) {
        return;
      }
      if (convertingMode === PropertyConvertingMode.PASS_NULLABLE) {
        json[jsonPropertyName] = classInstancePropertyValue;
        return;
      }
    }
    // Map the property
    try {
      json[jsonPropertyName] = customConverter !== null ? customConverter.serialize(classInstancePropertyValue) : this.convertProperty(expectedJsonType, classInstancePropertyValue, convertingMode, true);
      var classConstructorName_1 = (_b = dataObject === null || dataObject === void 0 ? void 0 : dataObject.constructor) === null || _b === void 0 ? void 0 : _b.name;
      if (this._useDiscriminator && json instanceof Object) {
        this.classes.forEach(function (classDataObject, key) {
          if (classDataObject.name === classConstructorName_1) {
            json[_this._discriminatorPropertyName] = key;
          }
        });
      }
    } catch (e) {
      throw new Error("Fatal error in JsonConvert. " + "Failed to map the JavaScript instance of class \"" + instance[Settings.CLASS_IDENTIFIER] + "\" to JSON because of a type error.\n\n" + "\tClass property: \n\t\t" + classPropertyName + "\n\n" + "\tClass property value: \n\t\t" + classInstancePropertyValue + "\n\n" + "\tExpected type: \n\t\t" + this.getExpectedType(expectedJsonType) + "\n\n" + "\tRuntime type: \n\t\t" + this.getTrueType(classInstancePropertyValue) + "\n\n" + "\tJSON property: \n\t\t" + jsonPropertyName + "\n\n" + e.message + "\n");
    }
  };
  /**
   * Tries to find the JSON mapping for a given class property and finally assign the value.
   *
   * @param instance the instance of the class
   * @param classPropertyName the property name
   * @param json the JSON object
   *
   * @throws throws an Error in case of failure
   */
  JsonConvert.prototype.deserializeObject_loopProperty = function (instance, classPropertyName, json) {
    var _a;
    var mappingOptions = this.getClassPropertyMappingOptions(instance, classPropertyName);
    if (mappingOptions === null) {
      return;
    }
    // Get expected and real values
    var jsonPropertyName = mappingOptions.jsonPropertyName;
    var expectedJsonType = mappingOptions.expectedJsonType;
    var convertingMode = (_a = this.propertyConvertingMode) !== null && _a !== void 0 ? _a : mappingOptions.convertingMode;
    var customConverter = mappingOptions.customConverter;
    var jsonValue = undefined;
    try {
      jsonValue = this.getObjectValue(json, jsonPropertyName);
    } catch (_b) {}
    // Check if we have a nullable type
    jsonValue = this.mapUndefinedToNull && jsonValue === undefined ? null : jsonValue;
    if (jsonValue === undefined || jsonValue === null) {
      if (convertingMode === PropertyConvertingMode.IGNORE_NULLABLE) {
        return;
      }
      if (convertingMode === PropertyConvertingMode.PASS_NULLABLE) {
        instance[classPropertyName] = jsonValue;
        return;
      }
    }
    // Map the property
    try {
      var classConstructorName = jsonValue instanceof Object ? jsonValue[this.discriminatorPropertyName] : null;
      if (this._useDiscriminator && this.classes.has(classConstructorName)) {
        expectedJsonType = this.classes.get(classConstructorName);
      }
      instance[classPropertyName] = customConverter !== null ? customConverter.deserialize(jsonValue) : this.convertProperty(expectedJsonType, jsonValue, convertingMode);
    } catch (e) {
      throw new Error("Fatal error in JsonConvert. " + "Failed to map the JSON object to the class \"" + instance[Settings.CLASS_IDENTIFIER] + "\" because of a type error.\n\n" + "\tClass property: \n\t\t" + classPropertyName + "\n\n" + "\tExpected type: \n\t\t" + this.getExpectedType(expectedJsonType) + "\n\n" + "\tJSON property: \n\t\t" + jsonPropertyName + "\n\n" + "\tJSON type: \n\t\t" + this.getJsonType(jsonValue) + "\n\n" + "\tJSON value: \n\t\t" + JSON.stringify(jsonValue) + "\n\n" + e.message + "\n");
    }
  };
  ////////////////////
  // HELPER METHODS //
  ////////////////////
  /**
   * Gets the mapping options of a given class property.
   *
   * @param instance any class instance
   * @param {string} propertyName any property name
   *
   * @returns {MappingOptions|null}
   */
  JsonConvert.prototype.getClassPropertyMappingOptions = function (instance, propertyName) {
    var mappings = instance[Settings.MAPPING_PROPERTY];
    // Check if mapping is defined
    if (typeof mappings === "undefined") return null;
    /* Find mapping by iterating up the prototype chain to find a matching mapping, rather than
     * just searching by property name. */
    var prototype = Object.getPrototypeOf(instance);
    /* According to documentation, we'll hit null when we've iterated all the way up to the base
     * Object, but check for undefined as well in case prototype has been manually set to
     * undefined. Note that javascript detects circular prototype references and will cause a
     * TypeError, so no need to check for self, the prototype chain will eventually terminate. */
    while (prototype !== null && prototype !== undefined) {
      var classIdentifier = prototype[Settings.CLASS_IDENTIFIER];
      if (!!classIdentifier) {
        var mappingName = classIdentifier + "." + propertyName;
        if (typeof mappings[mappingName] !== "undefined") {
          return mappings[mappingName];
        }
      }
      prototype = Object.getPrototypeOf(prototype);
    }
    return null;
  };
  /**
   * Compares the type of a given value with an internal expected json type.
   * Either returns the resulting value or throws an exception.
   *
   * @param expectedType the expected type for the property
   * @param value the property value to verify
   * @param convertingMode the converting mode for this property
   * @param serialize optional param (default: false), if given, we are in serialization mode
   *
   * @returns returns the resulted mapped property
   *
   * @throws an error in case of failure
   */
  JsonConvert.prototype.convertProperty = function (expectedType, value, convertingMode, serialize) {
    ////////////////////////////
    // Prior checks and setup //
    ////////////////////////////
    // Return the value immediately if we don't care about the type
    if (expectedType === undefined || expectedType === Any || expectedType === null || expectedType === Object) {
      return value;
    }
    // Check if we have a nullable type
    value = this.mapUndefinedToNull && value === undefined ? null : value;
    if (value === undefined || value === null) {
      if (convertingMode === PropertyConvertingMode.IGNORE_NULLABLE) {
        return undefined;
      }
      if (convertingMode === PropertyConvertingMode.PASS_NULLABLE) {
        return value;
      }
    }
    var expectedDimension = "1";
    if (expectedType instanceof Array) {
      expectedDimension = "2";
    }
    var valueDimension = "1or2";
    if (value instanceof Array) {
      valueDimension = "2";
    } else if (!(value instanceof Object)) {
      valueDimension = "1";
    }
    if (expectedDimension === "1" && valueDimension === "2") {
      throw new Error("\tReason: Expected a non-array type, but given value is an array.");
    }
    if (expectedDimension === "2" && valueDimension === "1") {
      // Allow to use null in the special case
      if (value === null && this.valueCheckingMode !== ValueCheckingMode.DISALLOW_NULL) {
        return null;
      } else if (value === null) {
        throw new Error("\tReason: Expected an array, but given value is null.");
      }
      throw new Error("\tReason: Expected an array, but given value is a primitive type.");
    }
    //////////////////
    // Check values //
    //////////////////
    if (expectedDimension === "2" && (valueDimension === "2" || valueDimension === "1or2")) {
      // Return an empty array if we have an empty array or object as value
      if (value.length === 0 || Object.keys(value).length === 0) {
        return [];
      }
      // Return the value if we don't care about the array type
      if (expectedType.length === 0) {
        return value;
      }
      // Copy the expectedJsonType array so we don't change the class-level mapping based on the value of this property
      var jsonType = expectedType.slice(0);
      var array = [];
      if (valueDimension === "2") {
        // Loop through the data. Both type and value are at least of length 1
        var autofillType = jsonType.length < value.length;
        for (var i = 0; i < value.length; i++) {
          if (autofillType && i >= jsonType.length) {
            jsonType[i] = jsonType[i - 1];
          }
          array[i] = this.convertProperty(jsonType[i], value[i], this.propertyConvertingMode || PropertyConvertingMode.MAP_NULLABLE, serialize);
        }
        return array;
      } else {
        // Loop through the data. Both type and value are at least of length 1
        var autofillType = jsonType.length < Object.keys(value).length;
        var i = 0;
        for (var key in value) {
          if (autofillType && i >= jsonType.length) {
            jsonType[i] = jsonType[i - 1];
          }
          array[key] = this.convertProperty(jsonType[i], value[key], this.propertyConvertingMode || PropertyConvertingMode.MAP_NULLABLE, serialize);
          i++;
        }
        return array;
      }
    } else if (expectedDimension === "1" && (valueDimension === "1" || valueDimension === "1or2")) {
      // Check if objects match
      if ((expectedType instanceof Object && [String, Number, Boolean].includes(expectedType) === false || typeof expectedType === "string") && value instanceof Object) {
        // If the expected type is a string (means: lazy-loading), get the real type from the registered classes
        if (typeof expectedType === "string") {
          var realExpectedType = this.classes.get(expectedType);
          if (!realExpectedType) {
            throw new Error("\tReason: Given expected type \"" + expectedType + "\" not registered with JsonConvert.registerClasses().");
          }
          expectedType = realExpectedType;
        }
        if (expectedType.prototype.hasOwnProperty(Settings.CLASS_IDENTIFIER)) {
          return serialize ? this.serializeObject(value, expectedType) : this.deserializeObject(value, expectedType);
        } else {
          return value;
        }
      } else {
        // Check for null values
        if (value === null) {
          if (expectedType === String || expectedType === Number || expectedType === Boolean) {
            if (this.valueCheckingMode === ValueCheckingMode.ALLOW_NULL) {
              return null;
            } else {
              throw new Error("\tReason: Given value null does not match the expected primitive type.");
            }
          } else {
            if (this.valueCheckingMode !== ValueCheckingMode.DISALLOW_NULL) {
              return null;
            } else {
              throw new Error("\tReason: Given value null does not match the expected object type.");
            }
          }
        }
        // Check for primitive matches
        if (expectedType === String && typeof value === "string" || expectedType === Number && typeof value === "number" || expectedType === Boolean && typeof value === "boolean") {
          return value;
        } else {
          if (this.ignorePrimitiveChecks && ["string", "number", "boolean"].includes(typeof value)) {
            return value;
          }
          throw new Error("\tReason: Given value type does not match the expected primitive type.");
        }
      }
    }
    // All other attempts are fatal
    throw new Error("\tReason: Mapping failed because of an unknown error.");
  };
  /**
   * Gets the value of an object for a given value.
   * If the object does not have the specific key, an Error is thrown.
   *
   * @param data
   * @param key
   *
   * @returns returns the value
   *
   * @throws an Error in case of the key was not found in the object
   */
  JsonConvert.prototype.getObjectValue = function (data, key) {
    // If we do not care about the case of the key, ad
    if (this.propertyMatchingRule === PropertyMatchingRule.CASE_INSENSITIVE) {
      // Create a mapping of the keys: keys[lowercase]=normalcase
      var keyMapping = Object.keys(data).reduce(function (keys, key) {
        keys[key.toLowerCase()] = key;
        return keys;
      }, {});
      // Define the new key
      key = keyMapping[key.toLowerCase()];
    }
    // Throw an error if the key is not in the object
    if (key in data === false) {
      throw new Error();
    }
    return data[key];
  };
  ///////////////////////////
  // JSON2TYPESCRIPT TYPES //
  ///////////////////////////
  /**
   * Returns a string representation of the expected json type.
   *
   * @param expectedJsonType the expected type given from the decorator
   *
   * @returns {string} the string representation
   */
  JsonConvert.prototype.getExpectedType = function (expectedJsonType) {
    var type = "";
    if (expectedJsonType instanceof Array) {
      type = "[";
      for (var i = 0; i < expectedJsonType.length; i++) {
        if (i > 0) type += ",";
        type += this.getExpectedType(expectedJsonType[i]);
      }
      type += "]";
      return type;
    } else {
      if (expectedJsonType === Any || expectedJsonType === null || expectedJsonType === Object) {
        return "any";
      } else if (expectedJsonType === String || expectedJsonType === Boolean || expectedJsonType === Number) {
        return new expectedJsonType().constructor.name.toLowerCase();
      } else if (typeof expectedJsonType === "function") {
        return new expectedJsonType().constructor.name;
      } else if (typeof expectedJsonType === "string") {
        return expectedJsonType;
      } else if (expectedJsonType === undefined) {
        return "undefined";
      } else {
        return "?????";
      }
    }
  };
  /**
   * Returns a string representation of the JSON value type.
   *
   * @param jsonValue the JSON value
   *
   * @returns {string} the string representation
   */
  JsonConvert.prototype.getJsonType = function (jsonValue) {
    if (jsonValue === null) return "null";
    var type = "";
    if (jsonValue instanceof Array) {
      type = "[";
      for (var i = 0; i < jsonValue.length; i++) {
        if (i > 0) type += ",";
        type += this.getJsonType(jsonValue[i]);
      }
      type += "]";
      return type;
    } else {
      return typeof jsonValue;
    }
  };
  /**
   * Returns a string representation of the true TypeScript type.
   *
   * @param trueValue the true value
   *
   * @returns {string} the string representation
   */
  JsonConvert.prototype.getTrueType = function (trueValue) {
    return typeof trueValue;
  };
  return JsonConvert;
}();
export { JsonConvert };
