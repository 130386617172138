import {Component} from "@angular/core";
import {Location} from "@angular/common";

@Component({
  selector: 'app-footer',
  templateUrl: 'app-footer.component.html',
  styleUrl: 'app-footer.component.scss'
})
export class AppFooterComponent {
  protected copyrightYear = (new Date()).getFullYear();
  private currentUrl: string | undefined;

  constructor(private location: Location) {
    this.location.onUrlChange((url) => {
      this.currentUrl = url.split('?')[0];
    })
  }

  public currentPage(pageUrl: string): boolean {
    return this.currentUrl === pageUrl;
  }

  public createError = () => {
    throw new Error('This is a test error, did it work?')
  }
}
