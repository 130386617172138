import { JsonObject, JsonProperty } from 'json2typescript';
import {DayJSConverter} from "@hidat/ng-api";
import * as dayjs from "dayjs";
import { AnyHash } from '@hidat/huijs-interfaces';
import {UserMixInterface} from "@splixer/interfaces";

@JsonObject('PlayerUserMix')
export class PlayerUserMix implements UserMixInterface {
  @JsonProperty('id', Number, true)
  id = -1;
  @JsonProperty('status', Number, true)
  status = 100;
  @JsonProperty('created_at', DayJSConverter, true)
  createdAt: dayjs.Dayjs | undefined = undefined;
  @JsonProperty('updated_at', DayJSConverter, true)
  updatedAt: dayjs.Dayjs | undefined = undefined;

  @JsonProperty('instance_id', Number, true)
  instanceId: number | undefined = undefined;
  @JsonProperty('user_id', Number, true)
  userId: number | undefined = undefined;
  @JsonProperty('station_id', Number, true)
  stationId: number | undefined = undefined;
  @JsonProperty('content_path_id', Number, true)
  contentPathId: number | undefined = undefined;

  @JsonProperty('name', String, true)
  name: string | undefined = undefined;
  @JsonProperty('mix_config', Object, true)
  mixConfig: AnyHash = {};
}
