import {JsonObject, JsonProperty} from 'json2typescript';
import {PlayerHost} from "./host.model";
import {PlayerMix} from "./player-mix.model";
import {PlayerShow} from "./show.model";
import {PlayerProgramBase} from "./program-base.model";

@JsonObject('PlayerProgram')
export class PlayerProgram extends PlayerProgramBase  {
  @JsonProperty('_host', PlayerHost, true)
  _host: PlayerHost | undefined = undefined;
  @JsonProperty('_mixes', [PlayerMix], true)
  _mixes: PlayerMix [] = [];
  @JsonProperty('_last_show_mix', PlayerMix, true)
  _lastShowMix: PlayerMix | undefined = undefined;
  @JsonProperty('_shows', [PlayerShow], true)
  _shows: PlayerShow [] = [];
  @JsonProperty('_show_count', Number, true)
  _showCount = 0;
  @JsonProperty('_mix_count', Number, true)
  _mixCount = 0;

}
