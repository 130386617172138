import {Component, Input} from "@angular/core";
import {PlayerComponent} from "@splixer/player";
import {faCirclePlay} from "@fortawesome/pro-duotone-svg-icons/faCirclePlay";
import {faCirclePause} from "@fortawesome/pro-duotone-svg-icons/faCirclePause";
import {faAnglesLeft} from "@fortawesome/pro-regular-svg-icons/faAnglesLeft";
import {faAnglesRight} from "@fortawesome/pro-regular-svg-icons/faAnglesRight";
import {faForwardStep} from "@fortawesome/pro-solid-svg-icons/faForwardStep";
import {faBackwardStep} from "@fortawesome/pro-solid-svg-icons/faBackwardStep";

@Component({
  selector: 'pv2-player',
  templateUrl: 'v2-player.component.html',
  styleUrls: ['v2-player.component.scss']
})
export class V2PlayerComponent extends PlayerComponent {
  @Input() canScrub = true;
  @Input() canSkip = true;

  protected playIcon = faCirclePlay;
  protected pauseIcon = faCirclePause;
  protected backIcon = faAnglesLeft;
  protected forwardIcon = faAnglesRight;
  protected skipForwardIcon = faForwardStep;
  protected skipBackIcon = faBackwardStep;


  public startOver = () => {
    this.playerEngine.setPosition(0);
  }

  public skip = () => {
    /*if (this.session.canSkip()) {*/
    this.session.gotoNextItemToPlay();
  }
}
