import {Component, Input} from "@angular/core";
import {PlayButtonController} from "./play-button.controller";
import {faPlay} from "@fortawesome/pro-solid-svg-icons/faPlay";

/**
 * Renders a play button with play icon on the left, duration on the right.
 */
@Component({
  selector: 'pv2-duration-play-button',
  templateUrl: 'duration-play-button.component.html',
  styleUrl: 'play-button.component.scss'
})
export class DurationPlayButtonComponent extends PlayButtonController {
  @Input() duration: number | undefined;

  constructor() {
    super();
    this.faPlay = faPlay;
  }
}
