<div class="v2-player">
  <div class="scrub-bar-container">
    <div class="time-left">
      {{ position | secondsToMinutes }}
    </div>
    <div class="scrub-bar">
      <div class="progress-container">
        <div class="progress-position" [ngStyle]="{'width': percPlayed + '%'}"></div>
      </div>
    </div>
    <div class="time-right">
      {{ (duration - position) | secondsToMinutes }}
    </div>
  </div>

  <div class="player-controls">
    @if (canSkip) {
      <div class="start-over-control icon-button">
        <a (click)="startOver()">
          <fa-icon [icon]="skipBackIcon"></fa-icon>
        </a>
      </div>
    }

    @if (canScrub) {
      <div class="back-control icon-button">
        <a (click)="reverse()">
          <fa-icon [icon]="backIcon"></fa-icon>
        </a>
      </div>
    }

    <div (click)="togglePause()" class="playback-toggle">
      <div [ngSwitch]="controlState">
        <div *ngSwitchCase="PlayerControlState.Play">
          <fa-duotone-icon [icon]="playIcon" secondaryOpacity="1"></fa-duotone-icon>
        </div>
        <div *ngSwitchCase="PlayerControlState.Pause">
          <fa-duotone-icon [icon]="pauseIcon" secondaryOpacity="1"></fa-duotone-icon>
        </div>

        <div *ngSwitchCase="PlayerControlState.Indeterminate">
          <fa-duotone-icon [icon]="playIcon" secondaryOpacity="1"></fa-duotone-icon>
        </div>
      </div>
    </div>

    @if (canScrub) {
      <div class="forward-control icon-button">
        <a (click)="fastForward()">
          <fa-icon [icon]="forwardIcon"></fa-icon>
        </a>
      </div>
    }

    @if (canSkip) {
      <div class="skip-forward-control icon-button">
        <a (click)="skip()">
          <fa-icon [icon]="skipForwardIcon"></fa-icon>
        </a>
      </div>
    }

  </div>
</div>
