import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonConvert } from 'json2typescript';
import {catchError, Observable, throwError} from 'rxjs';
import {
  AnyHash,
  StringHash,
} from '@hidat/huijs-interfaces';
import {ApiInterface, BaseApiInterface} from "@hidat/huijs-api";
import {MessageLevels, MessageNotifierService, NotificationKlass} from "@hidat/ng-common";

export class HttpApiInterface<T>
  extends BaseApiInterface<T>
  implements ApiInterface<T>
{
  protected httpClient: HttpClient;
  protected notifier: MessageNotifierService

  constructor(
    injector: Injector,
    baseUrl: string,
    endpoint: string,
    protected jsonConvert: JsonConvert,
    protected jsonClass: any
  ) {
    super(baseUrl, endpoint);
    this.httpClient = injector.get(HttpClient);
    this.notifier = injector.get(MessageNotifierService);
  }

  /**
   * Deserialize
   * Helper function to deserialize the resource from JSON.
   * By default uses the provided json converter.
   * Override in your class if you need to create something fancier.
   * @param jsonItem
   */
  protected deserialize = (jsonItem: object): T => {
    //console.log('Deserializing: ', jsonItem);
    return this.jsonConvert.deserialize(
      jsonItem,
      this.jsonClass
    ) as unknown as T;
    //For resource level interface
    //item.$slice = this.currentSlice;
    // Include includes if they haven't already been assigned
    //if (!item['$includes']) {
    //  item.$includes = jsonItem.$includes ?? this.includes;
    //}
  };

  /**
   * Serialize
   * Converts the resource to JSON
   * By default uses the provided json converter.
   * Override in your class if you need to create something fancier.
   * @param item
   */
  protected serialize = (item: T): object => {
    const d = item as unknown as object;
    return this.jsonConvert.serialize(d, this.jsonClass);
  };

  /**
   * Get Raw
   * Generic GET to te API endpoint, returning the raw data
   * @param url
   * @param queryParams Params to pass straight through to the endpoint
   * @param responseType  Desired response type, defaults to Blob
   * @param httpOptions Raw options for the angular HttpClient
   */
  public getRaw(
    url: string,
    responseType: string = 'json',
    queryParams?: StringHash,
    httpOptions: AnyHash = {}
  ): Observable<any> {
    httpOptions['observe'] = 'body';
    httpOptions['responseType'] = responseType;
    if (queryParams) {
      httpOptions['params'] = this.cleanQueryParams(queryParams);
    }

    return this.httpClient.get<any>(url, httpOptions).pipe(
      catchError((err) => {
        this.handleError(err, 'get', url);
        return throwError(err);
      })
    );
  }

  /**
   * Put Raw
   * Generic PUT to te API endpoint, returning the raw data
   * @param url Full URL to post to
   * @param payload JSON Payload to pass straight through to the endpoint
   * @param responseType  Desired response type, defaults to Blob
   * @param queryParams Any query string parameters to pass as part of the URL
   * @param httpOptions Raw options for the angular HttpClient
   */
  public putRaw(
    url: string,
    payload: any,
    responseType: string,
    queryParams?: StringHash,
    httpOptions: AnyHash = {}
  ): Observable<any> {
    httpOptions['observe'] = 'body';
    httpOptions['responseType'] = responseType;
    if (queryParams) {
      httpOptions['params'] = this.cleanQueryParams(queryParams);
    }

    return this.httpClient.put<any>(url, payload, httpOptions).pipe(
      catchError((err) => {
        this.handleError(err, 'put', url);
        return throwError(err);
      })
    );
  }

  /**
   * Post Raw
   * Generic POST to te API endpoint, returning the raw data
   * @param url Full URL to post to
   * @param payload JSON Payload to pass straight through to the endpoint
   * @param responseType  Desired response type, defaults to Blob
   * @param queryParams Any query string parameters to pass as part of the URL
   * @param httpOptions Raw options for the angular HttpClient
   */
  public postRaw(
    url: string,
    payload: any,
    responseType: string,
    queryParams?: StringHash,
    httpOptions: AnyHash = {}
  ): Observable<any> {
    httpOptions['observe'] = 'body';
    httpOptions['responseType'] = responseType;
    if (queryParams) {
      httpOptions['params'] = this.cleanQueryParams(queryParams);
    }

    return this.httpClient.post<any>(url, payload, httpOptions).pipe(
      catchError((err) => {
        this.handleError(err, 'post', url);
        return throwError(err);
      })
    );
  }

  /**
   * Delete Raw
   * Generic DELETE to te API endpoint, returning the raw data
   * @param url Full URL to post to
   * @param responseType  Desired response type, defaults to Blob
   * @param queryParams Any query string parameters to pass as part of the URL
   * @param httpOptions Raw options for the angular HttpClient
   */
  deleteRaw(
    url: string,
    responseType: string,
    queryParams?: StringHash,
    httpOptions: AnyHash = {}
  ): Observable<any> {
    httpOptions['observe'] = 'body';
    httpOptions['responseType'] = responseType;
    if (queryParams) {
      httpOptions['params'] = this.cleanQueryParams(queryParams);
    }

    return this.httpClient.delete<any>(url, httpOptions).pipe(
      catchError((err) => {
        this.handleError(err, 'delete', url);
        return throwError(err);
      })
    );
  }

  /**
   * Clean Params
   * Removes any null/undefined keys from the hash, as they don't get serialized well....
   * @param queryParams
   * @protected
   */
  protected cleanQueryParams(queryParams: AnyHash): AnyHash {
    const cleanParams: AnyHash = {};
    for (const [k, v] of Object.entries(queryParams)) {
      if (v != null) {
        cleanParams[k] = v;
      }
    }
    return cleanParams;
  }

  /**
   * Handle Error
   * @TODO: pass as unknown and react based on error type
   * @param err
   * @param operation
   * @param url
   * @protected
   */
  protected handleError(err: any, operation: string, url: string) {
    let msg: string | undefined;
    let status: number | undefined;
    console.log(err);
    if (err && err.error && err.error.message) {
      // client side error
      msg = err.error.message;
      status = err.error.status;
    } else if (err && err.message) {
      // server side error
      msg = err.message;
      status = err.status;
    } else {
      msg = JSON.stringify(err);
    }

    this.notifier.sendMessage({
      klass: NotificationKlass.HTTP,
      source: operation + ": " + url,
      eventId: status,
      message: msg,
      extra: err,
      level: MessageLevels.ERROR
    })
  }
}
