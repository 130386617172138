@if (content) {
  <div class="track-banner">
    <div class="metadata-wrapper">
      <div class="title-and-performer">
        <div class="track-name">{{ content.name }}</div>
        @if (content.performerName) {
          <div class="divider"> -by-</div>
          <div class="performer-name">{{ content.performerName }}</div>
        }
      </div>
      @if (content.releaseName && content.releaseName !== content.name) {
        <div class="divider"> -from-</div>
        <div class="release-name">{{ content.releaseName }}</div>
      }
    </div>
  </div>
}
