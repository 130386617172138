import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import { CommonModule } from '@angular/common';
import {PropertyListComponent} from "./property-list/property-list.component";
import {HoursOrMinutesPipe, SecondsToMinutesPipe, YesNoPipe} from "./display-helpers";
import {PrettyJsonComponent} from "./pretty-json/pretty-json.component";
import {AppConfig, AppControlService} from "./app";
import {HngTemplate} from "./helpers/hng-template.directive";
import {HngSafeHtmlComponent} from "./safe-html/safe-html.component";
import {HngLoadingContainerComponent} from "./loading-container/loading-container.component";

const components = [
  PropertyListComponent,
  SecondsToMinutesPipe,
  YesNoPipe,
  HoursOrMinutesPipe,
  PrettyJsonComponent,
  HngTemplate,
  HngSafeHtmlComponent,
  HngLoadingContainerComponent
]

@NgModule({
  imports: [CommonModule],
  declarations: components,
  exports: components
})
export class HngCommonModule {
  constructor(@Optional() @SkipSelf() parentModule?: HngCommonModule) {
    if (parentModule) {
      console.warn('HNG Common is already loaded.');
      /*throw new Error(
        'HNG Common is already loaded.  Import it in your AppModule only'
      )*/
    }
  }

  static forRoot(config: AppConfig): ModuleWithProviders<HngCommonModule> {
    return {
      ngModule: HngCommonModule,
      providers: [
        AppControlService,
        {
          provide: AppConfig,
          useValue: config
        }
      ]
    }
  }
}
