import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnChanges, Output} from "@angular/core";
import {Pv2ContentMenuItem} from "../menu-item.interface";
import {Router} from "@angular/router";

/**
 * Implements a 'tag-cloud' based nav/filter.
 * When setup for navigation, you should provide a router link for each menu item, and when the tag item is selected,
 * the given navigation will be executed.
 * When used for a filter, you need to define a 'tagSelected' handler, which will be called each time
 */
@Component({
  selector: 'pv2-tag-cloud',
  templateUrl: 'tag-nav.component.html',
  styleUrl: 'tag-nav.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagNavComponent {
  @Input({required: true}) navItems: Pv2ContentMenuItem [] | undefined;
  @Input() allowMultiple = true;
  @Output() tagSelected = new EventEmitter<Pv2ContentMenuItem []>();

  protected router = inject(Router);

  protected tagSelectedEvent = (tag: Pv2ContentMenuItem) => {
    console.debug('Tag selected')
    if (this.navItems) {
      if (tag.routerLink) {
        let route = tag.routerLink;
        if (!Array.isArray(route)) {
          route = [route];
        }
        this.router.navigate(route)
      } else {
        // If are turning this tag on, and we do NOT allow multiples, then turn off any existing selected tags
        if (!this.allowMultiple && !tag.selected) {
          for (const t of this.navItems) {
            if (t.selected) {
              t.selected = false;
              this.assignClasses(t);
            }
          }
        }

        if (tag.selected === undefined) {
          tag.selected = true;
        } else {
          tag.selected = !tag.selected;
        }
        this.assignClasses(tag);

        // And now build an array of all selected tags and pass it up
        const selectedItems: Pv2ContentMenuItem [] = [];
        for (const t of this.navItems) {
          if (t.selected) {
            selectedItems.push(t);
          }
        }
        this.tagSelected.emit(selectedItems);
      }
    }
  }

  protected assignClasses(tag: Pv2ContentMenuItem) {
    if (tag.selected) {
      tag.assignedClasses = ['selected'];
      if (tag.styleClass) {
        tag.assignedClasses.push(tag.styleClass);
      }
    } else {
      tag.assignedClasses = [];
      if (tag.styleClass) {
        tag.assignedClasses.push(tag.styleClass);
      }
    }
  }


}
