import {Directive, Injector, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {PlaybackService} from "../services/playback.service";
import {PlayerEngineService} from "../services/player-engine.service";
import {MediaToPlay} from "../models";
import {HuiPlaybackErrorTypes, HuiPlaybackStatusTypes} from "@hidat/huijs-player";


/**
 * Player UI Control State
 * This represents the icon/control the UI should be showing, i.e.
 *   Indeterminate: We don't have anything to play, so don't show or disable player control
 *   Play: Show something to indicate that pressing it will START PLAYBACK
 *   Pause: Show something to indicate that pressing it will PAUSE PLAYBACK
 */
export enum PlayerControlState {
  Indeterminate,
  Play,
  Pause
}

/**
 * Player Controller Base
 * Inherit all your player UI components from this.
 */
@Directive()
export class PlayerComponent implements OnInit, OnDestroy {
  protected session: PlaybackService;  // So we can get at the current path, I don't really like this
  protected playerEngine: PlayerEngineService;

  protected itemPlaying: MediaToPlay | undefined;
  protected playing = false;
  protected paused = false;
  protected canPlay = false;   // Set to true once the current track is loaded.  Helps us track blocked content.
  protected percPlayed = 0;
  protected duration = 0;
  protected position = 0;
  protected playbackRate = 1;
  protected PlayerControlState = PlayerControlState;  //Make available in HTML scope
  protected controlState: PlayerControlState = PlayerControlState.Indeterminate;
  protected mediaLoading = false;
  protected mediaLoaded = false;
  protected currentError: number | undefined;

  private playbackProgressSubscription$: Subscription | undefined;
  private playbackStatusSubscription$: Subscription | undefined;

  constructor(injector: Injector) {
    this.session = injector.get(PlaybackService);
    this.playerEngine = injector.get(PlayerEngineService);
  }

  ngOnInit(): void {
    this.setupSubscriptions();
    // Check initial state.  This covers instances where the player is created after the playback is started.
    this.setInitialState();
  }

  ngOnDestroy() {
    this.playbackStatusSubscription$?.unsubscribe();
    this.playbackProgressSubscription$?.unsubscribe();
  }

  setupSubscriptions() {
    this.playbackStatusSubscription$ = this.playerEngine.playbackStatusSource.subscribe((playbackStatusEvent) => {
        this.playing = playbackStatusEvent.playing;
        this.paused = playbackStatusEvent.paused;
        this.itemPlaying = playbackStatusEvent.source as MediaToPlay;
        this.currentError = playbackStatusEvent.errorType || HuiPlaybackErrorTypes.NO_ERROR;
        switch (playbackStatusEvent.eventID) {
          case HuiPlaybackStatusTypes.LOADING:
            this.mediaLoading = true;
            this.mediaLoaded = false;
            this.controlState = PlayerControlState.Indeterminate;
            this.canPlay = false;
            break;

          case HuiPlaybackStatusTypes.STARTED:
            this.canPlay = true;
            if (playbackStatusEvent.reallyPlaying) {
              this.controlState = PlayerControlState.Pause;
            }
            break;

          case HuiPlaybackStatusTypes.REALLY_STARTED:
            this.canPlay = true;
            this.controlState = PlayerControlState.Pause;
            break;

          case HuiPlaybackStatusTypes.RESUMED:
            this.canPlay = true;
            this.controlState = PlayerControlState.Pause;
            break;

          case HuiPlaybackStatusTypes.PAUSED:
            this.canPlay = true;
            this.controlState = PlayerControlState.Play;
            break;

          case HuiPlaybackStatusTypes.FILE_LOADED:
            this.mediaLoading = false;
            this.mediaLoaded = true;
            this.canPlay = true;

            // if we aren't already playing, indicate that we can play
            if (!playbackStatusEvent.reallyPlaying) {
              this.controlState = PlayerControlState.Play;
            }
            this.onMediaLoaded();
            break;

          case HuiPlaybackStatusTypes.STOPPED:
            this.canPlay = true;
            this.controlState = PlayerControlState.Play;
            break;

          case HuiPlaybackStatusTypes.ERROR:
            this.canPlay = true;
            this.controlState = PlayerControlState.Indeterminate;
            break;

          case HuiPlaybackStatusTypes.FINISHED:
            this.controlState = PlayerControlState.Indeterminate;
            this.canPlay = false;
            this.position = 0;
            this.percPlayed = 0;
            this.duration = 0;
            break;
        }
      }
    )

    this
      .playbackProgressSubscription$ = this.playerEngine.playbackProgressSource.subscribe((progressEvent) => {
      // If we are getting progress, then we are playing. Covers up many sins!
      this.playing = true;
      this.paused = false;

      this.percPlayed = progressEvent.percDone * 100;
      this.duration = progressEvent.duration;
      this.position = progressEvent.position;
    })

  }

  protected onMediaLoaded() {
    console.log('Media Loaded: ' + this.itemPlaying?.id);
  }

  protected setInitialState() {
    const status = this.playerEngine.getPlaybackStatus();
    this.itemPlaying = status.source as MediaToPlay
    if (status.playing) {
      this.canPlay = true;
      this.controlState = PlayerControlState.Pause;
    } else {
      this.canPlay = true;
      this.controlState = PlayerControlState.Play;
    }
  }

  public skipSeconds(skippingBack: boolean): number {
    let ms = 30;
    const segment = this.itemPlaying?.segment;
    if (segment) {
      if (segment.licensingKlass) {
        if (segment.licensingKlass.toLowerCase() === 'dmca') {
          if (skippingBack) {
            ms = -1;
          } else {
            ms = 600;
          }
        }
      }
    }
    return ms;
  }

  /**
   * Toggle pause.
   */
  togglePause() {
    if (this.canPlay) {
      this.playerEngine.togglePlayback();
    }
  }

  /**
   * Skip ahead in the current stream
   */
  fastForward() {
    if (this.playing) {
      const ms = this.skipSeconds(false);
      if (this.duration - this.position > ms) {
        this.playerEngine.setPosition((this.position + ms) * 1000);
      }
    }
  }

  /**
   * Skip ahead in the current stream
   */
  reverse() {
    if (this.playing) {
      let newPosition = 0;
      const ms = this.skipSeconds(true);
      if (this.position > ms) {
        newPosition = (this.position - ms) * 1000;
      }
      this.playerEngine.setPosition(newPosition);
    }
  }

  togglePlaybackRate() {
    if (this.playbackRate < 4) {
      this.playbackRate = 4;
    } else {
      this.playbackRate = 1;
    }
    this.playerEngine.setPlaybackRate(this.playbackRate);
  }

  /**
   * Increase playback rate (chipmunks!)
   */
  fasterPlayback() {
    if (this.playbackRate < 4) {
      this.playbackRate += .1;
      this.playerEngine.setPlaybackRate(this.playbackRate);
    }
  }

  /**
   * Decrease playback rate (Drunk Trump)
   */
  slowerPlayback() {
    if (this.playbackRate > .5) {
      this.playbackRate -= .1;
      this.playerEngine.setPlaybackRate(this.playbackRate);
    }
  }

}
