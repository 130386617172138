@import "../../styles/pv2-variables";

.show-name, .mix-name {
  font-size: 1.15rem;
  font-weight: bold;
}

.content-host-name {
  font-size: 1.05rem;
}

.track-info, .track-banner {
  .title-and-performer {
    font-size: 1rem;

    .track-name, .performer-name {
      display: inline;
    }

    .track-name {
      font-weight: 700;
      //color: var(--brand-primary-text-color, $primary-text-color);
    }
  }

  .divider {
    display: inline;
    padding: 0 .3rem;
    font-size: .95rem;
    font-style: italic;
  }

  .release-name {
    font-size: .95rem;
  }
}

.track-info {
  .played-at-time {
    font-size: .8rem;
  }

  .label {
    font-size: .9rem;
  }

  @media screen and (min-width: $tabletUpBreakpoint) {
    .title-and-performer {
      font-size: 1.05rem;
    }
  }
}

.track-banner {
  .title-and-performer, .release-name {
    display: inline;
  }
  .release-name {

  }
  padding-bottom: .25rem;
}

.segment-wrapper {
  border-bottom: 1px solid var(--brand-inverse-border-color, $gray-400) ;
  padding: .25rem .1rem .25rem .5rem;
  margin-left: .5rem;
  margin-top: .175rem;
}

