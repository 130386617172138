import {catchError, map, Observable, of, take} from 'rxjs';
import {DataSet} from "../client/data-set";
import {ApiInterface, SerializationLevel} from "../api-interface.js";
import {ResultStatus} from "../results-status";
import {AnyHash, StringHash} from "@hidat/huijs-interfaces";
import {ApiEnvelope} from "../client/api-envelope.interface";

/**
 * Base Api Interface
 * Implements an abstract implementation of an API interface.
 * Inherited class only has to implement the 'raw' abstract functions to finish off the interface.
 * This should move up in the chain so we can create non-ng versions of the interface
 */
export abstract class BaseApiInterface<T> implements ApiInterface<T> {
  protected resourceBaseUrl: string;
  protected currentSlice?: number;
  protected includes?: string;

  public lastDataSet?: DataSet<T>;

  constructor(protected baseUrl: string, protected endpoint: string) {
    this.resourceBaseUrl = baseUrl + '/' + this.endpoint.toLowerCase();
  }

  /***** Raw network access routines to be implemented **************************************************************/
  public abstract getRaw(
    url: string,
    responseType: string,
    queryParams?: StringHash,
    httpOptions?: AnyHash
  ): Observable<any>;

  public abstract putRaw(
    url: string,
    payload: any,
    responseType: string,
    queryParams?: StringHash,
    httpOptions?: AnyHash
  ): Observable<any>;

  public abstract postRaw(
    url: string,
    payload: any,
    responseType: string,
    queryParams?: StringHash,
    httpOptions?: AnyHash
  ): Observable<any>;

  public abstract deleteRaw(
    url: string,
    responseType: string,
    queryParams?: StringHash,
    httpOptions?: AnyHash
  ): Observable<any>;

  /**
   * Deserialize
   * Converts the raw json object to your strongly typed class.
   * By default just does a typecast, which isn't very useful except for the simplest of test classes....
   * @param jsonItem
   */
  protected abstract deserialize(jsonItem: object): T;

  /**
   * Serialize
   * Converts the strongly typed class to generic JSON.
   * By default just returns the class back, so you will get default JSON conversions, which probably isn't want you want....
   * Override in your class to do better.
   * @param item
   */
  protected abstract serialize(item: T): object;

  /**
   * Url For Array
   * Constructs a url from the given array of segments
   * @param segments
   */
  public urlFor(segments: (string | number)[]): string {
    let url = this.resourceBaseUrl;
    for (const segment of segments) {
      const s = segment.toString();
      if (s) {
        url += '/' + s;
      }
    }
    return url;
  }

  /**
   * Process API Results
   * Given the rawJson, converts it to a dataset.
   * This may be as simple as just passing the json to the dataset constructor, if we have an API Envelope,
   * or if we don't have an envelope, constructing the dataset from the raw results.
   * If there is no envelope (rawJson is an array, or doesn't have a status and version), then we assume that this
   * is raw data (NOT a HUI backend), then we mock the API Envelope, and load the dataset.
   * @param rawJson
   * @param deserialize
   * @protected
   */
  public processApiResults(
    rawJson: any,
    deserialize = true
  ): DataSet<T | AnyHash | undefined> {
    let ds: DataSet<T>;
    // Lets see if we have a API envelope
    if (rawJson) {
      if (!Array.isArray(rawJson) && rawJson.status && rawJson.version) {
        if (deserialize) {
          ds = new DataSet(rawJson, this.deserialize);
        } else {
          ds = new DataSet(rawJson);
        }
      } else {
        let totalRows = 1;
        if (Array.isArray(rawJson)) {
          totalRows = rawJson.length;
        }
        const apiEnvelope: ApiEnvelope = {
          status: ResultStatus.OK,
          version: '1',
          data: rawJson,
          counts: {rows: totalRows, errors: 0},
        };

        if (deserialize) {
          ds = new DataSet(apiEnvelope, this.deserialize);
        } else {
          ds = new DataSet(apiEnvelope);
        }
      }
    } else {
      ds = new DataSet();
    }
    this.lastDataSet = ds;
    return ds;
  }

  /**
   * Generic JSON Get
   * Sends a get request to the given URL under the current base URL.
   * Returns a DataSet.
   * Results can be optionally deserialized to T.
   * @param endpointUrl  An array of url segments to be added the endpoint to generate the final url
   * @param deserialize  If true, results will be deserialized to T
   * @param queryParams  Optional query params to pass through.
   */
  public get(
    endpointUrl: string[],
    deserialize: boolean,
    queryParams: StringHash = {}
  ): Observable<DataSet<T | AnyHash | undefined>> {
    const url = this.urlFor(endpointUrl);
    const request = this.getRaw(url, 'json', queryParams);
    return request.pipe(
      take(1),
      map((rawJson: any) => {
        return this.processApiResults(rawJson, deserialize);
      }),
      catchError(err => this.buildErrorDataSet(err))
    );
  }

  /**
   * Extension to get which just returns the first record returned.
   * @param endpointUrl
   * @param deserialize
   * @param queryParams
   */
  public getOne(
    endpointUrl: string[],
    deserialize: boolean,
    queryParams: StringHash = {}
  ): Observable<T | AnyHash | undefined> {
    return this.get(endpointUrl, deserialize, queryParams).pipe(
      map((ds) => {
        if (ds) {
          return ds.firstItem();
        }
        return undefined;
      })
    );
  }

  /**
   * Extension to get which just returns the records as an array instead of a dataset.
   * @param endpointUrl
   * @param deserialize
   * @param queryParams
   */
  public getMany(
    endpointUrl: string[],
    deserialize: boolean,
    queryParams: StringHash = {}
  ): Observable<(T | AnyHash | undefined)[] | undefined> {
    return this.get(endpointUrl, deserialize, queryParams).pipe(
      map((ds) => {
        if (ds) {
          return ds.allItems();
        }
        return undefined;
      })
    );
  }

  /**
   * Generic Post
   * Sends a post request for the given 'action' under the current base URL.
   * Expects the endpoint to return a dataset.
   * @param endpointUrl
   * @param item
   * @param serialize
   * @param queryParams
   */
  public post(
    endpointUrl: string[],
    item: T | AnyHash,
    serialize: SerializationLevel,
    queryParams: StringHash = {}
  ): Observable<DataSet<T | AnyHash | undefined>> {
    let payload: AnyHash | undefined;

    if (item) {
      if (
        serialize === SerializationLevel.Input ||
        serialize === SerializationLevel.Both
      ) {
        payload = this.serialize(item as unknown as T);
      } else {
        payload = item;
      }
    }

    const url = this.urlFor(endpointUrl);
    const request = this.postRaw(url, payload, 'json', queryParams);
    return request.pipe(
      take(1),
      map((rawJson: any) => {
        const ds = this.processApiResults(
          rawJson,
          serialize === SerializationLevel.Output ||
          serialize === SerializationLevel.Both
        );
        return ds;
      }),
      catchError(err => this.buildErrorDataSet(err))
    );
  }

  public postOne(
    endpointUrl: string[],
    item: T | AnyHash,
    serialize: SerializationLevel,
    queryParams: StringHash = {}
  ): Observable<T | AnyHash | undefined> {
    //console.log('Post One');
    return this.post(endpointUrl, item, serialize, queryParams).pipe(
      map((ds) => {
        if (ds) {
          return ds.firstItem();
        }
        return undefined;
      })
    );
  }

  /**
   * Generic JSON Put
   * Sends a put request to the given URL under the current base URL.
   * Returns a DataSet.
   * Results can be optionally deserialized to T.
   * @param endpointUrl  An array of url segments to be added the endpoint to generate the final url
   * @param item  A resource object of T or a AnyHash to be passed as the payload
   * @param serialize
   * @param queryParams  Optional query params to pass through.
   */
  public put(
    endpointUrl: string[],
    item: T | AnyHash,
    serialize: SerializationLevel,
    queryParams: StringHash = {}
  ): Observable<DataSet<T | AnyHash | undefined>> {
    let payload: AnyHash | undefined;

    if (item) {
      if (
        serialize === SerializationLevel.Input ||
        serialize === SerializationLevel.Both
      ) {
        payload = this.serialize(item as unknown as T);
      } else {
        payload = item;
      }
    }

    const url = this.urlFor(endpointUrl);
    const request = this.putRaw(url, payload, 'json', queryParams);
    return request.pipe(
      take(1),
      map((rawJson: any) => {
        return this.processApiResults(
          rawJson,
          serialize === SerializationLevel.Output ||
          serialize === SerializationLevel.Both
        );
      }),
      catchError(err => this.buildErrorDataSet(err))
    );
  }

  public putOne(
    endpointUrl: string[],
    item: T | AnyHash,
    serialize: SerializationLevel,
    queryParams: StringHash = {}
  ): Observable<T | AnyHash | undefined> {
    //console.log('Put One');
    return this.put(endpointUrl, item, serialize, queryParams).pipe(
      map((ds) => {
        if (ds) {
          return ds.firstItem();
        }
        return undefined;
      })
    );
  }

  /**
   * Generic JSON Delete
   * Sends a delete request to the given URL under the current base URL.
   * Returns a DataSet, which may have no items in it, but should have counts/error info.
   * Results can be optionally deserialized to T.
   * @param endpointUrl  An array of url segments to be added the endpoint to generate the final url
   * @param deserialize  If true, results will be deserialized to T
   * @param queryParams  Optional query params to pass through.
   */
  public delete(
    endpointUrl: string[],
    deserialize: boolean,
    queryParams?: StringHash
  ): Observable<DataSet<T | AnyHash | undefined>> {
    const url = this.urlFor(endpointUrl);
    const request = this.deleteRaw(url, 'json', queryParams);
    return request.pipe(
      take(1),
      map((rawJson: any) => {
        return this.processApiResults(rawJson, deserialize);
      }),
      catchError(err => this.buildErrorDataSet(err))
    );
  }

  /**
   * Build Error DS
   * @param err
   * @protected
   */
  protected buildErrorDataSet(err: any): Observable<DataSet<T>> {
    let msg: string | undefined;
    let status: number | undefined;
    if (err && err.error && err.error.message) {
      // client side error
      msg = err.error.message;
      status = err.error.status;
    } else if (err && err.message) {
      // server side error
      msg = err.message;
      status = err.status;
    } else {
      msg = JSON.stringify(err);
    }
    const errDs = new DataSet<T>();
    errDs.errorEncountered = true;
    errDs.errorMsg = msg;
    if (status != null) {
      errDs.errorCode = status.toString();
    }
    console.error('API Error: ', msg)
    return of(errDs);
  }
}
