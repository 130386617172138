import {Directive, inject, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {Router} from "@angular/router";
import {
  InstanceManagerService,
  PlaybackService,
  SessionsManagerService,
  StationManagerService,
  TimelineService
} from "@splixer/player";
import {NotifyService} from "@hidat/ng-bootstrap";
import {PSNotification, PSNotificationTypes} from "@hidat/huijs-player";
import {AnyHash, uString} from "@hidat/huijs-interfaces";
import {V2BasePageController} from "./base-page.controller";

/**
 * Base page for a player app's home/landing page
 */
@Directive()
export abstract class Pv2StationHomePageController extends V2BasePageController implements OnInit {
  protected showPlaybackModal = false;
  protected psNotifier$: Subscription;
  protected stationClasses: string [] = [];
  protected timeline = inject(TimelineService);
  protected notify = inject(NotifyService);
  protected session = inject(SessionsManagerService);
  protected instance = inject(InstanceManagerService);
  protected router = inject(Router);

  protected mixStarted = false;
  protected showNowPlaying = false;
  protected npMessage: uString;
  protected knownInstance = false;
  protected pageKlasses: AnyHash = {
    'known-instance': false,
    'unknown-instance': true,
    'content-started': false
  };

  constructor(protected playbackService: PlaybackService) {
    super();
    this.psNotifier$ = this.playbackService.notifier.subscribe(notification => this.handleNotification(notification));
  }

  ngOnInit() {
    if (this.station.stationCode) {
      this.setPageTitle('Home', true);
      this.stationClasses.push(this.station.stationCode.toLowerCase());
      this.stationLoaded();
      this.knownInstance = this.instance.loaded();
      this.pageKlasses['known-instance'] = this.knownInstance;
      this.pageKlasses['unknown-instance'] = !this.knownInstance;
    } else {
      this.npMessage = 'No Station Content Loaded!'
    }
  }

  /**
   * Handle Playback Error
   * Notifies the user of playback errors.
   * Primary purpose is to give a 'Press Play' modal when browser stops us.
   * @param notification
   */
  private handleNotification = (notification: PSNotification): void => {
    console.log(notification.message);
    switch (notification.type) {
      case PSNotificationTypes.MIX_LOADING:
        this.npMessage = "Loading Mix...";
        // At this point, we have to have an instance
        this.knownInstance = true;
        this.pageKlasses['known-instance'] = true;
        this.pageKlasses['unknown-instance'] = false;
        break;
      case PSNotificationTypes.MIX_LOADED:
        console.debug('Mix Loaded')
        this.npMessage = "Mix Loaded";
        break;
      case PSNotificationTypes.PLAYBACK_ERROR:
        this.displayPlayModal();
        break;
      case PSNotificationTypes.NO_CONTENT:
        this.timeline.forceNowPlayingToPast();
        this.npMessage = "Please Choose Something to Listen To"
        this.showNowPlaying = false;
        this.mixStarted = false;
        break;
      case PSNotificationTypes.OUT_OF_SKIPS:
        this.notify.error("We're sorry, but you have run out of skips for this hour.", 'Audio Player');
        break;
      case PSNotificationTypes.LOAD_ERROR:
      case PSNotificationTypes.ERROR:
        this.npMessage = "There was an Error"
        this.showNowPlaying = false;
        this.mixStarted = false;
        this.notify.error(notification.message, 'Audio Player')
        break;
      case PSNotificationTypes.INFO:
        this.notify.info(notification.message, 'Audio Player');
        break;
      case PSNotificationTypes.MIX_PLAYING:
        this.pageKlasses['content-started'] = true;
        this.showNowPlaying = true;
        this.mixStarted = true;
        this.onMixStarted();
        break;
    }
  }

  protected abstract onMixStarted(): void;

  private displayPlayModal() {
    this.showPlaybackModal = true;
  }

  public modalPlay = (event?: Event) => {
    this.playbackService.togglePlayback();
    this.showPlaybackModal = false;
    if (event) {
      event.preventDefault();
    }
  }

  protected abstract stationLoaded(): void;

}
