import {Component, ViewEncapsulation} from "@angular/core";
import {faArrowLeft} from "@fortawesome/pro-solid-svg-icons";

const template = `
<a class="mobile-back-link hide-on-desktop" routerLink="..">
  <fa-icon [icon]="faArrowLeft"></fa-icon>
</a>
`;

/**
 * Back arrow that brings you back on level (..).
 * Relies on styling from headers.scss and common.scss
 */
@Component({
  selector: 'pv2-mobile-back-link',
  template,
  encapsulation: ViewEncapsulation.None
})
export class MobileBackLinkComponent {
  protected faArrowLeft=faArrowLeft;
}
