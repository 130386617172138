import {ChangeDetectionStrategy, Component, Input} from "@angular/core";
import {ContentItem} from "@splixer/player";

@Component({
  selector: 'pv2-track-banner',
  templateUrl: 'track-banner.component.html',
  styleUrl: '../player-v2-styles.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrackBannerComponent {
  @Input({required: true}) content: ContentItem | undefined;

}
