import { JsonObject, JsonProperty } from 'json2typescript';
import {DayJSConverter} from "@hidat/ng-api";
import * as dayjs from "dayjs";
import { MediaToPlay } from './scheduler.model';
import { PlayerMix } from './player-mix.model';
import { PlayerInstance } from './player-instance.model';
import { AnyHash } from '@hidat/huijs-interfaces';

@JsonObject('SkipStatus')
export class SkipStatus {
  @JsonProperty('can_skip', Boolean, true)
  canSkip = false;
  @JsonProperty('can_skip_at', DayJSConverter, true)
  canSkipAt: dayjs.Dayjs | undefined = undefined;
  @JsonProperty('count', Number, true)
  count = 0;
}

@JsonObject('LoadErrorInfo')
export class LoadErrorInfo {
  @JsonProperty('required', Number, true)
  requred = 0;
  @JsonProperty('available', Number, true)
  available = 0;
}
/**
 * Extension of the Playback session that includes all the 'includes' that the player uses.
 */
@JsonObject('PlayerSession')
export class PlayerSession {
  @JsonProperty('uid', String, true)
  id: string | undefined = undefined;
  @JsonProperty('status', Number, true)
  status = 100;

  @JsonProperty('instance', PlayerInstance, true)
  _instance: PlayerInstance | undefined = undefined;

  @JsonProperty('user_id', Number, true)
  userId: number | undefined = undefined;
  @JsonProperty('session_path_id', Number, true)
  sessionPathId: number | undefined = undefined;

  @JsonProperty('load_error', Number, true)
  loadError: number | undefined = undefined;
  @JsonProperty('load_error_info', LoadErrorInfo, true)
  loadErrorInfo: LoadErrorInfo | undefined = undefined;

  @JsonProperty('mix_mode', String, true)
  mixMode: string | undefined = undefined;
  @JsonProperty('skip_status', SkipStatus, true)
  skipStatus: SkipStatus | undefined = undefined;
  @JsonProperty('current_path', PlayerMix, true)
  currentPath: PlayerMix | undefined = undefined;
  @JsonProperty('current_state', Object, true)
  currentState: AnyHash = {};
  @JsonProperty('media_to_play', [MediaToPlay], true)
  mediaToPlay: MediaToPlay[] = [];

  @JsonProperty('alt_paths', [PlayerMix], true)
  _altPaths: PlayerMix[] = [];
  @JsonProperty('related_content', [PlayerMix], true)
  _relatedContent: PlayerMix[] | undefined = undefined;
  /*
  @JsonProperty('program', PlayerProgram, true)
  _program: PlayerProgram | undefined = undefined;
  @JsonProperty('show', PlayerShow, true)
  _show: PlayerShow | undefined = undefined;*/
}
