import {Directive, inject} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {StationManagerService} from "@splixer/player";
import {nString, uString} from "@hidat/huijs-interfaces";

/**
 * Base page for all player pages.
 * Adds title handling services.
 */
@Directive()
export class V2BasePageController {
  protected titleService = inject(Title);
  protected station = inject(StationManagerService);
  protected pageTitle: uString;


  protected setPageTitle(title: nString, addStationCode = true) {
    let browserTitle: uString;
    if (title) {
      browserTitle = title;
      this.pageTitle = browserTitle;
    }
    if (addStationCode && this.station.stationCode) {
      if (browserTitle) {
        browserTitle += ' - ' + this.station.stationCode;
      } else {
        browserTitle = this.station.stationCode;
      }
    }

    if (browserTitle) {
      this.titleService.setTitle(browserTitle);
    }
  }


}
