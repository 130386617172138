import {Component, Input} from "@angular/core";
import {TimelineSegment, TimelineSegmentItem} from "@splixer/player";

@Component({
  selector: 'pv2-segment-timeline',
  templateUrl: 'segment-timeline.component.html',
  styleUrl: "../player-v2-styles.scss"
})
export class SegmentTimelineComponent {
  @Input({required: true}) timelineSegment: TimelineSegment | undefined;
  @Input() currentTrack: TimelineSegmentItem | undefined;     // Current track which has yet to be pushed into the timeline segment
  @Input() contentOnly = true;
  @Input() displaySegmentInfo = false;
}
