import {NgModule} from "@angular/core";
import {HngCommonModule} from "@hidat/ng-common";
import {HngBootstrapModule} from "@hidat/ng-bootstrap";
import {RouterLink} from "@angular/router";
import {PlayerUiV2Module} from "@splixer/player-ui-v2";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {AppHeaderComponent} from "./layout/header/app-header.component";
import {AppFooterComponent} from "./layout/footer/app-footer.component";

const components = [
  AppHeaderComponent,
  AppFooterComponent
]

const modules = [
  HngBootstrapModule,
  HngCommonModule,
  PlayerUiV2Module,
  FontAwesomeModule,
]

@NgModule({
  imports: [
    ...modules,
    RouterLink
  ],
  declarations: components,
  exports: [
    ...modules,
    ...components
  ]
})
export class KnkxCoreModule {}
