
export interface HuiPlaybackProgressEvent {
  source: unknown | undefined,
  position: number,
  duration: number,
  percDone: number
}

export interface HuiPlaybackStatusEvent {
  source: unknown | undefined;
  eventID: number;
  playing: boolean;
  paused: boolean;
  reallyPlaying: boolean;
  errorType?: number;
}

export enum HuiPlaybackStatusTypes {
  ERROR = -100,
  SUSPENDED = -10,
  LOADING = 0,
  STARTED = 10,
  REALLY_STARTED = 15,
  FILE_LOADED = 20,
  PAUSED = 50,
  RESUMED = 60,
  STOPPED = 90,
  FINISHED = 100
}

export interface HuiPlaybackErrorEvent {
  errorType: number,
  message: string,
  source: unknown | undefined,
  //contentToPlay: HuiContentToPlayInterface | undefined,
}

export enum HuiPlaybackErrorTypes {
  NO_ERROR = 0,
  LOAD = 1,
  PLAYBACK = 2,
  GENERAL = 3,
}

export interface HuiPlayerEngine {
  init(config: object): boolean;
  play(itemToPlay: HuiMediaToPlay): void;   // Do better
  retryPlay(): void;
  stop(): void;
  togglePlayback(): void;
  playbackStatus(): number;
  back(milliseconds: number): number;
  forward(milliseconds: number): number;
  position(): number;
  setPosition(timeAsMilliseconds: number): void;
  setPlaybackRate(rate: number): void;
  pause(): void;
  resume(): void;
}

export interface HuiPlaybackItem {
  expiresAt: Date | undefined;
  urls: string [];
  source: unknown | undefined;
  duration: number;
}

/**
 * Defines a media item that the player will play
 */
export class HuiMediaToPlay implements HuiPlaybackItem {
  startedAt: Date | undefined;
  finishedAt: Date | undefined;
  playedThrough = false;
  duration = 0;
  expiresAt: Date | undefined;
  urls: string [] = [];
  source: unknown | undefined;

  constructor(urls: string [], source: unknown, duration = 0, expiresAt?: Date) {
    this.urls = urls;
    this.source = source;
    this.duration = duration;
    this.expiresAt = expiresAt;
  }

  markStarted(startedAt = new Date()): void {
    this.startedAt = startedAt;
  }

  markFinished(playedThrough = true, startedAt = new Date()): void {
    this.finishedAt = startedAt;
    this.playedThrough = playedThrough;
  }

  getPlaybackURL(): string [] {
    return this.urls;
  }

}

