@if (item) {
  <div class="button-grid-item">
    @if (item.title) {
      <div class="top-title">
        {{item.title}}
      </div>
    }
    <div class="button-grid-link" [ngStyle]="buttonStyles" [ngClass]="buttonClasses">
      @if (item.playable) {
        <a (click)="playEvent($event)" href="#" class="play-button">
          <fa-icon class="play-icon" [icon]="faPlay"></fa-icon>
        </a>
      } @else {
        <div class="top-link" (click)="clickEvent($event)"></div>
      }
      <a [routerLink]="item.routerLink ?? ''" (click)="clickEvent($event)" class="button-name">
        <div class="name-text">
          {{ item.name }}
        </div>
      </a>
    </div>
    @if (item.tagLine) {
      <div class="bottom-desc">
        {{item.tagLine}}
      </div>
    }
  </div>
}
