import {Component, inject, Input} from "@angular/core";
import {PlaybackService, TimelineService} from "@splixer/player";

@Component({
  selector: 'pv2-now-playing-page',
  templateUrl: 'now-playing-page.component.html',
  styleUrl: 'now-playing-page.component.scss'
})
export class NowPlayingPageComponent {
  @Input() displayRelatedMixes = true;
  @Input() displayPlaylists = true;
  @Input() displayRandomContent = false;

  protected playbackService = inject(PlaybackService);
  protected timeline = inject(TimelineService);

}
