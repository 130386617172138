import {Injectable} from '@angular/core';
import {AnyHash} from '@hidat/huijs-interfaces';
import {EventTrackerConfig} from "./event-tracker-config";
import {GaActionEnum, GoogleAnalyticsService} from "ngx-google-analytics";

/**
 * Analytics Tracking Service.
 * Used to track custom user interactions across the app, sending them to Google Analytics
 * NOTE: Currently only sends to the primary ga property code
 */
@Injectable()
export class EventTrackerService {
  private primaryTrackingCode: string | undefined;
  private secondaryTrackingCode: string | undefined;

  constructor(config: EventTrackerConfig, private gaService: GoogleAnalyticsService) {
    this.primaryTrackingCode = config.primaryTrackingCode;
    this.secondaryTrackingCode = config.secondaryTrackingCode;
  }

  private trackEvent(eventType: GaActionEnum | string, data: AnyHash) {
    this.gaService.gtag('event', eventType, data)
  }

  public pageView(url: string, title?: string): void {
    if (this.primaryTrackingCode != null) {
      this.gaService.pageView(url, title);
    }
    /*
    if (this.secondaryTrackingCode) {
      this.track('config', this.secondaryTrackingCode, {page_path: url});
    }*/
  }

  /*
  private gaTrackingCodes(): string | string[] | undefined {
    if (this.secondaryTrackingCode && this.primaryTrackingCode) {
      return [this.primaryTrackingCode, this.secondaryTrackingCode];
    }
    return this.primaryTrackingCode;
  }*/

  public signedIn(accessCode: string) {
    this.trackEvent(GaActionEnum.LOGIN, {
      method: accessCode,
    });
  }

  public mixSelected(category: string, mixName: string) {
    this.trackEvent(GaActionEnum.SELECT_CONTENT, {
      content_type: category,
      item_id: mixName
    });
  }

  public contentSkipped() {
    this.trackEvent( 'skipped', {
      event_category: 'playlist',
    });
  }

  public pathTaken(direction: string) {
    this.trackEvent('path_taken', {
      event_category: 'playlist',
      event_label: direction,
    });
  }

  public mixSaved() {
    this.trackEvent( 'mix_saved', {
      event_category: 'playlist',
    });
  }

  public apiError(description: string, fatal = false) {
    this.trackEvent( 'exception', {
      description: description,
      fatal: fatal,
    });
  }
}
