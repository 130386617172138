import {Injectable} from  '@angular/core';

/**
 * A simple service to hold, retrieve, and set the current instance id, session id, and user id in the local browser.
 * Currently this users LocalStorage to do this, but by putting the logic here, we can change this in the future....
 */
@Injectable({providedIn: "root"})
export class DeviceStoreService {
  private _instanceId: string | undefined;
  private _sessionId: string | undefined;
  private _userId: string | undefined;
  private _stationUid: string | undefined;
  private _infoPanelDismissed: string | undefined;
  readonly INSTANCE_KEY = 'TKInstanceID';
  readonly SESSION_KEY = 'TKSessionID';
  readonly USER_KEY = 'TKUserID';
  readonly INFO_PANEL_DISMISSED = 'TKInfoPanelDismissed';
  readonly STATION_UID = 'TkStationUid';

  constructor() {
    this._instanceId = this.readKey(this.INSTANCE_KEY);
    this._sessionId = this.readKey(this.SESSION_KEY);
    this._userId = this.readKey(this.USER_KEY);
    this._stationUid = this.readKey(this.STATION_UID);
    this._infoPanelDismissed = this.readKey(this.INFO_PANEL_DISMISSED);
  }

  private readKey(key: string): string | undefined {
    let val: string | undefined;
    if (localStorage) {
      const lval = localStorage.getItem(key);
      if (lval == null || lval == 'null') {
        return undefined;
      }
      val = lval;
    }
    return val;
  }

  public clear() {
    this._sessionId = undefined;
    this._instanceId = undefined;
    //this._userId = undefined;
    if (localStorage) {
      localStorage.removeItem(this.SESSION_KEY);
      localStorage.removeItem(this.INSTANCE_KEY);
    }
  }

  public hasInstance(): boolean {
    return this._instanceId !== undefined
  }

  public hasSession(): boolean {
    return this._sessionId !== undefined
  }

  public hasUser(): boolean {
    return this._userId !== undefined
  }

  /**
   * Saves the given sessionId if it is new or changed, returns true of updated.
   * @param sessionId
   */
  public updateSession(sessionUid: string): boolean {
    let needToSave = false;
    if (sessionUid !== undefined) {
      const sessionId = sessionUid.toString();
      if (this._sessionId) {
        if (sessionId !== this._sessionId) {
          needToSave = true;
        }
      } else {
        needToSave = true;
      }
      if (needToSave) {
        this._sessionId = sessionId;
        if (localStorage) {
          localStorage.setItem(this.SESSION_KEY, this._sessionId);
        }
      }
    }
    return needToSave;
  }

  /**
   * Saves the given instanceId if it is new or changed, returns true if updated.
   * @param instanceId
   */
  public updateInstance(instanceUid: string): boolean {
    let needToSave = false;
    if (instanceUid !== undefined) {
      const instanceId = instanceUid.toString();
      if (this._instanceId) {
        if (instanceId !== this._instanceId) {
          needToSave = true;
        }
      } else {
        needToSave = true;
      }
      if (needToSave) {
        this._instanceId = instanceId;
        if (localStorage) {
          localStorage.setItem(this.INSTANCE_KEY, this._instanceId);
        }
      }
    }
    return needToSave;
  }

  public get instanceId(): string {
    if (this._instanceId !== undefined) {
      return this._instanceId;
    }
    throw new Error('No Instance Defined')
  }

  public get sessionId() {
    if (this._sessionId !== undefined) {
      return this._sessionId;
    }
    throw new Error('No Session Defined')
  }

  public userId(newVal?: string): string | undefined {
    if (newVal != null) {
      this._userId = newVal;
      if (localStorage) {
        localStorage.setItem(this.USER_KEY, this._userId);
      }
    }
    return this._userId;
  }

  public stationsUid(newVal?: string): string | undefined {
    if (newVal != null) {
      this._stationUid = newVal;
      if (localStorage) {
        localStorage.setItem(this.STATION_UID, this._stationUid);
      }
    }
    return this._stationUid;
  }

}
