
export enum PSNotificationTypes {
  INFO,
  ERROR,
  LOAD_ERROR,
  PLAYBACK_ERROR,
  NO_CONTENT,
  OUT_OF_SKIPS,
  MIX_LOADING,
  MIX_LOADED,
  MIX_PLAYING
}

export interface PSNotification {
  type: PSNotificationTypes;
  message: string;
}

