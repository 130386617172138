import {DestroyRef, Directive, inject} from "@angular/core";
import {PlaybackService} from "@splixer/player";
import {ActivatedRoute} from "@angular/router";

@Directive()
export class PlayPageController {
  protected playbackService = inject(PlaybackService);
  protected activatedRoute = inject(ActivatedRoute);
  protected destroyRef = inject(DestroyRef);
  protected loadingMessage = "Loading your Show";
  protected mixLoaded = false;


  protected playMix(uid: string) {
    console.debug('Playing mix ', uid)
    this.playbackService.playMix(uid).subscribe(res => {
      this.mixLoaded = true;
    })

  }
}
