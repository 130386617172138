import {DestroyRef, Directive, inject, OnInit} from "@angular/core";
import {PlayerProgram} from "@splixer/player";
import {ActivatedRoute} from "@angular/router";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {V2BasePageController} from "./base-page.controller";

@Directive()
export class Pv2ProgramPageController extends V2BasePageController implements OnInit {
  protected item: PlayerProgram | undefined;
  protected hostName: string | undefined;
  protected activatedRoute = inject(ActivatedRoute);
  protected destroyRef = inject(DestroyRef);

  ngOnInit() {
    this.activatedRoute.data.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(({item}) => {
      this.loadProgram(item);
    })
  }

  protected loadProgram(results: PlayerProgram) {
    if (results) {
      this.item = results as PlayerProgram;
      this.setPageTitle(this.item.name);
      if (this.item._host) {
        this.hostName = 'Hosted by ' + this.item._host.name;
      }
      const bt = this.item.branding.broadcastTimes;
      if (bt) {
        if (this.hostName) {
          this.hostName += ' - ' + bt;
        } else {
          this.hostName = bt;
        }
      }
      this.onProgramLoaded(results);
    }
  }

  protected onProgramLoaded(item: PlayerProgram) {}

}
