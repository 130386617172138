import {nString} from "@hidat/huijs-interfaces";
import {JsonObject, JsonProperty, PropertyConvertingMode} from "json2typescript";
import {ExtendedBrandingInterface} from "@splixer/interfaces";

// WET alert!  Duplicated from admin_common....
@JsonObject('PlayerExtendedBranding')
export class PlayerExtendedBranding implements ExtendedBrandingInterface {
  @JsonProperty('color_scheme', String, PropertyConvertingMode.PASS_NULLABLE)
  colorScheme?: 'dark' | 'light' | undefined | null = undefined;
  @JsonProperty('background_color', String, PropertyConvertingMode.PASS_NULLABLE)
  backgroundColor?: nString = undefined;

  @JsonProperty('background_image_url', String, PropertyConvertingMode.PASS_NULLABLE)
  backgroundImageUrl?: nString = undefined;
  @JsonProperty('banner_image_url', String, PropertyConvertingMode.PASS_NULLABLE)
  bannerImageUrl?: nString = undefined;
  @JsonProperty('avatar_image_url', String, PropertyConvertingMode.PASS_NULLABLE)
  avatarImageUrl?: nString = undefined;
}
