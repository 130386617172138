import {ModuleWithProviders, NgModule, Optional, SkipSelf} from "@angular/core";
import {PlayerApiService} from "./resources/api/player-api.service";
import {PlayerApiConfig} from "./resources/api/player-api-config";
import {CommonModule} from "@angular/common";

@NgModule({
  imports: [CommonModule],
  providers: [PlayerApiService]
})
export class PlayerApiModule {
  constructor(@Optional() @SkipSelf() parentModule?: PlayerApiModule) {
    if (parentModule) {
      throw new Error(
        'PlayerAPIModule is already loaded.  Import it in the AppModule only'
      )
    }
  }

  static forRoot(config: PlayerApiConfig): ModuleWithProviders<PlayerApiModule> {
    return {
      ngModule: PlayerApiModule,
      providers: [
        PlayerApiService,
        {
          provide: PlayerApiConfig,
          useValue: config
        }
      ]
    }
  }
}
