<div class="play-button duration-play-button">
  <button (click)="buttonClickedEvent()" [title]="hint" class="button-link" [ngStyle]="buttonStyles">
    <div class="play-icon">
      <fa-icon [icon]="faPlay"></fa-icon>
    </div>
    <div class="duration">
      @if (duration) {
        {{ duration | secondsToMinutes }}
      }
    </div>
  </button>
</div>
