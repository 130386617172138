import {inject, Injectable} from "@angular/core";
import {map, Observable} from "rxjs";
import {AnyHash} from "@hidat/huijs-interfaces";
import {StationManagerService} from "./station-manager.service";
import {PlayerApiService} from "./api/player-api.service";
import {PlayerMix, PlayerProgram} from "../models";

export type ContentSearchType = 'all' | 'recent' | 'featured' | 'startsWith';

export interface ContentFilter {
  q?: string;     // Full Text Query
  klass?: string;
  category?: string;
  tag?: string;
  maxItems?: number;
}

/**
 * Manages access to programs, shows, and mixes for searching and filtering
 */
@Injectable({providedIn: "root"})
export class ContentSearcherService {
  private station = inject(StationManagerService);
  private api = inject(PlayerApiService);

  /**
   * Fetches programs based on the passed in criteria
   * @param cf
   * @param searchType
   * @param includes
   */
  public fetchPrograms(cf: ContentFilter, searchType = "all", includes?: string): Observable<PlayerProgram []> {
    const q: AnyHash = {
      station_id: this.station.id,
      include: includes ?? 'last_show_mix,mix_count',
      max_items: cf.maxItems ?? 20
    };
    if (includes) {
      q['include'] = includes
    }
    if (cf.q) {
      if (searchType === 'startsWith') {
        q['starts_with'] = cf.q;
      } else {
        q['q'] = cf.q;
      }
    }
    if (cf.klass) {
      q['klass'] = cf.klass;
    }
    if (searchType == 'recent') {
      q['recently_added'] = true
    }

    if (searchType == 'featured') {
      q['featured'] = true
    }
    q['sort']='priority-,name+'

    return this.api.programs.getMany([], true, q) as Observable<PlayerProgram []>;
  }

  /**
   * Fetches the given program
   * @param id
   * @param includes
   */
  public fetchProgram(id: string, includes = 'host,mixes') {
    return this.api.programs.getOne([id], true, {station_id: this.station.id!, include: includes})
      .pipe(map(results => {
        if (results) {
          return results as PlayerProgram;
        }
        return null;
      }))
  }

  /**
   * Fetches mixes based on the passed in criteria
   * @param cf
   * @param searchType
   * @param includes
   */
  public fetchMixes(cf: ContentFilter, searchType = "all", includes?: string): Observable<PlayerMix []> {
    const q: AnyHash = {
      station_id: this.station.id,
      include: includes ?? 'program',
      max_items: cf.maxItems ?? 20
    };
    if (cf.q) {
      if (searchType === 'startsWith') {
        q['starts_with'] = cf.q;
      } else {
        q['q'] = cf.q;
      }
    }
    if (cf.category) {
      q['category'] = cf.category;
    }
    if (searchType == 'recent') {
      q['recently_added'] = true
    }

    if (searchType == 'featured') {
      q['featured'] = true
    }

    q['sm']='priority[desc],name[asc]'


    return this.api.mixes.getMany([], true, q) as Observable<PlayerMix []>;
  }

  /**
   * Fetches the given Mix
   * @param id
   * @param includes
   */
  public fetchMix(id: string, includes="program") {
    return this.api.mixes.getOne([id], true, {station_id: this.station.id!, include: includes})
      .pipe(map(results => {
        console.debug('Mix fetched')
        if (results) {
          return results as PlayerMix;
        }
        return null;
      }))
  }

}
