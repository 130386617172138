<header class="station-header">
  <div class="station-logo-container">
    <a href="https://www.knkx.org" target="_blank">
      <img src="/assets/images/round-logo.png" class="hide-on-mobile" alt="KNKX" title="Launch KNKX Website">
      <img src="/assets/images/knkxwordmarkwhite.png" class="mobile-only" alt="KNKX" title="Launch KNKX Website">
    </a>
  </div>
  <div class="app-name">
    <h1><a class="clean-link" routerLink="/"><span class="hide-on-mobile">KNKX </span>On Demand</a></h1>
  </div>
  <div class="donate-button-container">
    <a href="https://secure.knkx.org/site/Donation2?df_id=1600&mfc_pref=T&1600.donation=form1e" class="donate-button">
      <div class="contribute-link">
        Donate<span class="desktop-only"> Now</span>
      </div>
    </a>
  </div>
</header>
