import {Component, Input} from "@angular/core";
import {Pv2ContentMenuItem} from "../menu-item.interface";

const template = `
  @if (items) {
    <div class="playable-list content-list">
      @for (item of items; track item.id) {
        <div class="list-item-wrapper">
          <pv2-playable-list-item [item]="item"
            [displayPlayButton]="displayPlayButtons" [displayDuration]="displayDurations"></pv2-playable-list-item>
        </div>
      }
    </div>
  }
`

/**
 * A vertical list of playable elements, composed of three columns
 * * A play button
 * * Multiline Description (label, description)
 * * Duration
 */
@Component({
  selector: 'pv2-playable-list',
  template
})
export class PlayableListComponent {
  @Input() items: Pv2ContentMenuItem [] | undefined;
  @Input() displayPlayButtons = true;
  @Input() displayDurations = true;
}
