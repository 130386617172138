import {AnyHash, nNumber, nString, uNumber, uString, Uuid} from "../helpers";
import {AudioMediaFormats, ImageMediaFormats, MediaTypes} from "./media-enums";
import {FieldConfig, FieldDataTypes} from "../resource";

// NOTE: Keep settings as simple JSON types, so we don't have to deserialize them....

/**
 * Image transformation settings
 */
export interface MediaImageSettings {
  encoding: ImageMediaFormats;
  encodingQuality: nNumber;
  minHeight: nNumber;
  minWidth: nNumber;
  maxHeight: nNumber;
  maxWidth: nNumber;
}

export const MediaImageSettingsFieldConfigs = [
  new FieldConfig('encoding').setRequired(true),
  new FieldConfig('quality', FieldDataTypes.INTEGER),
  new FieldConfig('minHeight', FieldDataTypes.INTEGER),
  new FieldConfig('minWidth', FieldDataTypes.INTEGER),
  new FieldConfig('maxHeight', FieldDataTypes.INTEGER),
  new FieldConfig('maxWidth', FieldDataTypes.INTEGER),
]

/**
 * Audio transformation settings, incomplete
 */
export interface MediaAudioSettings {
  encoding: AudioMediaFormats;
  bitRate: nNumber;
  sampleRate: nNumber;
}

/**
 * Transformation pipeline, for when there are multiple steps to be executed.  Incomplete fantasy....
 */
export interface MediaPipelineSettings {
  transforms: MediaPipelineItems [];
}

export interface MediaPipelineItems {
  step: uNumber;
  repositoryId: Uuid | undefined;
  setting: MediaImageSettings | MediaAudioSettings;
}

export interface MediaAssetTypeInterface {
  name?: uString;
  mediaType: MediaTypes | undefined;
  usageCode: uString;
  role: nString;
  repositoryId: Uuid | undefined | null;
  previewRepositoryId: Uuid | undefined ;
  generatePreview: boolean;
  maxSize: uNumber;
  masterSettings: AnyHash;  // MediaImageSettings | MediaAudioSettings | MediaPipelineSettings;
  previewSettings: AnyHash;
}
