import {Router} from "@angular/router";
import {uString} from "@hidat/huijs-interfaces";
import {inject} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {catchError, forkJoin, map, Observable, of} from "rxjs";
import {InstanceManagerService} from "../resources/instance-manager.service";
import {StationManagerService} from "../resources/station-manager.service";

/**
 * Loads the given station and instance.
 * Wrap this with the actual guard function that passes in the current station and instance (normally pulled from
 * the DeviceStore).
 * @param defaultStation
 * @param instanceId
 */
export const loadStationAndInstance = (defaultStation: string, instanceId: uString) => {
  const station = inject(StationManagerService);
  const instanceManager = inject(InstanceManagerService);
  const router = inject(Router);
  const title = inject(Title);

  try {
    if (station.isLoaded) {
      return true;
    }
    console.log('Loading station information...');
    const errorTree = router.parseUrl('station-error');
    const station$ = station.loadStation(defaultStation).pipe(map((playerStation) => {
      if (playerStation) {
        title.setTitle(station.stationCode);
        return true;
      }
      console.error('Unable to load station!')
      return errorTree;
    }), catchError(err => {
      console.error('Load Error: ', err.message);
      return of(errorTree);
    }))

    let instance$: Observable<boolean>
    if (instanceId) {
      instance$ = instanceManager.loadInstance(instanceId);
    } else {
      return station$;
    }

    return forkJoin({
      station: station$,
      instance: instance$
    }).pipe(map((results) => {
      // All we need is the stations - if the instance didn't load, it will have been cleared and will be recreated when the user plays something
      if (results.station) {
        return true;
      }
      console.warn('There was a problem loading the station information');
      return errorTree;
    }), catchError(err => {
      console.error('Loading Error: ', err.message);
      return of(errorTree);
    }))
  } catch (err) {
    alert('Error loading the application.  Please try again later, and if this continues, please let us know at odfeedback@knkx.org.')
    return true;
  }

}
