import {Pipe, PipeTransform} from "@angular/core";
import {DisplayHelperService} from "./display-helper.service";

@Pipe({name: 'secondsToMinutes'})
export class SecondsToMinutesPipe implements PipeTransform {
  constructor(private displayHelper: DisplayHelperService) {}

  transform(value: number | undefined | null, showFractions = false): string | undefined {
    return this.displayHelper.secondsToMinutes(value, showFractions);
  }
}

@Pipe({name: 'yesNo'})
export class YesNoPipe implements PipeTransform {
  constructor(private displayHelper: DisplayHelperService) {}

  transform(value: boolean | undefined | null): string | undefined {
    return this.displayHelper.yesNo(value);
  }
}

@Pipe({name: 'hoursOrMinutes'})
export class HoursOrMinutesPipe implements PipeTransform {
  constructor(private displayHelper: DisplayHelperService) {}

  transform(value: number, displayFractionalMinutes = false): string | undefined {
    return this.displayHelper.hoursOrMinutes(value, displayFractionalMinutes);
  }
}
