import { JsonConvert, OperationMode, ValueCheckingMode } from 'json2typescript';
import { Injectable, Injector } from '@angular/core';
import { PlayerApiConfig } from './player-api-config';
import { InstancesApiInterface } from './instances-api-interface';
import { HttpApiInterface } from '@hidat/ng-api';
import {PlayerMix, PlayerSession, PlayerStation, PlayerProgram, PlayerShow} from "../../models";

@Injectable({ providedIn: 'root' })
export class PlayerApiService {
  private baseUrl: string;
  jsonConvert: JsonConvert;

  public readonly stations: HttpApiInterface<PlayerStation>;
  public readonly programs: HttpApiInterface<PlayerProgram>;
  public readonly shows: HttpApiInterface<PlayerShow>;
  public readonly sessions: HttpApiInterface<PlayerSession>;
  public readonly instances: InstancesApiInterface;
  public readonly mixes: HttpApiInterface<PlayerMix>;

  constructor(config: PlayerApiConfig, injector: Injector) {
    this.baseUrl = config.apiUrl;
    this.jsonConvert = new JsonConvert();
    this.jsonConvert.operationMode = OperationMode.ENABLE;
    this.jsonConvert.ignorePrimitiveChecks = false;
    this.jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

    this.stations = new HttpApiInterface<PlayerStation>(
      injector,
      this.baseUrl,
      'stations',
      this.jsonConvert,
      PlayerStation
    );

    this.programs = new HttpApiInterface<PlayerProgram>(
      injector,
      this.baseUrl,
      'programs',
      this.jsonConvert,
      PlayerProgram
    );

    this.shows = new HttpApiInterface<PlayerShow>(
      injector,
      this.baseUrl,
      'shows',
      this.jsonConvert,
      PlayerShow
    );

    this.mixes = new HttpApiInterface<PlayerMix>(
      injector,
      this.baseUrl,
      'mixes',
      this.jsonConvert,
      PlayerMix
    );

    this.sessions = new HttpApiInterface<PlayerSession>(
      injector,
      this.baseUrl,
      'sessions',
      this.jsonConvert,
      PlayerSession
    );

    this.instances = new InstancesApiInterface(
      injector,
      this.baseUrl,
      this.jsonConvert
    );
  }
}
