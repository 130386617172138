import {DestroyRef, Directive, inject, OnInit} from "@angular/core";
import {ContentFilter, ContentSearcherService, ContentSearchType, PlayerMix} from "@splixer/player";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {V2BasePageController} from "./base-page.controller";
import {Pv2ContentMenuItem} from "../content";
import {HttpParams} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";

/**
 * Base controller for a page the displays a list of mixes.
 * The mixes will be automatically loaded using the passed in filter/search type
 * When the mixes are loaded, onMixesLoaded is called, and by default, an array of MenuItems will be created.
 * You can then used these menu items to populate a playable list, or use the mixes directly
 */
@Directive()
export abstract class V2MixListPageController extends V2BasePageController implements OnInit {
  protected contentSearcher = inject(ContentSearcherService);
  protected route = inject(ActivatedRoute);
  protected router = inject(Router);
  protected location = inject(Location);

  protected destroyRef = inject(DestroyRef);
  protected mixes: PlayerMix [] | undefined;
  protected menuItems: Pv2ContentMenuItem[] = [];

  protected filter: ContentFilter;
  protected searchType: ContentSearchType;
  protected includes: string | undefined;

  /**
   * Implementing class must pass in a filter and a search type (defaults to all).
   * You can also set the 'includes' if you don't want the searcher defaults.
   * @param filter
   * @param searchType
   */
  constructor(filter: ContentFilter, searchType: ContentSearchType = "all") {
    super();
    this.filter = filter;
    this.searchType = searchType;
  }

  ngOnInit() {
    this.fetchMixes(this.filter, this.searchType);
  }

  protected fetchMixes(filter: ContentFilter, searchType: ContentSearchType = 'all') {
    this.contentSearcher.fetchMixes(filter, searchType)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(results => {
        if (results) {
          this.mixes = results;
          this.onMixesLoaded();
        }
      })
  }

  protected setQueryParams(sw: string) {
    const sp = new HttpParams().append('sw', sw);
    const path = this.location.path().split('?')[0];
    const query = sp.toString();
    this.location.replaceState(path, query);
  }

  protected onMixesLoaded() {
    const menuItems: Pv2ContentMenuItem [] = [];
    if (this.mixes) {
      for (const item of this.mixes) {
        if (item.uid) {
          const mi: Pv2ContentMenuItem = {
            id: item.uid,
            contentId: item.uid,
            name: item.name ?? 'No Name!',
            tagLine: item.tagLine,
            duration: item.duration,
            routerLink: this.getMixLink(item)
          }
          menuItems.push(mi);
        }
      }
    }
    this.menuItems = menuItems;
  }

  protected getMixLink(mix: PlayerMix): string | string [] | undefined {
    return undefined;
  }

}
