<div class="timeline-segment">
  @if (displaySegmentInfo) {

  }
  @if (currentTrack) {
    <div class="segment-wrapper">
      <pv2-track-info [content]="currentTrack.content" [startedAt]="currentTrack.startedAt"></pv2-track-info>
    </div>
  }
  @if (timelineSegment && timelineSegment.items && timelineSegment.items.length > 0) {
    @for (segment of timelineSegment.items; track segment.item.uid) {
      <div class="segment-wrapper">
        @if (segment.type === 'SegmentItem' && segment.item.content) {
          <pv2-track-info [content]="segment.item.content" [startedAt]="segment.addedAt"></pv2-track-info>
        }
      </div>
    }
  }
</div>
