import {inject} from "@angular/core";
import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from "@angular/router";
import {of} from "rxjs";
import {PlayerMix, PlayerProgram} from "../models";
import {ContentSearcherService} from "./content-searcher.service";

/*****
 Resolver Functions to be used with the router
*****/

export const programResolver: ResolveFn<PlayerProgram | null> = ( route: ActivatedRouteSnapshot /*state: RouterStateSnapshot*/) => {
  const contentSearcher= inject(ContentSearcherService);
  const id = route.paramMap.get('id');
  if (id) {
    return contentSearcher.fetchProgram(id);
  }
  return of(null);
}

export const mixResolver: ResolveFn<PlayerMix | null> = ( route: ActivatedRouteSnapshot) => {
  const contentSearcher= inject(ContentSearcherService);
  const id = route.paramMap.get('id');
  if (id) {
    return contentSearcher.fetchMix(id, "program,source,host,related_content");
  }
  return of(null);
}

export const mixOnlyResolver: ResolveFn<PlayerMix | null> = ( route: ActivatedRouteSnapshot) => {
  const contentSearcher= inject(ContentSearcherService);
  const id = route.paramMap.get('id');
  if (id) {
    return contentSearcher.fetchMix(id, "");
  }
  return of(null);
}
