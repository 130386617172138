import {JsonObject, JsonProperty} from "json2typescript";
import {DayJSConverter} from "@hidat/ng-api";
import * as dayjs from "dayjs";
import {PlayerUser} from "./player-user.model";

@JsonObject("PlayerInstance")
export class PlayerInstance {
  @JsonProperty('uid', String, true)
  id: string | undefined = undefined;

  // Guid
  @JsonProperty('user_id', String, true)
  userId: string | undefined = undefined;
  @JsonProperty('_user', PlayerUser, true)
  _user: PlayerUser | undefined = undefined;
}
