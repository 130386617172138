import {MediaToPlay} from "../models/scheduler.model";

/**
* The Simple Scheduler
* This scheduler is simply a queue of 'ItemsToPlay', which are media files that have one or more pieces of content
* associated with them.
*/
export class Scheduler {
    public mediaToPlay: MediaToPlay[];
    private _playMicBreaks = true;
    protected currentItemToPlay = 0;

    constructor() {
      this.mediaToPlay = [];
    }

    public playMicBreaks(status: boolean): boolean {
      this._playMicBreaks = status;
      return this._playMicBreaks;
    }

    /**
     * Has Items to Play
     * @returns {boolean} - Returns true if their are tracks to play
     */
    hasItemsToPlay(): boolean {
      let results = false;
      if (this.mediaToPlay.length > this.currentItemToPlay) {
        results = true;
      }
      return results;
    }

    /**
     * Needs Items to Play
     * @returns {boolean} - Returns true if we need to feed the scheduler
     */
    needsItemsToPlay(): boolean {
      return !this.hasItemsToPlay();
    }

    /**
     * Add Server Items To Play
     * @param mediasToPlay
     */
    addServerItemsToPlay(mediasToPlay: MediaToPlay[]) {
      for (const mtp of mediasToPlay) {
        this.mediaToPlay.push(mtp)
      }
    }

    /**
     * Clear Unplayed
     * Clears all the unplayed tracks from the current position to the end of the schedule
     */
    clearUnplayed() {
      if (this.hasItemsToPlay()) {
        this.mediaToPlay.splice(this.currentItemToPlay);
      }
    }

    /**
     * Get Next Item
     * Gets  the next item to play, and then advances the current item.
     * If there is no item available, returns null.
     * It is up to the calling routine to load more items if we run out.
     * @returns {MediaToPlay}
     */
    getNextItem(): MediaToPlay | undefined {
      let chosenItem: MediaToPlay | undefined = undefined;
      let done = false;
      while (this.currentItemToPlay < this.mediaToPlay.length && !done) {
        chosenItem = this.mediaToPlay[this.currentItemToPlay];
        this.currentItemToPlay++;
        if (this._playMicBreaks || chosenItem.mediaType !== 'MicBreak') {
          done = true;
        } else {
          chosenItem = undefined;
        }
      }
      return chosenItem;
    }

    /**
     * Advances the next segment, so you can then call 'getNextItem' to start playing that segment.
     * @returns {{}}
     */
    gotoNextSegment(): boolean {
      let done = false;
      console.log('Skipping segment');
      const theEnd = this.mediaToPlay.length;
      if (this.currentItemToPlay < theEnd) {
        const currentSegmentId = this.mediaToPlay[this.currentItemToPlay].segment?.id
        while (!done && this.currentItemToPlay < theEnd) {
          this.currentItemToPlay++;
          if (this.currentItemToPlay < theEnd && this.mediaToPlay[this.currentItemToPlay].segment?.id != currentSegmentId) {
            done = true;
          }
        }
      }
      return done;
    }
  }
