import {ModuleWithProviders, NgModule, Optional, SkipSelf} from "@angular/core";
import {CommonModule} from "@angular/common";
import {EventTrackerService} from "./services/event-tracker.service";
import {EventTrackerConfig} from "./services/event-tracker-config";

@NgModule({
  imports: [CommonModule],
  providers: [EventTrackerService]
})
export class EventTrackerModule {
  constructor(@Optional() @SkipSelf() parentModule?: EventTrackerModule) {
    if (parentModule) {
      throw new Error(
        'EventTrackerModule is already loaded.  Import it in the AppModule only'
      )
    }
  }

  static forRoot(config: EventTrackerConfig): ModuleWithProviders<EventTrackerModule> {
    return {
      ngModule: EventTrackerModule,
      providers: [
        EventTrackerService,
        {
          provide: EventTrackerConfig,
          useValue: config
        }
      ]
    }
  }
}
