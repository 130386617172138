import {ChangeDetectionStrategy, Component, inject, Input, OnChanges, SimpleChanges} from "@angular/core";
import {
  PlayerMix,
  PlayerProgramBase, PlayerShow,
  PlayerShowBase, StationManagerService,
} from "@splixer/player";
import {nString, uString} from "@hidat/huijs-interfaces";
import {FlatBrandedContent} from "../../../models";

/**
 * A columnar focused rendering of the 'current-mix'.
 * Displays information about the mix, and associated program/show (if the mix has one...)
 * Does not display the playlist!
 */
@Component({
  selector: 'pv2-mix-details',
  templateUrl: 'details.component.html',
  //styleUrls: ['../player-v2-styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MixDetailsComponent implements OnChanges {
  @Input() item: PlayerMix | undefined;
  @Input() flatItem: FlatBrandedContent | undefined;
  @Input() show: PlayerShow | undefined;
  @Input() displayName : 'none' | 'header' | 'inline' = 'header';
  @Input() displayImage = true;
  @Input() displayPlayButton = true;
  @Input() displayDescription = true;

  protected station = inject(StationManagerService);
  protected hasExtendedDetails = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['item']) {
      if (this.flatItem === undefined || !changes['flatItem']) {
        this.buildComponent();
      }
    }
    if (!this.item && !this.flatItem) {
      console.error('Not item or flatItem given for Mix Details')
    }
    if (this.flatItem) {
      this.hasExtendedDetails = !!this.flatItem.blogLink || !!this.flatItem.videoLink || !!this.flatItem.credits;
    }
  }

  protected buildComponent() {
    if (this.item) {
      this.hasExtendedDetails = false;
      this.flatItem = new FlatBrandedContent(this.item);
      this.hasExtendedDetails = !!this.flatItem.blogLink || !!this.flatItem.videoLink || !!this.flatItem.credits;
    }
  }

}
