import {Subject} from "rxjs";
import {
  HuiMediaToPlay,
  HuiPlaybackErrorEvent, HuiPlaybackErrorTypes,
  HuiPlaybackProgressEvent,
  HuiPlaybackStatusEvent, HuiPlaybackStatusTypes,
  HuiPlayerEngine
} from "./player-types";

export abstract class HuiAudioPlayer implements HuiPlayerEngine {
  protected _playing = false;  //todo: make a property
  protected reallyPlaying = false;
  protected _paused = false;
  protected _playbackStartError = false;
  protected mediaToPlay: HuiMediaToPlay | undefined;
  protected queuedToPlay: HuiMediaToPlay | undefined;
  protected mediaDuration = 0;
  protected suspended = false;
  protected currentPlaybackRate = 1;
  protected itemsPlayed = 0;
  protected volume = 1.0;
  protected playerEngineReady = false;
  protected wpFailSafeCount = 0;

  public readonly playbackStatusSource = new Subject<HuiPlaybackStatusEvent>();
  public readonly playbackErrorSource = new Subject<HuiPlaybackErrorEvent>();
  public readonly playbackProgressSource = new Subject<HuiPlaybackProgressEvent>();

  abstract init(config: object): boolean;
  abstract play(itemToPlay: HuiMediaToPlay): void;
  abstract retryPlay(): void;
  abstract stop(): void;
  abstract togglePlayback(): void;
  abstract playbackStatus(): number;
  abstract back(milliseconds: number): number;
  abstract forward(milliseconds: number): number;
  abstract position(): number;
  abstract setPosition(timeAsMilliseconds: number): void;
  abstract setPlaybackRate(rate: number): void;
  abstract pause(): void;
  abstract resume(): void;

  protected _onPlay(): void {
    //console.debug('onplay');
    this._playing = true;
    this.reallyPlaying = false;
    this.suspended = false;
    this._paused = false;
    this.wpFailSafeCount = 0;
    // Are we starting or resuming
    if (this.queuedToPlay) {
      this.mediaToPlay = this.queuedToPlay;
      this.queuedToPlay = undefined;
    }
    if (this.mediaToPlay) {
      this.mediaToPlay.markStarted();
      this.playbackStatusSource.next({
        source: this.mediaToPlay?.source,
        playing: this._playing,
        paused: this._paused,
        reallyPlaying: this.reallyPlaying,
        eventID: HuiPlaybackStatusTypes.STARTED
      });
    }
    this.itemsPlayed++
  }

  protected _onPaused() {
    //console.debug('onpause')
    this._paused = true
    this.wpFailSafeCount = 0
    this.playbackStatusSource.next({
      source: this.mediaToPlay?.source,
      playing: this._playing,
      paused: this._paused,
      reallyPlaying: this.reallyPlaying,
      eventID: HuiPlaybackStatusTypes.PAUSED
    });
  }

  protected _onLoaded(duration: number): void {
    //console.debug('onload:', ' playing: ', this._playing)
    this.mediaDuration = duration;
    this.playbackStatusSource.next({
      source: this.mediaToPlay?.source,
      playing: this._playing,
      paused: this._paused,
      reallyPlaying: this.reallyPlaying,
      eventID: HuiPlaybackStatusTypes.FILE_LOADED
    });
  }

  protected _onResumed(): void {
    //console.debug('onresume');
    this._paused = false;
    this.playbackStatusSource.next({
      source: this.mediaToPlay?.source,
      playing: this._playing,
      paused: this._paused,
      reallyPlaying: this.reallyPlaying,
      eventID: HuiPlaybackStatusTypes.RESUMED
    });
  }

  protected _onStopped() {
    //console.debug('onstop');
    this._playing = false;
    this.reallyPlaying = false;
    this._paused = false;
    if (this.mediaToPlay) {
      this.mediaToPlay.markFinished(false);
    }
    this.playbackStatusSource.next({
      source: this.mediaToPlay?.source,
      playing: this._playing,
      paused: this._paused,
      reallyPlaying: this.reallyPlaying,
      eventID: HuiPlaybackStatusTypes.STOPPED
    });
  }

  protected _onFinished() {
    //console.debug('onfinish');
    this._playing = false;
    this.reallyPlaying = false;
    this._paused = false;
    if (this.mediaToPlay) {
      this.mediaToPlay.markFinished();
    }
    this.playbackStatusSource.next({
      source: this.mediaToPlay?.source,
      playing: this._playing,
      paused: this._paused,
      reallyPlaying: this.reallyPlaying,
      eventID: HuiPlaybackStatusTypes.FINISHED
    });
  }

  /**
   * Playback Progress
   * Handles the media progress event
   * @param duration  Media duration, in seconds
   * @param position  Current position, in seconds
   * @private
   */
  protected _onPlaybackProgress(duration: number, position: number) {
    this.suspended = false;
    if (!this.reallyPlaying) {
      this.reallyPlaying = true;  // If we have activity, then we are really playing....
      this._playbackStartError = false;
      this.playbackStatusSource.next({
        source: this.mediaToPlay?.source,
        playing: this._playing,
        paused: this._paused,
        reallyPlaying: this.reallyPlaying,
        eventID: HuiPlaybackStatusTypes.REALLY_STARTED
      });
    }

    const percDone = position / duration;
    this.playbackProgressSource.next({
      source: this.mediaToPlay?.source,
      position: position,
      duration: duration,
      percDone: percDone
    });

  }

  protected loadingSound() {
    this._playing = false;
    this.reallyPlaying = false;
    this._paused = false;
    this.playbackStatusSource.next({
      source: this.mediaToPlay?.source,
      playing: this._playing,
      paused: this._paused,
      reallyPlaying: this.reallyPlaying,
      eventID: HuiPlaybackStatusTypes.LOADING
    });
  }

  protected handleError(type: number, msg: string) {
    //console.warn('onerror: ', type, ' - ', msg)
    this.wpFailSafeCount = 0
    this._playing = false;
    this.reallyPlaying = false;
    this._paused = false;
    if (type === HuiPlaybackErrorTypes.PLAYBACK) {
      this._playbackStartError = true;
    }
    this.playbackStatusSource.next({
      source: this.mediaToPlay?.source,
      playing: this._playing,
      paused: this._paused,
      reallyPlaying: this.reallyPlaying,
      errorType: type,
      eventID: HuiPlaybackStatusTypes.ERROR
    });
    this.playbackErrorSource.next({
      errorType: type,
      message: msg,
      source: this.mediaToPlay?.source,
    });
  }

  /**
   * Get Playback Status
   * Programmatic way to get current playback status.
   */
  public getPlaybackStatus(): HuiPlaybackStatusEvent {
    return {
      eventID: HuiPlaybackStatusTypes.FILE_LOADED,
      source: this.mediaToPlay?.source,
      playing: this._playing,
      paused: this._paused,
      reallyPlaying: this.reallyPlaying,
    };
  }

  public get playing() {
    return this._playing;
  }

  public get paused() {
    return this._paused;
  }

  public get playbackStartError() {
    return this._playbackStartError;
  }

}


