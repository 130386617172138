import {Injectable} from "@angular/core";
import dayjs from "dayjs";
import {
  ContentItem,
  ContentToPlay,
  PlayerHost,
  MediaToPlay,
  PlayerMix,
  SchedulingSegment,
  PlayerShow,
  PlayerProgram
} from "../models";
import {CreateUuid} from "@hidat/huijs-interfaces";

type TimelineItemTypes = TimelineSegmentItem;
type TimelineTypes = "SegmentItem" | "Underwriting" | "RelatedContent";


/**
 * A container for a timeline item.
 */
export class TimelineContainer {
  readonly addedAt: dayjs.Dayjs;
  public readonly type: TimelineTypes;
  public readonly item: TimelineItemTypes;
  public readonly expiresAt: dayjs.Dayjs;
  status = 100;

  constructor(type: TimelineTypes, item: TimelineItemTypes, expiresAt: dayjs.Dayjs) {
    this.addedAt = dayjs();
    this.type = type;
    this.item = item;
    this.expiresAt = expiresAt;
  }

  updateStatus(timeToCheck: dayjs.Dayjs = dayjs()): number {
    if (this.status >= 0 && this.expiresAt) {
      if (this.expiresAt.isBefore(timeToCheck)) {
        this.status = -100;
      }
    }
    return this.status;
  }
}

/**
 * A TimelineItem that represents a piece of content that has been played.
 * Includes information about the containing segment.
 * If 'topOfSegment' is set, then this is the first item in the segment.
 * If 'topOfMix' is set, then this is the first item in the mix.
 */
export interface TimelineSegmentItem {
  uid: string;   // Used for generating/tracking in the DOM
  content?: ContentItem;
  playbackLogId?: string;
  startedAt: dayjs.Dayjs;
  mix?: PlayerMix;
  program?: PlayerProgram;
  show?: PlayerShow;
  host?: PlayerHost;
  segment?: SchedulingSegment;
  segmentId?: number;
  topOfSegment: boolean;
  topOfMix: boolean;
  segmentIndex: number;
}

/**
 * A normalized view of all the content items played in a segment
 */
export interface TimelineSegment {
  segmentId: number | undefined;
  currentItem: TimelineSegmentItem | null;   // Current playing item, before it gets pushed into items once completed.
  items: TimelineContainer [];
}

/**
 * Timeline Service
 * This is a rethinking of the standard 'playlist', mutated into more of a social-media style timeline.
 * You can add multiple types of content to the timeline, which is then used as the source to draw the timeline on page.
 * The session will be responsible for adding the content, unlike the playlist, it does not connect directly to the playback engine.
 * Keeps at most 'maxContentSeconds' worth of data around.
 * The timeline keeps multiple publicly available lists of items, these are arrays to make change tracking simple.
 * nowPlaying: The currently playing item
 * nowPlayingSegmentItems: All the items related to the currently playing segment, except 'nowPlaying'
 * timelineItems: All the previously playing items, excluding 'nowPlaying'
 * previousSegmentItems: All the previously played items, except the currently playing segment items
 */
@Injectable({providedIn: 'root'})
export class TimelineService {
  // Now playing item and segment
  public nowPlaying: TimelineSegmentItem | undefined;
  public nowPlayingSegment: TimelineSegment | undefined;
  public nowPlayingMix: PlayerMix | undefined;

  // Everything after now playing
  public timelineItems: TimelineContainer[] = [];
  public timelineSegments: TimelineSegment [] = [];
  public timelineMixes: PlayerMix [] = [];

  private maxContentSeconds = 3600 * 3;  // Display up to 3 hours of playlist info


  /**
   * Adds the currently playing piece of content to the timeline.
   * This will replace the currently playing item, which will be pushed down into the timeline.
   * @param contentPlaying  Specific piece of content/track playing
   * @param itemPlaying  The media item playing
   * @param mix  The current mix
   */
  public addCurrentlyPlayingItem(contentPlaying: ContentToPlay, itemPlaying: MediaToPlay | undefined, mix: PlayerMix | undefined): void {
    console.debug('Adding currently playing to Timeline: ', contentPlaying.id)
    const startedAt = contentPlaying.startedAt ?? dayjs();
    const segmentItem: TimelineSegmentItem = {
      uid: contentPlaying.id ?? CreateUuid(),
      content: contentPlaying.content,
      playbackLogId: contentPlaying.id,
      startedAt: startedAt,
      mix: mix,
      topOfSegment: false,
      topOfMix: false,
      segmentIndex: 0,    // Index of the track within the current segment.  Most recent played will be 0.
    }

    if (itemPlaying) {
      segmentItem.show = itemPlaying.show;
      segmentItem.host = itemPlaying.host;
      segmentItem.segment = itemPlaying.segment;
      segmentItem.segmentId = itemPlaying.segment?.id;
    }

    // If we currently have a now playing, then push it down into the timeline, and check if we are moving to a new segment or mix
    if (this.nowPlaying) {
      const newSegmentId = segmentItem.segment?.id
      if (this.nowPlaying.segment?.id != newSegmentId) {
        segmentItem.topOfSegment = true;
      }
      const expiresAt = startedAt.add(this.maxContentSeconds, 's');
      this.addToTimeline('SegmentItem', this.nowPlaying, expiresAt, newSegmentId);
      this.nowPlaying = segmentItem;
      if (mix && mix.uid && this.nowPlayingMix && this.nowPlayingMix.uid != mix.uid) {
        this.timelineMixes = [this.nowPlayingMix, ...this.timelineMixes];
        this.nowPlayingMix = mix;
      }
    } else {
      // First play!
      segmentItem.topOfSegment = true;
      segmentItem.topOfMix = true;
      this.nowPlaying = segmentItem;
      this.nowPlayingSegment = {
        segmentId: this.nowPlaying.segmentId,
        currentItem: this.nowPlaying,
        items: []
      };
      if (mix) {
        this.nowPlayingMix = mix;
      }
    }
  }

  /**
   * Force Now Playing to Past
   * Used to force the now playing into previous played.
   * This is for when we run out of content to play.
   */
  public forceNowPlayingToPast() {
    if (this.nowPlaying) {
      this.nowPlaying.topOfSegment = true;
      const expiresAt = this.nowPlaying.startedAt.add(this.maxContentSeconds, 's');
      this.addToTimeline('SegmentItem', this.nowPlaying, expiresAt, undefined);
    }
    this.nowPlaying = undefined;
  }

  /**
   * Adds the given item to the timeline.
   * @param type  Type of segment (do we really need this?)
   * @param item  Item to add
   * @param npSegmentId  Segment id of the NEW nowPlaying item, used to figure out if we need to update lists/indexes
   * @param expiresAt  When the item should expire
   */
  public addToTimeline(type: TimelineTypes, item: TimelineItemTypes, expiresAt: dayjs.Dayjs, npSegmentId?: number | undefined): void {
    const newTimelineItems: TimelineContainer[] = [];
    let newTimelineSegments: TimelineSegment [] = [];

    // Add the new items at the top of the list(s)
    const timelineItem = new TimelineContainer(type, item, expiresAt);
    const timeToCheck: dayjs.Dayjs = dayjs();
    let currentSegmentId: number | undefined;
    let currentSegment: TimelineSegment;
    let currentIndex = 0;

    newTimelineItems.push(timelineItem);
    if (type === "SegmentItem") {
      const segmentId = item.segmentId;
      currentSegmentId = segmentId ?? npSegmentId;
    }

    currentSegment = {
      segmentId: currentSegmentId,
      currentItem: item,
      items: [timelineItem]
    }

    // Go through current timeline items and expire any that are old
    for (const ti of this.timelineItems) {
      if (ti.type == 'SegmentItem' && ti.item.segment) {
        const s = ti.item.segment;
        if (s.id != currentSegmentId) {
          newTimelineSegments.push(currentSegment)
          currentSegmentId = s.id;
          currentIndex = 0;
          currentSegment = {
            segmentId: currentSegmentId,
            currentItem: null,
            items: []
          }
        } else {
          currentIndex++;
        }
        currentSegment.items.push(ti);

        if (ti.expiresAt.isAfter(timeToCheck)) {
          ti.item.segmentIndex = currentIndex;
          newTimelineItems.push(ti);
        }
      }
    }

    newTimelineSegments.push(currentSegment);

    this.timelineItems = newTimelineItems
    this.nowPlayingSegment = undefined;
    if (newTimelineSegments.length > 0) {
      if (npSegmentId && newTimelineSegments[0].segmentId === npSegmentId) {
        this.nowPlayingSegment = newTimelineSegments[0];
        newTimelineSegments = newTimelineSegments.slice(1)
      }
    }
    this.timelineSegments = newTimelineSegments;
  }

}
