/**
 * Field Data Types
 * Valid datatypes for a field config
 * The primary purpose of these is to aid in serialization.
 */
import * as Inflector from "inflected";

export enum FieldDataTypes {
  STRING = 'string',        // String
  BOOLEAN = 'boolean',      // Boolean
  NUMBER = 'number',        // Regular number
  INTEGER = 'int',          // Integer number (stored as Number)
  UID = 'uid',              // GUID
  UNKNOWN = 'unknown',      // We don't know what it is, so don't do any conversion.... (should we change name to object???)
  HASH = 'hash',            // Hash, for forms, this generates a FormGroup instead of FormControl
  IGNORED = 'ignored',       // Don't include in forms

  // Many types of dates, the main reason for this enum!
  DATE = 'date',            // js-joda LocalDate, no time
  DATEZONED = 'datezoned',  // We are just interested in the date, but need to translate it from local on the FE to UTC on back end (i.e., backend holds a DATETIME)
  DATETIME = 'datetime',    // Date and Time - js-joda ZoneDateTime
  JSDATE = "jsdate",        // Native Javascript Date, primarily for conversion
  DAYJS = 'dayjs',          // DayJS, for apps that don't need all the weight of js-joda
}

/**
 * Returns true if the given dataType is one of the many variations of a date field
 * @param dataType
 */
export function isFieldDateType(dataType: FieldDataTypes): boolean {
  return (dataType.startsWith('da') || dataType === FieldDataTypes.JSDATE);
}

export interface FieldEditConfig {
  canEdit: boolean;
  required: boolean;
  lookupCodes?: string[];
}

export interface FieldApiConfig {
  columnName?: string;
  columnType?: string;
  canUpdate: boolean;
  canCreate: boolean;
  hashViews?: string [];
}

/**
 * Field Config
 * Generic field config.
 * Used for object serialization and form construction.
 * By default, this class is mutable, however, is you call the 'freeze' method, the object will be frozen and any
 * mutations will generate a new copy of the config. (This is good for 'base' configurations that can then be copied).
 */
export class FieldConfig {

  public readonly name: string;     // Name of field
  public readonly dataType: FieldDataTypes;   // Target datatype of the field
  public readonly vocabCode: string;
  public editConfig: FieldEditConfig;
  public apiConfig: FieldApiConfig;

  constructor(name: string,
              dataType: FieldDataTypes = FieldDataTypes.STRING,
              canCreateOrApiConfig?: boolean | Partial<FieldApiConfig>,
              editConfigOrVocab?: Partial<FieldEditConfig> | string,
              vocabCode?: string) {

    this.name = name;
    const normalizedName = this.normalizeName(name);
    this.dataType = dataType;
    this.vocabCode = this.assignDefaultVocabCode(normalizedName);

    // defaults
    this.apiConfig = {
      canCreate: true,
      canUpdate: true,
    };

    if (canCreateOrApiConfig !== undefined) {
      if (typeof canCreateOrApiConfig === "boolean") {
        this.apiConfig.canCreate = canCreateOrApiConfig;
        this.apiConfig.canUpdate = canCreateOrApiConfig;
      } else {
        this.buildApiConfig(canCreateOrApiConfig);
      }
    }

    this.editConfig = {
      canEdit: this.apiConfig.canUpdate,
      required: false
    }
    if (editConfigOrVocab) {
      if (typeof editConfigOrVocab === "string") {
        this.vocabCode = editConfigOrVocab;
      } else {
        this.buildEditConfig(editConfigOrVocab);
      }
      if (vocabCode) {
        this.vocabCode = vocabCode;
      }
    }
  }

  freeze(): FieldConfig {
    Object.freeze(this);
    return this;
  }

  setCreatable(v: boolean): FieldConfig {
    if (Object.isFrozen(this)) {
      const nfC = this.clone();
      return nfC.setCreatable(v);
    } else {
      this.apiConfig.canCreate = v;
    }
    return this;
  }

  setUpdatable(v: boolean): FieldConfig {
    if (Object.isFrozen(this)) {
      const nfC = this.clone();
      return nfC.setUpdatable(v);
    } else {
      this.apiConfig.canUpdate = v;
    }
    return this;
  }

  addToView(v: string): FieldConfig {
    if (Object.isFrozen(this)) {
      const nfC = this.clone();
      return nfC.addToView(v);
    } else {
      if (!this.apiConfig.hashViews) {
        this.apiConfig.hashViews = [v];
      } else {
        this.apiConfig.hashViews.push(v);
      }
    }
    return this;
  }

  setRequired(v: boolean): FieldConfig {
    if (Object.isFrozen(this)) {
      const nfC = this.clone();
      return nfC.setRequired(v);
    } else {
      this.editConfig.required = v;
    }
    return this;
  }

  setEditable(v: boolean): FieldConfig {
    if (Object.isFrozen(this)) {
      const nfC = this.clone();
      return nfC.setEditable(v);
    } else {
      this.editConfig.canEdit = v;
    }
    return this;
  }


  /* Can't do because vocab is readonly....
  setVocabCode(v: string): FieldConfig {
    if (Object.isFrozen(this))  {
      const nfC = this.clone();
      return nfC.setVocabCode(v);
    } else {
      this.vocabCode = v;
    }
    return this;
  }*/

  public get canCreate(): boolean {
    return this.apiConfig.canCreate;
  }

  public get canUpdate(): boolean {
    return this.apiConfig.canUpdate;
  }

  public inView(v: string): boolean {
    if (this.apiConfig.hashViews) {
      return this.apiConfig.hashViews.includes(v);
    }
    return false;
  }

  public get canEdit(): boolean {
    return this.editConfig.canEdit;
  }

  public get required(): boolean {
    return this.editConfig.required;
  }


  /**
   * Clone
   * Clones the field config, so you can change any of the updatable fields.
   */
  clone(): FieldConfig {
    return new FieldConfig(this.name, this.dataType, this.apiConfig, this.editConfig, this.vocabCode);
  }

  /**
   * Normalizes the name by stripping off common suffixes and prefixes
   * Currently just strips off 'Id' for foreign keys.
   * @param name
   * @private
   */
  private normalizeName(name: string): string {
    let n = name;
    if (name.endsWith('Id')) {
      n = name.substring(0, name.length - 2);
    }
    return n;
  }

  private assignDefaultVocabCode(normalizedName: string) {
    return Inflector.underscore(normalizedName).toUpperCase();
  }

  private assignDefaultLabel(normalizedName: string) {
    return Inflector.titleize(normalizedName);
  }

  /**
   * Build API Config
   * Builds the API Config from the partial.
   * Assigns sane defaults for missing configs, or the config itself is missing.
   * BE SURE TO UPDATE THIS IF YOU CHANGE API CONFIG!
   * @param c
   * @private
   */
  private buildApiConfig(c: Partial<FieldApiConfig>): void {
    if (c.canCreate !== undefined) {
      this.apiConfig.canCreate = c.canCreate;
    }
    if (c.canUpdate !== undefined) {
      this.apiConfig.canUpdate = c.canUpdate;
    } else {
      this.apiConfig.canUpdate = this.apiConfig.canCreate;
    }
    if (c.hashViews !== undefined) {
      this.apiConfig.hashViews = [];
      for(const v of c.hashViews) {
        this.apiConfig.hashViews.push(v);
      }
    }
    this.apiConfig.columnName = c.columnName;
    this.apiConfig.columnType = c.columnType;
  }

  /**
   * Build Edit Config
   * Builds the Edit Config from the partial.
   * Assigns sane defaults for missing configs, or the config itself is missing.
   * @param c
   * @private
   */
  private buildEditConfig(c: Partial<FieldEditConfig>): void {
    if (c.canEdit !== undefined) {
      this.editConfig.canEdit = c.canEdit;
    }
    if (c.required !== undefined) {
      this.editConfig.required = c.required;
    }
    this.editConfig.lookupCodes = c.lookupCodes;
  }


}


/**
 * Resource Field Config Factory
 * @param name  Name of field
 * @param canCreate  Can field used in creating the reource
 * @param canUpdate  Can the field be updated
 * @param required   Is the field required
 * @param dataType
 * @param vocabCode
 */
export function rfc(name: string, canCreate: boolean, canUpdate?: boolean, required = false, dataType: FieldDataTypes = FieldDataTypes.STRING, vocabCode?: string): FieldConfig {
  return new FieldConfig(name, dataType, {canCreate, canUpdate}, {canEdit: canUpdate, required}, vocabCode);
}

/**
 * Field Config Dictionary
 */
export type FieldConfigDictionary = { [key: string]: FieldConfig };
