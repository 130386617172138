@if (item) {
  <div class="content-list-item mix-list-item" [ngStyle]="item.style" [ngClass]="item.styleClass">
    @if (displayPlayButton) {
      <div class="button-column">
        <pv2-round-play-button (buttonClicked)="playItem()" [hint]="item.title ?? 'Play Now'"
                         [backgroundImage]="item.backgroundImage"></pv2-round-play-button>
      </div>
    }
    <div class="info-column">
      @if (item.routerLink) {
        <a [routerLink]="item.routerLink">
          <ng-container *ngTemplateOutlet="info"></ng-container>
        </a>
      } @else {
        <ng-container *ngTemplateOutlet="info"></ng-container>
      }
    </div>
    @if (displayDuration && item.duration) {
      <div class="count-column hide-on-mobile">{{ item.duration | hoursOrMinutes }}</div>
    }

    <ng-template #info>
      <div class="info-container">
        <div class="content-name">{{ item.name }}</div>
        <div class="content-tag-line">{{ item.tagLine }}</div>
        <div class="content-host-name">{{ item.thirdLine }}</div>
      </div>
    </ng-template>
  </div>
}
