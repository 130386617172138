import {JsonObject, JsonProperty, PropertyConvertingMode} from 'json2typescript';
import {DayJSConverter} from "@hidat/ng-api";
import * as dayjs from "dayjs";
import {AnyHash, nString, uNumber} from '@hidat/huijs-interfaces';
import {PlayerMixInterface} from "@splixer/interfaces";
import {DayJsUtils} from "@hidat/huijs-dates";
import {PlayerProgramBase} from "./program-base.model";
import {PlayerShowBase} from "./show-base.model";
import {PlayerHost} from "./host.model";
import {PlayerExtendedBranding} from "./extended-branding.model";

/**
 * This is a subset/denormalization of the content path, as used in the player.
 */
@JsonObject('PlayerMix')
export class PlayerMix implements PlayerMixInterface {
  @JsonProperty('id', Number, true)
  id = -1;
  @JsonProperty('uid', String, true)
  uid: string | undefined = undefined;

  @JsonProperty('name', String, true)
  name: string | undefined = undefined;
  @JsonProperty('description', String, true)
  description: string | undefined = undefined;
  @JsonProperty('tag_line', String, true)
  tagLine: string | undefined = undefined;
  @JsonProperty('image_url', String, true)
  imageUrl: string | undefined = undefined;
  @JsonProperty('short_code', String, true)
  shortCode: string | undefined = undefined;
  @JsonProperty('branding', Object, true)
  branding: PlayerExtendedBranding = {};

  @JsonProperty('category_code', String, true)
  categoryCode: string | undefined = undefined;
  @JsonProperty('media_type', String, true)
  mediaType: string | undefined = undefined;
  @JsonProperty('featured', Boolean, true)
  featured = false;
  @JsonProperty('has_playlist', Boolean, true)
  hasPlaylist = true;
  @JsonProperty('display_show', Boolean, true)
  displayShow = true;
  @JsonProperty('display_host', Boolean, true)
  displayHost = true;
  @JsonProperty('priority', Number, PropertyConvertingMode.IGNORE_NULLABLE)
  priority: uNumber = undefined;
  @JsonProperty('url_stub', String, PropertyConvertingMode.PASS_NULLABLE)
  urlStub: nString;

  @JsonProperty('program_id', Number, true)
  programId: number | undefined = undefined;
  @JsonProperty('source_type', String, true)
  sourceType: string | undefined = undefined;
  @JsonProperty('source_uid', String, true)
  sourceUid: string | undefined = undefined;
  @JsonProperty('is_steerable', Boolean, true)
  isSteerable = true;
  @JsonProperty('is_selectable', Boolean, true)
  isSelectable = true;

  @JsonProperty('filter_config', Object, true)
  filterConfig: AnyHash = {};
  @JsonProperty('filter_klass', String, true)
  filterKlass: string | undefined = undefined;
  @JsonProperty('valid_segments', Number, true)
  validSegments = 0;
  @JsonProperty('duration', Number, true)
  duration = 0;

  @JsonProperty('mic_break_mode', Number, true)
  micBreakMode: number | undefined = undefined;
  @JsonProperty('min_duration_hours', Number, true)
  minDurationHours: number | undefined = undefined;
  @JsonProperty('playback_type', String, true)
  playbackType: string | undefined = undefined;
  @JsonProperty('scheduling_klass', String, true)
  schedulingKlass: string | undefined = undefined;

  @JsonProperty('station_id', Number, true)
  stationId: number | undefined = undefined;
  @JsonProperty('status', Number, true)
  status = 100;

  @JsonProperty('created_at', DayJSConverter, true)
  createdAt: dayjs.Dayjs | undefined = undefined;
  @JsonProperty('updated_at', DayJSConverter, true)
  updatedAt: dayjs.Dayjs | undefined = undefined;

  @JsonProperty('_show', PlayerShowBase, true)
  _show: PlayerShowBase | undefined = undefined;
  @JsonProperty('_host', PlayerHost, true)
  _host: PlayerHost | undefined = undefined;
  @JsonProperty('_source', Object, true)
  _source: AnyHash = {};
  @JsonProperty('_program', PlayerProgramBase, true)
  _program: PlayerProgramBase | undefined = undefined;
  @JsonProperty('_related_content', [PlayerMix], true)
  _relatedContent: PlayerMix[] | undefined = undefined;
}

/********** Interfaces to send data to API ****************************************************************************/
//@TODO  This needs a bunch of cleaning up once I understand it again....
export class SessionFilter {
  //? hosts: any;
  //? programs: any;
  startTime: number | undefined; // 24 hour clock
  endTime: number | undefined;
  hostIds: string[] | undefined;
  programIds: string[] | undefined;
  dow: number[] | undefined;
  startDate: dayjs.Dayjs | undefined;
  endDate: dayjs.Dayjs | undefined;

  constructor(hash?: any) {
    if (hash) {
      if (hash.dow) {
        this.dow = hash.dow;
      }
      if (hash.hostIds) {
        this.hostIds = hash.hostIds;
      } else if (hash.host_id) {
        this.hostIds = hash.host_id;
      }
      if (hash.programIds) {
        this.programIds = hash.programIds;
      } else if (hash.program_id) {
        this.programIds = hash.program_id;
      }
      if (hash.startTime) {
        this.startTime = hash.startTime;
      } else if (hash.start_time) {
        this.startTime = hash.start_time;
      }
      if (hash.startDate) {
        this.startDate = hash.startDate;
      } else if (hash.start_date) {
        this.startDate = DayJsUtils.toDayjs(hash.start_date);
      }
      if (hash.endTime) {
        this.endTime = hash.endTime;
      } else if (hash.end_time) {
        this.endTime = hash.end_time;
      }
      if (hash.endDate) {
        this.endDate = hash.endDate;
      } else if (hash.end_date) {
        this.endDate = DayJsUtils.toDayjs(hash.end_date);
      }
    }
  }
}

export class UserSettings {
  public includeMicBreaks: boolean;

  public constructor(apiHash?: AnyHash) {
    if (apiHash) {
      this.includeMicBreaks = apiHash['include_mic_breaks'];
    } else {
      this.includeMicBreaks = true;
    }
  }
}

/**
 * Mix Configuration
 * Includes the current set of filters, as well as the mic break setting and anything else we can dream up.
 */
export class MixConfig {
  public userFilters: SessionFilter;
  public userSettings: UserSettings;

  public constructor(apiHash?: AnyHash) {
    if (apiHash) {
      this.userFilters = new SessionFilter(apiHash['user_filters']);
      this.userSettings = new UserSettings(apiHash['user_settings']);
    } else {
      this.userFilters = new SessionFilter();
      this.userSettings = new UserSettings();
    }
  }
}
