@import "pv2-variables";

$grid-gap: .6rem;
$button-height-sm: 8.55rem;
$button-width-sm: 7.3125rem;
$button-height-lg: 9.5rem;
$button-width-lg: 8.125rem;

.button-grid {
  width: 100%;
  padding-left: $grid-gap;
  padding-right: $grid-gap;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  gap: $grid-gap;

  @media screen and (max-width: $tabletUpBreakpoint - 1) {
    &.scroll-on-mobile {
      width: 100vw;
      flex-wrap: nowrap;
      overflow-x: auto;
      justify-content: start;
    }
  }

  @media screen and (min-width: $tabletUpBreakpoint) {
    flex-wrap: wrap;
    overflow-x: visible;
  }


  .button-grid-item {
    width: $button-width-sm;

    .bottom-title, .top-title {
      font-size: .95rem;
      font-weight: bold;
      color: var(--brand-primary-text-color);
      margin: .25rem 0 .35rem;
    }

    .bottom-desc {
      margin: .35rem 0 .25rem;
      text-align: center;
      font-size: .9rem;
      line-height: 1rem;
      font-style: italic;
    }

    .button-grid-link {
      margin-top: .25rem;
      $button-border-radius: .5rem;
      text-decoration: none;
      width: $button-width-sm;
      height: $button-height-sm;
      border-radius: $button-border-radius;
      filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.06)) drop-shadow(0px 1px 3px rgba(16, 24, 40, 0.10));
      background-color: var(--knkx-blue);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      flex-direction: column;
      justify-content: end;

      &:hover {
        cursor: pointer;
      }

      div.top-link {
        height: 100%;
      }

      a {
        text-decoration: none;
      }

      a.button-name {
        margin-top: auto;
        min-height: 2.75em;
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(rgba(0, 0, 0, .0), rgba(0, 0, 0, .50));
        color: var(--brand-inverse-text-color);
        border-radius: 0 0 $button-border-radius $button-border-radius;

        .name-text {
          font-size: 0.9375em;
          line-height: 1.1em;
          font-weight: bold;
          margin: 0 .1rem;
        }

        &:hover {
          background-color: rgba(0, 0, 0, .5);
        }
      }

      a.play-button {
        color: rgba(255, 255, 255, .75);
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: $button-border-radius $button-border-radius 0 0;

        .play-icon {
          font-size: 3.5rem;
        }

        &:hover {
          background-color: rgba(0, 0, 0, .5);
        }
      }

      /*
      a.play-button:hover, a.button-name:hover {
        background-color: rgba(255, 255, 255, .75);
        color: var(--brand-primary-text-color, $gray-800);
        text-decoration: underline;
      }*/

      @media screen and (min-width: $tabletUpBreakpoint) {
        width: $button-width-lg;
        height: $button-height-lg;
      }


    }

    @media screen and (min-width: $tabletUpBreakpoint) {
      width: $button-width-lg;
    }

  }

  &.large-on-mobile {
    .button-grid-item {
      width: $button-width-lg;

      .button-grid-link {
        width: $button-width-lg;
        height: $button-height-lg;
      }
    }
  }
}
