import { ApiEnvelope } from './api-envelope.interface';
import { ResultCountsInterface } from '../result-counts.interface';
import { SlicingInterface } from '../slicing-interface';
import { ResultStatus } from '../results-status';
import {AnyHash} from "@hidat/huijs-interfaces";

/**
 * API DataSet
 * A wrapper around the results of an API call to the backend.  This class is used on the consumer/frontend side.
 * This is modeled around a static list of items.  If you need an observable, use the much more capable ResourceManager.
 */
export class DataSet<T> {
  public errorEncountered: boolean;
  public loaded: boolean;
  public version?: string;
  public errorCode?: string;
  public errorMsg?: string;
  public extErrorMsg?: string;
  public counts?: ResultCountsInterface;
  public includes?: string;
  slicing?: SlicingInterface;

  public totalRows: number; // Total rows available on the backend, could be larger than rows loaded if we have slicing
  public rowsLoaded: number; // Total rows for this call/slice
  public items: T[] = [];

  constructor(apiEnvelope?: ApiEnvelope, private deserializer?: any) {
    this.totalRows = 0;
    this.rowsLoaded = 0;
    this.errorEncountered = false;
    this.loaded = false;
    this.items = []; // Always want items to be an array, even on failure, so we can eliminate lots of error checking downstream.
    if (apiEnvelope) {
      this.loadResults(apiEnvelope);
    }
  }

  /**
   * Errored
   * Marks the DataSet as having errored out.
   * Useful when we need to return a dataset no matter what...
   * @param errorMessage
   * @param errorCode
   */
  errored(errorMessage?: string, errorCode?: string) {
    this.errorEncountered = true;
    this.errorCode = errorCode;
    this.errorMsg = errorMessage;
    if (!this.counts) {
      this.counts = {rows: 0, errors: 1}
    }
    this.totalRows = 0;
    this.rowsLoaded = 0;
    this.loaded = true;
  }

  /**
   * Load Results
   * Loads the results from a API call.
   * Returns the total number of rows loaded.
   * @param apiEnvelope
   */
  loadResults(apiEnvelope: ApiEnvelope): number {
    this.version = apiEnvelope.version;
    this.loaded = true;
    this.counts = apiEnvelope.counts; // Always try to load counts, even if there has been an error.

    if (apiEnvelope.status === ResultStatus.ERROR) {
      this.errorEncountered = true;
      this.errorCode = apiEnvelope.errorCode;
      this.errorMsg = apiEnvelope.errorMsg;
      this.extErrorMsg = apiEnvelope.extErrorMsg;
      console.error('API Error: ', this.errorMsg)
      return 0;
    }

    if (apiEnvelope.slicing) {
      this.slicing = apiEnvelope.slicing;
    } else if (apiEnvelope.per_page !== undefined) {
      this.slicing = {
        sliceSize: apiEnvelope.per_page,
        totalRows: apiEnvelope.total_rows,
        page: apiEnvelope.page ?? 1
      }
    }

    let data = apiEnvelope.data;
    if (!data) {
      data = apiEnvelope.items;
    }

    if (data) {
      // If we have an array, load the items
      if (Array.isArray(data)) {
        for (const rawItem of data) {
          this.items.push(this.loadItem(rawItem));
        }
      } else if (Object.keys(data).length > 0) {
        this.items.push(this.loadItem(data));
      }
    }

    this.rowsLoaded = this.items.length;
    this.totalRows = this.slicing?.totalRows ?? this.rowsLoaded;
    return this.rowsLoaded;
  }

  /**
   * Load Item
   * Converts the raw into a strongly typed instance of T.
   * Uses the configured deserializer if defined, else just casts rawItem as T.
   * This is a good place to override if you need to do something custom....
   * @param rawItem
   */
  loadItem(rawItem: AnyHash): T {
    let item: T;
    if (this.deserializer) {
      item = this.deserializer(rawItem);
    } else {
      item = rawItem as T;
    }
    return item;
  }

  /**
   * All Items
   * Returns a static array of all the items in the dataset.
   */
  allItems(): T[] {
    return this.items;
  }

  /**
   * First Item
   * Returns the first item in the dataset (or undefined if non exist)
   */
  firstItem(): T | undefined {
    let item: T | undefined;
    if (this.items.length > 0) {
      item = this.items[0];
    }
    return item;
  }
}
