<ngb-toast
  *ngFor="let toast of notifyService.toasts"
  [class]="toast.className"
  [header]="toast.title ?? ''"
  [autohide]="toast.delay > 0"
  [delay]="toast.delay"
  (hidden)="notifyService.remove(toast)"
>
  <div class="d-flex">
    <div class="me-auto">{{toast.message}}</div>
    <ng-container *ngIf="toast.canClose">
      <button type="button" class="btn-close" (click)="close(toast)" aria-label="Close"></button>
    </ng-container>
  </div>
</ngb-toast>
