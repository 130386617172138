import {Component} from "@angular/core";
import {PlayButtonController} from "./play-button.controller";

/**
 * Renders a big round play button with play icon in the center.
 */
@Component({
  selector: 'pv2-round-play-button',
  templateUrl: 'round-play-button.component.html',
  styleUrl: 'play-button.component.scss'
})
export class RoundPlayButtonComponent extends PlayButtonController {
}
