import { JsonObject, JsonProperty } from 'json2typescript';
import { PlayerUser } from './player-user.model';
import { AnyHash } from '@hidat/huijs-interfaces';
import { PlayerStation } from './player-station.model';
import {PlayerUserMix} from "./user-mix.model";

/**
 * Instance User
 * This is a hybrid instance/user which includes all the bookmarks, liked sets, etc. for the given instances user.
 */
@JsonObject('InstanceUser')
export class InstanceUser {
  // Instance ID
  @JsonProperty('id', Number, true)
  id = -1;

  // Guid
  @JsonProperty('user_id', String, true)
  userId: string | undefined = undefined;
  @JsonProperty('_bookmarked_count', Number, true)
  _bookmarkedCount = 0;
  @JsonProperty('_liked_count', Number, true)
  _likedCount = 0;
  @JsonProperty('_liked_duration', Number, true)
  _likedDuration = 0;
  @JsonProperty('_mix_count', Number, true)
  _mixCount = 0;

  @JsonProperty('_user', PlayerUser, true)
  _user: PlayerUser | undefined = undefined;
  @JsonProperty('_station', PlayerStation, true)
  _station: PlayerStation | undefined = undefined;

  //@TODO
  _bookmarks: AnyHash[] = [];
  _likedSets: AnyHash[] = [];
  @JsonProperty('_user_mixes', [PlayerUserMix], true)
  _userMixes: PlayerUserMix[] = [];
}
