import {Injectable, Injector} from "@angular/core";
import {AppControlBase} from "./app-control-base";
import {AppConfig} from "./app-config";

/**
 * App Control
 * Minimal concrete implementation of an App Control Service
 * Be sure to configure with an AppConfig object in your AppModule
 */
@Injectable({providedIn: "root"})
export class AppControlService extends AppControlBase {
  constructor(appConfig: AppConfig, injector: Injector) {
    super(appConfig, injector);
  }
}
