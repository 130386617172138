import {Injectable, Injector, OnDestroy} from "@angular/core";
import {catchError, finalize, map, Observable, of, Subject, Subscription, timer} from "rxjs";
import {Scheduler} from "../playback/scheduler";
import {TimelineService} from "./timeline.service";
import {DeviceStoreService} from "./device-store.service";
import {InstanceManagerService} from "../resources/instance-manager.service";
import {StationManagerService} from "../resources/station-manager.service";
import {PlayerEngineService} from "./player-engine.service";
import {EventTrackerService} from "./event-tracker.service";
import dayjs from "dayjs";
import {
  ContentToPlay,
  LoadErrorInfo,
  MediaToPlay,
  MixConfig,
  Playback,
  PlayerMix,
  PlayerSession,
  SkipStatus
} from "../models";
import {SessionsManagerService} from "../resources/sessions-manager.service";
import {
  HuiAudioPlayer,
  HuiMediaToPlay,
  HuiPlaybackErrorEvent,
  HuiPlaybackErrorTypes, HuiPlaybackProgressEvent, HuiPlaybackStatusEvent,
  HuiPlaybackStatusTypes, PSNotification, PSNotificationTypes
} from "@hidat/huijs-player";

/**
 * Playback Service
 * Orchestrates the interface between the audio player and the UI and backend.
 * 12/21/2023 - This service is too big!!!
 */
@Injectable({providedIn: "root"})
export class PlaybackService implements OnDestroy {
  // Services
  private session: SessionsManagerService;
  private playerEngine: HuiAudioPlayer;
  private timelineService: TimelineService;
  private deviceStore: DeviceStoreService;
  private instance: InstanceManagerService;
  private station: StationManagerService;
  private eventTracker: EventTrackerService;  // Need to define service....

  // Members
  public startedAt = new Date();
  public mixConfig: MixConfig = new MixConfig();
  public loading = false;
  public loaded = false;
  public showPlayOverlay = false;
  public skipCount = 0;
  public mixMode: string | undefined;
  public loadError: number | undefined;
  public loadErrorInfo: LoadErrorInfo | undefined;
  public restrictedAccessMode = false;

  // Currently playing mix
  public currentMix: PlayerMix | undefined;
  // Next mix to play, when current mix is finished.
  public nextMix: PlayerMix | undefined;
  // Alternate mix, 'left' side
  public leftMix: PlayerMix | undefined;
  // Alternate mix, 'right' side
  public rightMix: PlayerMix | undefined;
  public relatedMixes: PlayerMix [] | undefined;   // Assigned at the mix level

  private readonly kickstartFile = 'https://s3.amazonaws.com/open.splixer.com/silence.mp3';
  private masterScheduler: Scheduler;
  private currentContentPlaying: ContentToPlay | undefined;
  private _playMicBreaks = true;
  private _canToggleMicBreaks = true;
  private retrievingNextTracks = false;
  private kickstartStatus = 0;
  //private stationCode: string;
  private mixLoaded = false;    // Set to true once a mix has been loaded
  private loadErrorCount = 0;
  //private accessMode = 0;
  private skipEnabled = true;
  private canSkipAt: dayjs.Dayjs | undefined;

  // Available Subscription Sources
  public readonly steeringWheelChangedSource = new Subject<PlayerMix | undefined>();
  public readonly contentPathChangedSource = new Subject<PlayerMix>();
  public readonly notifier = new Subject<PSNotification>();

  // Player Subscriptions
  private playbackStatusSubscription$: Subscription | undefined;
  private playbackErrorSubscription$: Subscription | undefined;
  private contentStatusSubscription$: Subscription | undefined;
  private playbackProgressSubscription$: Subscription | undefined;
  private intervalTimerSubscription$: Subscription | undefined;

  // Listen Time spent Tracking
  private ltFirstStatusSeconds = 10;   // Send first status after...
  private ltSecondStatusSeconds = 20;  // Send second status after an additional...
  private ltThirdStatusSeconds = 30;  // Send third status after an additional...
  private ltFourthStatusSeconds = 90;  // Send all the remaining status every...

  private ltReportingSeconds = this.ltFirstStatusSeconds;
  private ltMaxChange = .8;   // If change in position is greater than this, then we are probably skipping around and reset
  private ltLastPosition = 0;
  private ltSecondsSinceLastStatus = 0;
  private ltMediaItemCount = 1;

  constructor(injector: Injector) {
    this.session = injector.get(SessionsManagerService);
    this.playerEngine = injector.get(PlayerEngineService);
    this.timelineService = injector.get(TimelineService);
    this.deviceStore = injector.get(DeviceStoreService);
    this.instance = injector.get(InstanceManagerService);
    this.station = injector.get(StationManagerService);
    this.eventTracker = injector.get(EventTrackerService);

    this.masterScheduler = new Scheduler();
    this.leftMix = undefined;
    this.rightMix = undefined;
    this.currentMix = undefined;
    this.setupSubscriptions();
  }

  public ngOnDestroy() {
    this.playbackStatusSubscription$?.unsubscribe();
    this.playbackErrorSubscription$?.unsubscribe();
    this.contentStatusSubscription$?.unsubscribe();
    this.playbackProgressSubscription$?.unsubscribe();
    this.intervalTimerSubscription$?.unsubscribe();
  }

  protected setupSubscriptions() {
    this.playbackStatusSubscription$ = this.playerEngine.playbackStatusSource.subscribe((playbackStatus) => {
      this.playbackStatusUpdated(playbackStatus);
    })

    this.playbackErrorSubscription$ = this.playerEngine.playbackErrorSource.subscribe((error) => {
      this.playbackErrorEncountered(error);
    })

    this.playbackProgressSubscription$ = this.playerEngine.playbackProgressSource.subscribe(pbProgress => {
      this.playbackProgressUpdated(pbProgress);
      this.checkIfContentChanged(pbProgress);
    })

    this.intervalTimerSubscription$ = timer(5000, 5000)
      .subscribe(() => {
        this.updateState();
      })
  }

  private playbackStatusUpdated(playbackStatus: HuiPlaybackStatusEvent) {
    const mediaToPlay = playbackStatus.source as MediaToPlay;
    if (playbackStatus.eventID == HuiPlaybackStatusTypes.STARTED) {
      this.showPlayOverlay = false;
      if (this.kickstartStatus == 1) {
        this.kickstartStatus = 2;
      }
      this.notifier.next({
        type: PSNotificationTypes.MIX_PLAYING,
        message: 'Content started playing.'
      })

    } else if (playbackStatus.eventID == HuiPlaybackStatusTypes.FINISHED) {
      //console.debug('Playback Finished: ', this.ltSecondsSinceLastStatus)
      // Update listen tracking
      this.updateListeningTime();
      this.onContentChanged(undefined, this.currentContentPlaying, mediaToPlay, false);

      if (this.kickstartStatus == 2) {
        this.kickstartStatus = 3;
        // Only start playback if we have content waiting, as we could have content in-flight
        if (this.masterScheduler.hasItemsToPlay()) {
          this.gotoNextItemToPlay();
        }
      } else {
        this.gotoNextItemToPlay();
      }
    } else if (playbackStatus.eventID == HuiPlaybackStatusTypes.FILE_LOADED) {
      //-- Reset the load error count since we have successfully loaded a file. --//
      this.loadErrorCount = 0;
      // Update listen tracking
      this.ltMediaItemCount = 1;
      this.ltSecondsSinceLastStatus = 0;
      this.ltLastPosition = 0;
      this.ltReportingSeconds = this.ltFirstStatusSeconds;
    }
  }

  private playbackProgressUpdated(pbProgress: HuiPlaybackProgressEvent) {
    // Are we moving forward in time
    if (pbProgress.position > this.ltLastPosition) {
      const change = pbProgress.position - this.ltLastPosition;
      if (change < this.ltMaxChange) {
        this.ltSecondsSinceLastStatus += change
      }
    }
    this.ltLastPosition = pbProgress.position;
    if (this.ltSecondsSinceLastStatus > this.ltReportingSeconds) {
      //console.debug('Listen Time: ', this.ltSecondsSinceLastStatus);
      this.ltMediaItemCount++;
      if (this.ltMediaItemCount === 2) {
        this.ltReportingSeconds = this.ltSecondStatusSeconds;
      } else if (this.ltMediaItemCount === 3) {
        this.ltReportingSeconds = this.ltThirdStatusSeconds;
      } else if (this.ltMediaItemCount === 4) {
        this.ltReportingSeconds = this.ltFourthStatusSeconds;
      }
      this.updateListeningTime();
    }
  }

  /**
   * Checks if the currently playing content has changed.
   * This is called from the Player Progress callback, so every couple of seconds.
   * @param pbProgress
   * @private
   */
  private checkIfContentChanged(pbProgress: HuiPlaybackProgressEvent) {
    let getNewAltPaths = false;

    // Check if playing content has changed
    if (pbProgress.source) {
      const mediaToPlay = pbProgress.source as MediaToPlay;
      const newContentToPlay = mediaToPlay.getContentPlayingAt(pbProgress.position) as ContentToPlay;
      if (newContentToPlay) {
        if (!this.currentContentPlaying) {
          // First Content Started
          this.currentContentPlaying = newContentToPlay;
          this.currentContentPlaying.startedAt = dayjs();

          this.onContentChanged(this.currentContentPlaying, undefined, mediaToPlay, false);
        } else if (newContentToPlay.id !== this.currentContentPlaying.id) {
          // Content Updated
          this.currentContentPlaying.finishedAt = dayjs();
          newContentToPlay.startedAt = dayjs();

          if (newContentToPlay) {
            getNewAltPaths = true;
            this.onContentChanged(newContentToPlay, this.currentContentPlaying, mediaToPlay, true);
          }
          this.currentContentPlaying = newContentToPlay;
        }
      } else {
        // Content Finished
        if (this.currentContentPlaying) {
          console.debug(this.currentContentPlaying.id, ' finished playing.')
          this.currentContentPlaying.finishedAt = dayjs();
          this.onContentChanged(undefined, this.currentContentPlaying, mediaToPlay, false);
          this.currentContentPlaying = undefined;
        }
      }
    }
  }

  private playbackErrorEncountered(error: HuiPlaybackErrorEvent) {
    console.log("Error: " + error.errorType + ' - ' + error.message);
    if (error.errorType === HuiPlaybackErrorTypes.LOAD) {
      if (error.message == '4' && this.loadErrorCount == 0) {
        this.loadErrorCount++;
      } else {
        console.log('Load Error, skipping: ' + this.loadErrorCount);
        // Mark the bad content as played so we don't try to play it again
        /*
        if (error.contentToPlay) {
          const contentToPlay = error.contentToPlay as ContentToPlay;
          this.session.markContentChanged(this.deviceStore.sessionId, undefined, contentToPlay.id, false);
        }*/

        this.loadErrorCount++;
        if (this.loadErrorCount < 10) {
          this.gotoNextItemToPlay()
        } else {
          this.notifier.next({
            type: PSNotificationTypes.LOAD_ERROR,
            message: 'Unable to find tracks to play.'
          })
        }
      }
    } else if (error.errorType === HuiPlaybackErrorTypes.PLAYBACK) {
      this.notifier.next({
        type: PSNotificationTypes.PLAYBACK_ERROR,
        message: 'Your device stopped playback from starting, please press the play button to start playback.'
      })
    } else {
      this.notifier.next({
        type: PSNotificationTypes.INFO,
        message: 'There was an error encountered.'
      })
    }
  }

  private updateListeningTime() {
    this.session.trackListeningTime(this.deviceStore.sessionId, this.ltSecondsSinceLastStatus).subscribe();
    this.ltSecondsSinceLastStatus = 0;
  }

  public togglePlayback() {
    if (this.playerEngine.paused || this.playerEngine.playbackStartError) {
      this.playerEngine.resume();
    } else {
      this.playerEngine.pause();
    }
  }

  public playMicBreaks(status?: boolean): boolean {
    if (status !== undefined) {
      this._playMicBreaks = status;
      this.masterScheduler.playMicBreaks(status);
    }
    return this._playMicBreaks;
  }

  public canToggleMicBreaks() {
    return this._canToggleMicBreaks;
  }

  // Called when we have detected the current content item has changed.
  private onContentChanged(contentToPlay: ContentToPlay | undefined, contentPlayed: ContentToPlay | undefined, mediaToPlay: MediaToPlay | undefined, getNewAltPaths: boolean): void {
    const contentToPlayId = contentToPlay?.id;
    const contentPlayedId = contentPlayed?.id;
    if (contentToPlay) {
      this.timelineService.addCurrentlyPlayingItem(contentToPlay, mediaToPlay, this.currentMix);
    }
    this.updateState();
    if (contentToPlay || contentPlayed) {
      this.session.markContentChanged(this.deviceStore.sessionId, contentToPlayId, contentPlayedId, getNewAltPaths).subscribe();
    }
  }

  /**
   * Is Playing
   * Returns true if the player is actively playing.
   * @returns {boolean}
   */
  isPlaying(): boolean {
    return this.playerEngine.playing;
  }

  public play(mediaItem: MediaToPlay) {
    const pb = mediaItem.playback;
    if (pb) {
      let expiresAt: Date | undefined;
      if (pb.expiresAt) {
        expiresAt = pb.expiresAt.toDate();
      }
      const ttp = new HuiMediaToPlay(pb.url, mediaItem, pb.duration, expiresAt);
      this.playerEngine.play(ttp);
    }
  }
  /**
   * Can Skip
   * Returns true if the user can skip the current track.
   * We should actually put something here based around Soundexchange rules and the licensing on the current content.
   * @returns {boolean}
   */
  public canSkip() {
    return this.skipEnabled; //|| this.debugMode();
  }

  /**
   * Skips the current track.
   * This skips to the next audio FILE.  If a segment is made up of multiple files, this will skip within the segment.
   * Future: Have a setting at the segment level that would allow us to skip forward in the file.
   */
  public skipCurrent() {
    if (this.canSkip()) {
      if (this.currentContentPlaying) {
        this.session.contentSkipped(this.deviceStore.sessionId, this.currentContentPlaying.id)
          .subscribe((ds) => {
            if (ds) {
              const status = ds.firstItem();
              if (status) {
                this.setSkipStatus(status['skip_status']);
                this.eventTracker.contentSkipped();
              }
            }
          })
      }
      this.gotoNextItemToPlay();
    } else {
      const msg = 'Skip not allowed at this time.';
      this.notifier.next({type: PSNotificationTypes.OUT_OF_SKIPS, message: msg});
      console.warn(msg);
    }
  }

  /**
   * Skips to the next segment.
   */
  public skipSet() {
    if (this.canSkip()) {
      if (this.currentContentPlaying) {
        this.session.contentSkipped(this.deviceStore.sessionId, this.currentContentPlaying.id)
          .subscribe((ds) => {
            if (ds) {
              const status = ds.firstItem();
              if (status) {
                this.setSkipStatus(status['skip_status']);
                this.eventTracker.contentSkipped();
              }
            }
          })
      }
      this.gotoNextSegment();
    } else {
      const msg = 'Skip not allowed at this time.';
      this.notifier.next({type: PSNotificationTypes.OUT_OF_SKIPS, message: msg});
      console.warn(msg);
    }
  }

  /**
   * Updates the can_skip, can_skip_at, and skip_count from the given hash
   * @param skipStatus
   */
  private setSkipStatus(skipStatus: SkipStatus) {
    if (skipStatus) {
      this.skipEnabled = skipStatus.canSkip;
      this.skipCount = skipStatus.count;
      if (skipStatus.canSkipAt) {
        this.canSkipAt = skipStatus.canSkipAt;
      }
    }
  }

  /**
   * Goto Next Item
   * Skips to the next item to play.
   */
  gotoNextItemToPlay() {
    if (!this.isMixLoaded()) {
      return;
    }

    // See if we can find the next item  with a new url
    let skipToNextTrack: boolean | undefined;
    let trackAddedToPlayer = false;
    console.log('Going to next item')
    const ttp = this.masterScheduler.getNextItem();
    if (ttp && ttp.playback && ttp.playback.url && ttp.playback.url.length > 0) {
      this.play(ttp);
      trackAddedToPlayer = true;
      skipToNextTrack = false;
    }
    // Lets see if we just used the last segment, and if we did, kickoff load some more
    if (this.masterScheduler.needsItemsToPlay()) {
      // If we aren't in a hurry, lets delay for a couple of seconds to reduce any gap in playback....
      if (trackAddedToPlayer) {
        setTimeout(() => {
          this.getNextTracksFromServer(skipToNextTrack);
        }, 5000)
      } else {
        // We are out, panic!
        this.getNextTracksFromServer(skipToNextTrack);
      }
    }
  }

  /**
   * Goto Next Segment
   * Skips to the next segment (vs. item) to play.
   */
  gotoNextSegment(): void {
    if (this.masterScheduler.gotoNextSegment()) {
      this.gotoNextItemToPlay();
    } else {
      this.getNextTracksFromServer(true);
    }
  }

  /**
   * Get Next Tracks from Server
   * Asks the server for a bunch more content to play
   * @param skipToNewTracks If true, automatically skips to the first of the new tracks. If false, does not skip.  If undefined, checks if the player is playing, and if not, skips.
   */
  getNextTracksFromServer(skipToNewTracks: boolean | undefined): void {
    if (!this.retrievingNextTracks) {
      //console.info('Loading tracks: ', skipToNewTracks);
      this.session.getItemsToPlay(this.instance.instanceId, this.deviceStore.sessionId)
        .pipe(
          catchError((err) => {
            console.error(err);
            this.notifier.next({type: PSNotificationTypes.LOAD_ERROR, message: "There was an error loading content."});
            return of(undefined);
          }),
          finalize(() => {
            this.retrievingNextTracks = false;
          }))
        .subscribe((session) => {
          if (session) {
            if (skipToNewTracks === undefined) {
              console.debug('Tracks loaded,  playing:', this.playerEngine.playing);
              skipToNewTracks = !this.playerEngine.playing;
            }
            this.addServerResults(session, skipToNewTracks)
          }
          this.retrievingNextTracks = false;
        })
    }
  }

  /**
   * Clear Playlist
   * Clears all unplayed items from the current playlist.
   */
  public clearPlaylist(): void {
    this.masterScheduler.clearUnplayed();
  }

  /**
   * Creates or Updates the session with the new filter.
   * This will either create or update the session on the server, saving the ID's to local storage, and pulling back the
   * initial array of tracks to play.
   * @param {SessionFilter} mixConfig
   * @param contentPathUid
   * @param userMixId
   * @param clearPlaylist
   * @returns {Promise<any>}
   */
  upsertSession(mixConfig?: MixConfig, contentPathUid?: string, userMixId?: number, clearPlaylist = false): Observable<boolean> {
    this.notifier.next({
      type: PSNotificationTypes.MIX_LOADING,
      message: 'Loading/updating session.'
    })

    let p: Observable<PlayerSession | undefined> | undefined;
    //console.debug('starting session')
    if (this.instance.instanceId && this.deviceStore.hasSession()) {
      p = this.session.updateSession(this.instance.instanceId, this.deviceStore.sessionId, mixConfig, contentPathUid, userMixId);
    } else {
      p = this.session.createSession(this.station.stationCode, this.instance.instanceId, mixConfig, contentPathUid, userMixId);
    }
    if (p) {
      this.loading = true;
      return this.handlePlayStartResults(p, clearPlaylist);
      /*
      p
        .pipe(
          catchError((err) => {
            this.notifier.next({type: PSNotificationTypes.LOAD_ERROR, message: "There was an error loading the mix."});
            return of(undefined);
          }),
          finalize(() => {
            this.loaded = false;
            this.loading = false;
          })
        )
        .subscribe((session) => {
          if (session) {
            if (clearPlaylist) {
              this.masterScheduler.clearUnplayed();
            }
            this.addServerResults(session, true);
            this.loaded = true;
            this.loading = false;
            this.notifier.next({
              type: PSNotificationTypes.MIX_LOADED,
              message: 'Mix loaded.'
            })
          }
          return session;
        })*/
    }
    return of(false);
  }

  /**
   * Updates the session with the new filter.
   * This will either create or update the session on the server, saving the ID's to local storage, and pulling back the
   * initial array of tracks to play.
   * @param contentPathUid
   */
  playMix(contentPathUid?: string): Observable<boolean> {
    this.restrictedAccessMode = true;    // This isn't really true, but it's the easy way out right now
    if (this.instance.instanceId) {
      this.notifier.next({
        type: PSNotificationTypes.MIX_LOADING,
        message: 'Loading Mix.'
      })
      this.loading = true;
      let sessionId: string | undefined;
      if (this.deviceStore.hasSession()) {
        sessionId = this.deviceStore.sessionId;
      }

      // If we are currently playing, lets update the session before we switch playlists
      if (sessionId && this.ltLastPosition > 0 && this.ltSecondsSinceLastStatus > 10) {
        this.updateListeningTime();
      }

      const p = this.session.playContentPath(this.instance.instanceId, sessionId, this.station.stationCode, contentPathUid);
      return this.handlePlayStartResults(p);
    } else {
      return this.upsertSession(undefined, contentPathUid, undefined, true);
    }
  }

  /**
   * Handles a playback start call results, loading in the track queue and kicking off playback
   * @param p
   * @param clearPlaylist
   */
  handlePlayStartResults(p: Observable<PlayerSession | undefined>, clearPlaylist = true): Observable<boolean> {
    return p.pipe(
      map(session => {
        if (session) {
          if (clearPlaylist) {
            this.masterScheduler.clearUnplayed();
          }
          this.addServerResults(session, true);
          this.loaded = true;
          this.loading = false;
          this.notifier.next({
            type: PSNotificationTypes.MIX_LOADED,
            message: 'Mix loaded.'
          })
          return true;
        }
        return false;
      }),
      catchError((err) => {
        this.notifier.next({type: PSNotificationTypes.LOAD_ERROR, message: "There was an error loading the mix."})
        console.error(err);
        return of(false);
      }),
      finalize(() => {
        this.loaded = false;
        this.loading = false;
      })
    )
  }


  /**
   * Loads an existing session.
   * Only call this if we know we already have an instance.
   * This will load the filters and current path information for the current session.
   */
  loadSession(startPlayback = false, getAltPaths = false): Observable<PlayerSession | undefined> | undefined {
    let p: Observable<PlayerSession | undefined> | undefined;
    if (this.instance.instanceId) {
      p = this.session.loadSession(this.instance.instanceId, this.deviceStore.sessionId, startPlayback, getAltPaths)
        .pipe(
          catchError((err) => {
            console.error(err);
            this.notifier.next({type: PSNotificationTypes.LOAD_ERROR, message: "There was an error loading the mix."});
            return of(undefined);
          }),
          finalize(() => {
            this.loaded = false;
            this.loading = false;
          })
        );
      p.subscribe((session) => {
        if (session) {
          if (session.status > 0 && session.currentState) {
            this.addServerResults(session, startPlayback);
          }
        }
        this.loaded = true;
        this.loading = false;
      })
      this.loading = true;
    }
    return p;
  }

  gotoMix(mixUid: string): Observable<PlayerSession | undefined> | undefined {
    let p: Observable<PlayerSession | undefined> | undefined;
    if (this.instance.instanceId) {
      p = this.session.updateSession(this.instance.instanceId, this.deviceStore.sessionId, undefined, mixUid)
        .pipe(catchError((err) => {
          console.error(err);
          this.notifier.next({type: PSNotificationTypes.LOAD_ERROR, message: "There was an error loading the mix."});
          return of(undefined);
        }));

      p.subscribe((session) => {
        if (session) {
          if (session.mediaToPlay && session.mediaToPlay.length > 0) {
            this.masterScheduler.clearUnplayed();
            this.addServerResults(session, true);
          }
        }
      })
    }
    return p;
  }

  /**
   * Adds the results from the server to the session.
   * @param session - Session results from the server.
   * @param {boolean} skipToNext - Should  the system skip to the next track as soon as items have been loaded?
   */
  addServerResults(session: PlayerSession, skipToNext: boolean) {
    if (session.id) {
      // Check if we are starting a new instance, or the session id has changed, and update accordingly
      if (session._instance) {
        this.instance.load(session._instance);
      }

      this.deviceStore.updateSession(session.id)

      this.mixMode = session.mixMode;
      this.loadError = session.loadError;
      this.loadErrorInfo = session.loadErrorInfo;

      // Check if we ran into an error loading mix/content
      if (session.loadError) {
        return
      }

      if (session.currentState) {
        this.mixConfig = new MixConfig(session.currentState);
      }

      if (session._altPaths && session._altPaths.length > 0) {
        this.leftMix = session._altPaths[0];
        if (session._altPaths.length > 1) {
          this.rightMix = session._altPaths[1];
        }
      }
      if (this.leftMix == null) {
        this.leftMix = undefined;
      }

      if (this.rightMix == null) {
        this.rightMix = undefined;
      }

      // Check if we have a new content path, if so, let the world know
      if (session.currentPath) {
        if (!this.currentMix || (this.currentMix.uid != session.currentPath.uid)) {
          console.debug('New Content Path: ', session.currentPath.name)
          this.currentMix = session.currentPath;
          // Only assigned related mixes when the mix changes
          if (session._relatedContent) {
            this.relatedMixes = session._relatedContent;
          }
          this._playMicBreaks = true;
          this._canToggleMicBreaks = true;
          switch (this.currentMix.micBreakMode) {
            case 20:
              this._playMicBreaks = true;
              this._canToggleMicBreaks = false;
              break;
            case 100:
              this._playMicBreaks = false;
              this._canToggleMicBreaks = true;
              break;
            case 110:
              this._playMicBreaks = false;
              this._canToggleMicBreaks = false;
              break;
          }
          this.contentPathChangedSource.next(this.currentMix);
        }
      }

      if (session.mediaToPlay) {
        this.masterScheduler.addServerItemsToPlay(session.mediaToPlay);
      }

      if (session.skipStatus) {
        this.setSkipStatus(session.skipStatus);
      }

      this.mixLoaded = true;
      if (this.leftMix || this.rightMix || this.nextMix) {
        this.steeringWheelChangedSource.next(this.nextMix);
      }

      if (skipToNext) {
        this.startPlayback();
      }
    }
  }

  public startPlayback(): boolean {
    if (this.masterScheduler.hasItemsToPlay()) {
      this.gotoNextItemToPlay();
      return true;
    } else {
      this.notifier.next({type: PSNotificationTypes.NO_CONTENT, message: "No content found to play."});
      console.warn("No content found to play");
    }
    return false;
  }

  /**
   * Retry Playback
   * Retries playing the current track.
   * This is meant to be used when you encounter a playback error.
   */
  public retryPlayback(): void {
    console.info('Retrying to start playback.')
    this.playerEngine.retryPlay();
  }

  /**
   * Debug Helper function to return the current items to play.  Shouldn't be used in production.
   * @returns {MediaToPlay[]}
   */
  dbgItemsToPlay(): MediaToPlay[] {
    return this.masterScheduler.mediaToPlay;
  }

  /**
   * Kickstart Player
   * This is a HACK to get around mobile browser click limitations.
   * Call this the when the user clicks something, and if the player hasn't played anything, this will play a short
   * silence file, hopefully kickstarting the ability to control the player via JS.
   */
  kickStart() {
    if (this.kickstartStatus == 0) {
      this.kickstartStatus = 1;
      const itp = new MediaToPlay();
      itp.playback = new Playback();
      itp.playback.url = [this.kickstartFile];

      this.play(itp);
    }
  }

  /**
   * Updates the current state of the session.
   * Checks if we can skip again, and then calls the timeline's update function.
   * This is meant to be called off of a timer.
   */
  updateState() {
    // Check if the user can skip again
    if (!this.skipEnabled && this.canSkipAt?.isBefore(dayjs())) {
      this.skipEnabled = true;
    }
  }

  /**
   * Mix Loaded?
   * Returns true if a mix has been successfully loaded
   */
  isMixLoaded(): boolean {
    return this.mixLoaded;
  }


  /********* Steering Wheel Routines **********************************************************************************/

  setNextMix(nextMix: PlayerMix) {
    this.nextMix = nextMix;
    this.steeringWheelChangedSource.next(this.nextMix);
  }

  gotoNextMix() {
    if (this.nextMix && this.nextMix.uid) {
      this.gotoMix(this.nextMix.uid);
      this.nextMix = undefined;
    }
  }

  goLeft(immediate = false) {
    if (this.leftMix) {
      if (immediate && this.leftMix.uid) {
        this.gotoMix(this.leftMix.uid);
      } else {
        // Need to reset mix before 'setNextMix', as it sends out the message that the mix has changed
        const mix = this.leftMix;
        this.leftMix = undefined;
        this.setNextMix(mix);
      }
    }
  }

  goRight(immediate = false) {
    if (this.rightMix) {
      if (immediate && this.rightMix.uid) {
        this.gotoMix(this.rightMix.uid);
      } else {
        const mix = this.rightMix;
        this.rightMix = undefined;
        this.setNextMix(mix);
      }
    }
  }

}
