import {Component, OnInit} from "@angular/core";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {PlayPageController} from "./play-page.controller";
import {PlayerMix} from "@splixer/player";

/**
 * Play Mix - this is meant for remote links
 * We should be able to do this all in a guard or resolver, but for now....
 */
@Component({
  selector: 'pv2-mix-play-page',
  templateUrl: 'play.page.html'
})
export class Pv2MixPlayPage extends PlayPageController implements OnInit {

  ngOnInit() {
    // Data should be in the router
    this.activatedRoute.data.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(({mix}) => {
      console.debug('Mix fetched')
      this.loadMix(mix);
    })
  }

  private loadMix(results: PlayerMix) {
    if (results && results.uid) {
      this.loadingMessage = "Starting playback of your requested show";
      this.playMix(results.uid);
    } else {
      this.loadingMessage = "We're sorry, but we could not find the mix you asked for.";
    }
  }

}
