import {ChangeDetectionStrategy, Component, inject, Input, ViewEncapsulation} from "@angular/core";
import {ContentItem, StationManagerService} from "@splixer/player";
import * as dayjs from "dayjs";

/**
 * Displays the track info - name, artist, album, release date, label
 * Does NOT display album artwork.
 * If you don't want the startedAt date to display, don't pass it!
 */
@Component({
  selector: 'pv2-track-info',
  templateUrl: 'track-info.component.html',
  styleUrls: ['../player-v2-styles.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrackInfoComponent {
  @Input({required: true}) content: ContentItem | undefined;
  @Input() startedAt: dayjs.Dayjs | undefined;

  protected station = inject(StationManagerService);

  public startedAtFormatted(): string | undefined {
    let d: string | undefined;
    if (this.startedAt) {
      d = this.station.formatTime(this.startedAt);
    }
    return d
  }

}
