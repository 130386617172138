import {Injector} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {Location} from "@angular/common";
import {AppConfigInterface} from "./app-config.interface";

/**
 * App Control Base
 * Simple global class that can be used app wide so any component can communicate up and across the component stack.
 */
export class AppControlBase {
  private appName = "Your App Name";
  protected titleService: Title;
  protected location: Location;
  protected gaCode: string | undefined;

  constructor(protected config: AppConfigInterface, injector: Injector) {
    this.titleService = injector.get(Title);
    this.location = injector.get(Location);

    this.appName = config.appName;
    this.gaCode = config.gaCode;
  }

  public setPageTitle(pageName: string | undefined | null, logToAalytics = true) {
    let title = this.appName;
    if (pageName) {
      title = pageName + ' - ' + this.appName;
      if (this.gaCode && logToAalytics) {
        this.logPageView(pageName);
      }
    }
    this.titleService.setTitle(title);
  }

  /**
   * Log Page View
   * Override to implement page view tracking
   * @param pageName
   * @protected
   */
  protected logPageView(pageName: string): void {
  }


}
