import {Injectable, Injector} from "@angular/core";
import {map, Observable} from "rxjs";
import {DataSet, SerializationLevel} from "@hidat/huijs-api";
import {AnyHash} from "@hidat/huijs-interfaces";
import { PlayerApiService } from "./api/player-api.service";
import {MixConfig, PlayerSession, UserHash} from "../models";

/**
 * Session Manager
 * Provides an abstraction layer to the session api.
 * Eventually we will extend to include the session singleton, so it can become the source of truth for the session.
 */
@Injectable({providedIn: 'root'})
export class SessionsManagerService {
  private api: PlayerApiService;

  constructor(injector: Injector) {
    this.api = injector.get(PlayerApiService)
  }

  loadLink(linkCode: string, instanceId: string): Observable<DataSet<AnyHash>> {
    const q = {
      link_code: linkCode,
      instance_id: instanceId,
    };
    return this.api.sessions.put(['load_link'], q, SerializationLevel.None) as Observable<
      DataSet<AnyHash>
      >;
  }

  //@TODO: Does this return a PlayerSession?
  knock(
    accessCode: string,
    userInfo: UserHash,
    stationCode: string,
    instanceId: string
  ): Observable<DataSet<AnyHash>> {
    const payload = {
      access_code: accessCode,
      user: userInfo,
      station_code: stationCode,
      instance_id: instanceId,
    };
    return this.api.sessions.post(['knock'], payload, SerializationLevel.None) as Observable<
      DataSet<AnyHash>
      >;
  }

  createSession(
    stationCode: string,
    instanceID: string | undefined,
    mixConfig?: MixConfig,
    contentPathUid?: string,
    userMixId?: number
  ): Observable<PlayerSession | undefined> {
    let tz = '';
    let offset = 0;
    try {
      tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      offset = -new Date().getTimezoneOffset();
    } catch (e) {
      console.error('Exception while getting tz info');
    }
    const payload = {
      instance_id: instanceID,
      station_code: stationCode,
      tz: tz,
      tz_offset: offset,
      update: {
        mix_config: mixConfig,
        content_path_uid: contentPathUid,
        user_mix_id: userMixId,
      },
      include:
        'media_to_play,related_content,current_path,current_state,skip_status,instance',
    };
    return this.api.sessions.postOne(
      [],
      payload,
      SerializationLevel.Output
    ) as Observable<PlayerSession>;
  }

  updateSession(
    instanceID: string,
    sessionId: string,
    mixConfig?: MixConfig,
    contentPathUid?: string,
    userMixId?: number
  ): Observable<PlayerSession> {
    // May need to add 'userSettings' as a separate entity at some point.
    let tz = '';
    let offset = 0;
    try {
      tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      offset = -new Date().getTimezoneOffset();
    } catch (e) {
      console.error('Exception while getting tz info');
    }
    const payload = {
      instance_id: instanceID,
      tz: tz,
      tz_offset: offset,
      update: {
        mix_config: mixConfig,
        content_path_uid: contentPathUid,
        user_mix_id: userMixId,
      },
      include: 'media_to_play,related_content,current_path,current_state,skip_status',
    };

    return this.api.sessions.putOne(
      [sessionId],
      payload,
      SerializationLevel.Output
    ) as Observable<PlayerSession>;
  }

  /**
   * Play the given content path, specified by the given UID (not id....)
   * @param instanceID
   * @param sessionId
   * @param stationCode
   * @param contentPathUid
   */
  playContentPath(
    instanceID: string,
    sessionId: string | undefined,
    stationCode: string,
    contentPathUid: string | undefined
  ): Observable<PlayerSession> {
    let tz = '';
    let offset = 0;
    try {
      tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      offset = -new Date().getTimezoneOffset();
    } catch (e) {
      console.error('Exception while getting tz info');
    }
    const payload = {
      instance_id: instanceID,
      station_code: stationCode,
      tz: tz,
      tz_offset: offset,
      update: {
        content_path_uid: contentPathUid,
      },
      include: 'media_to_play,current_path,current_state,skip_status,related_content',
    };

    if (sessionId) {
      return this.api.sessions.put([sessionId], payload, SerializationLevel.Output).pipe(
        map((ds) => {
          return ds.firstItem() as PlayerSession;
        })
      );
    }
    return this.api.sessions.postOne(
      [],
      payload,
      SerializationLevel.Output
    ) as Observable<PlayerSession>;
  }

  /**
   * Load Session
   * Attempts to load the current session for the given instance.  If the session has expired, a new one will be returned.
   * @param instanceID
   * @param sessionId
   * @param getItemsToPlay
   * @param getAltPaths
   */
  loadSession(
    instanceID: string,
    sessionId: string,
    getItemsToPlay: boolean = false,
    getAltPaths = false
  ): Observable<PlayerSession> {
    let include = 'current_path,current_state,skip_status,instance,';
    if (getItemsToPlay) {
      include += ',media_to_play';
    }
    if (getAltPaths) {
      include += ',related_content';
    }
    const payload = {
      instance_id: instanceID,
      id: sessionId,
      schedule: getItemsToPlay,
      include: include,
    };
    return this.api.sessions.put(['load_session'], payload, SerializationLevel.Output).pipe(
      map((ds) => {
        return ds.firstItem() as PlayerSession;
      })
    );
  }

  saveMix(
    sessionId: string,
    mixName: string,
    overwriteExisting: boolean
  ): Observable<DataSet<AnyHash>> {
    const payload = {
      mix_name: mixName,
      overwrite_existing: overwriteExisting,
      include: 'current_path,current_state',
    };

    return this.api.sessions.put(
      [sessionId, 'save_mix'],
      payload,
      SerializationLevel.None
    ) as Observable<DataSet<AnyHash>>;
  }

  getItemsToPlay(
    instanceID: string | undefined,
    sessionId: string
  ): Observable<PlayerSession> {
    const payload = {
      instance_id: instanceID,
      include: 'media_to_play',
    };
    return this.api.sessions.put(
      [sessionId, 'get_next_items'],
      payload,
      SerializationLevel.Output
    ).pipe(
      map((ds) => {
        return ds.firstItem() as PlayerSession;
      })
    );
  }

  markContentChanged(
    sessionId: string,
    contentToPlayId: string | undefined,
    contentPlayedId: string | undefined,
    includeAltPaths = false
  ): Observable<DataSet<AnyHash>> {
    const payload = {
      content_to_play_id: contentToPlayId,
      content_played_id: contentPlayedId,
      get_alt_paths: includeAltPaths,
    };
    return this.api.sessions.put(
      [sessionId, 'content_changed'],
      payload,
      SerializationLevel.None
    ) as Observable<DataSet<AnyHash>>;
  }

  /**
   * Track Listening Time
   * @param sessionId
   * @param secondsListened
   */
  trackListeningTime(sessionId: string, secondsListened: number): Observable<DataSet<AnyHash>> {
    const payload = {
      seconds_listened: secondsListened,
    };
    return this.api.sessions.put(
      [sessionId, 'listen_time'],
      payload,
      SerializationLevel.None
    ) as Observable<DataSet<AnyHash>>;
  }

  contentSkipped(
    sessionId: string,
    contentLogId: string | undefined
  ): Observable<DataSet<AnyHash>> {
    const payload = {
      content_log_id: contentLogId,
      include: 'skip_status',
    };
    return this.api.sessions.put(
      [sessionId, 'content_skipped'],
      payload,
      SerializationLevel.None
    ) as Observable<DataSet<AnyHash>>;
  }

  createUser(instanceId: string, user: UserHash): Observable<DataSet<AnyHash>> {
    const payload = {
      instance_id: instanceId,
      user: user,
    };
    return this.api.sessions.post([], payload, SerializationLevel.None) as Observable<
      DataSet<AnyHash>
      >;
  }


  /**
   * Saves the current mix to the given name.
   * @returns {Promise<any>}
   */
  /* @TODO Figure out types
  saveMix(mixName: string, overwriteExisting = true): Observable<AnyHash> {
    this.eventTracker.mixSaved();
    const p = this.api.sessions.saveMix(this.deviceStore.sessionId, mixName, overwriteExisting);
    p.subscribe((results) => {
      // Add to the end of the current user mixes
      this.station.addUserMix(results.user_mix);
      // Update the current path information so we display the just saved name.
      this.addServerResults(results, false)
      return results;
    });
    return p;
  }*/

}
