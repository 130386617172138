import {Injectable, Injector} from "@angular/core";
import {PlayerApiService} from "./api/player-api.service";
import {map, Observable} from "rxjs";
import * as dayjs from "dayjs";
import {DayJsUtils} from "@hidat/huijs-dates";
import {PlayerHost, PlayerMix, PlayerStation, PlayerProgram, PlayerUserMix} from "../models";

/**
 * Station Service
 * Manages the current station configuration.
 * This takes a PlayerStation object, and loads/builds everything we need from that.
 * This is similar to the 'TenantSettings' class in Heavy Horses.
 */
@Injectable({providedIn: 'root'})
export class StationManagerService {
  protected api: PlayerApiService;
  public isLoaded = false;
  public isLoading = false;

  public id: string | undefined;
  public tenantId: string | undefined;
  public stationCode = "Not Loaded";
  public name: string | undefined;
  public tagLine: string | undefined;
  public description: string | undefined;
  public brandName: string | undefined;
  public status: number | undefined;
  public accessMode = 0; // Indicates if the station is protected or wide open
  public urlCode = "";   // Code to be used when constructing station specific URL - generally the station code lowercased, ie., /s/kexp/home
  public gaCode: string | undefined;    // Google Analytics Tracking Code
  public filtersLoaded = false;

  public hosts: PlayerHost [] = [];         // All Hosts

  // Broadcast
  public programs: PlayerProgram [] = [];   // Initial list of programs, for the home page
  public userMixes: PlayerUserMix [] = [];  // User mixes
  public mixes: PlayerMix [] = [];
  public featuredMixes: PlayerMix [] = [];  // All featured mixes

  constructor(injector: Injector) {
    this.api = injector.get(PlayerApiService);
  }

  /**
   * Load Station
   * Attempts to load the station with the given stationCode from the api.
   * Returns true on success, false if there was a problem.
   * @param stationCode
   */
  public loadStation(stationCode: string): Observable<PlayerStation | undefined> {
    this.isLoading = true;
    return this.api.stations.getOne([stationCode], true, {include: 'hosts,broadcast_programs[host,mix_count,last_show_mix],featured_mixes[program]'})
      .pipe(map((playerStation) => {
        if (playerStation) {
          this.load(playerStation as PlayerStation);
        }
        this.isLoaded = true;
        this.isLoading = true;
        return playerStation;
      })) as Observable<PlayerStation | undefined>;
  }


  /**
   * Loads the station service from the given API hash.
   * @param playerStation The station to load
   */
  protected load(playerStation: PlayerStation | undefined) {
    if (playerStation != null) {
      // Make a shallow copy of the results
      this.filtersLoaded = false;
      this.id = playerStation.uid;
      if (playerStation.stationCode) {
        this.stationCode = playerStation.stationCode;
        this.urlCode = this.stationCode.toLowerCase();
      }
      this.name = playerStation.name;
      if (playerStation.brandName) {
        this.brandName = playerStation.brandName;
      } else {
        this.brandName = this.name;
      }
      this.gaCode = playerStation.gaCode;
      this.tagLine = playerStation.tagLine;
      this.description = playerStation.description;
      this.status = playerStation.status;
      this.accessMode = playerStation.accessMode;
      this.filtersLoaded = true;
      this.hosts = playerStation._hosts || [];
      this.programs = playerStation._programs || [];
      this.mixes = playerStation._mixes || [];
      this.userMixes = playerStation._userMixes || [];
      this.featuredMixes = playerStation._featuredMixes || [];
    }
  }

  public clear() {
    this.isLoaded = false;
    this.isLoading = false;
    this.filtersLoaded = false;
    this.id = undefined;
    this.stationCode = 'Not Loaded';
    this.urlCode = '';
    this.name = undefined;
    this.brandName = undefined;
    this.gaCode = undefined;
    this.tagLine = undefined;
    this.description = undefined;
    this.status = undefined;
    this.accessMode = 0;
    this.filtersLoaded = true;
    this.hosts = [];
    this.programs = [];
    this.mixes = [];
    this.userMixes = [];
    this.featuredMixes = [];
  }


  public formatMediumDate(d: dayjs.Dayjs | Date | undefined): string | undefined {
    let ds: string | undefined;
    if (d) {
      const formatter = new Intl.DateTimeFormat([], {dateStyle: 'medium'});
      ds = formatter.format(DayJsUtils.toJSDate(d));
    }
    return ds;
  }

  public formatMediumDateTime(d: dayjs.Dayjs | Date | undefined): string | undefined {
    let ds: string | undefined;
    if (d) {
      const formatter = new Intl.DateTimeFormat([], {dateStyle: 'medium', timeStyle: 'medium'});
      ds = formatter.format(DayJsUtils.toJSDate(d));
    }
    return ds;
  }

  public formatShortDate(d: dayjs.Dayjs | Date | undefined): string | undefined {
    let ds: string | undefined;
    if (d) {
      const formatter = new Intl.DateTimeFormat([], {dateStyle: 'short'});
      ds = formatter.format(DayJsUtils.toJSDate(d));
    }
    return ds;
  }

  public formatTime(d: dayjs.Dayjs | Date | undefined): string | undefined {
    let ds: string | undefined;
    if (d) {
      const formatter = new Intl.DateTimeFormat([], {timeStyle: 'medium'});
      ds = formatter.format(DayJsUtils.toJSDate(d));
    }
    return ds;
  }


  /*
    addUserMix(userMix) {
      this.userMixes.push(userMix);
    }
  */
}
