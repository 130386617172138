import {Injectable} from "@angular/core";
import {HuiHowlerPlayer} from "@hidat/huijs-player";

/**
 * Player Engine
 * A common player instance that can be shared between services and components.
 */
@Injectable({providedIn: 'root'})
export class PlayerEngineService extends HuiHowlerPlayer {
  constructor() {
    super();
  }
}
