import {Component, HostBinding} from "@angular/core";
import {NotifyService, ToastInfo} from "./notify.service";

@Component({
  selector: 'notify-component',
  templateUrl: 'notify.component.html',
})
export class NotifyComponent {
  @HostBinding('class') class = 'toast-container position-fixed top-0 end-0 p-3';
  @HostBinding('style') style =  'z-index: 1200';
  constructor(public notifyService: NotifyService) {
  }

  public close = (toast: ToastInfo) => {
    this.notifyService.remove(toast)
  }

}
