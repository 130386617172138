<div class="now-playing-page">
  <div class="column-content-container">
    @if (playbackService.currentMix) {
      <div class="current-mix">
        <pv2-current-mix [mix]="playbackService.currentMix"></pv2-current-mix>

        @if (displayPlaylists && timeline.nowPlaying && timeline.nowPlaying.content && !timeline.nowPlaying.content.default) {
          <div class="section-divider"><h4>Playlist</h4></div>
          <pv2-segment-timeline [timelineSegment]="timeline.nowPlayingSegment"
                                [currentTrack]="timeline.nowPlaying"
                                [displaySegmentInfo]="false"></pv2-segment-timeline>
        }

        @if (displayRelatedMixes && playbackService.relatedMixes && playbackService.relatedMixes.length > 0) {
          <div class="section-divider">
            <h4>Related Content</h4>
          </div>
          <pv2-mix-list [items]="playbackService.relatedMixes"></pv2-mix-list>
        }

      </div>
    } @else {
      <div class="silence-container">
        <div class="headline">Silence!</div>
        <div class="headline-2">
          <span class="w2">沈黙</span><br/>
          <span class="w1">silencio</span>
          <span class="w3">schweigen</span><br/>
          <span class="w4">tystnad!</span>
        </div>
        @if (displayRandomContent) {
          <div class="recommended-content">
            <div>How about listening to one of these?</div>
            <div>...Random list of Content...</div>
          </div>
        } @else {
          <div class="recommended-content">
            <a routerLink="../listen">Find Something!</a>
          </div>
        }
      </div>
    }
  </div>
  <div class="column-content-container">
    @if (timeline.timelineMixes.length > 0) {
      <div class="section-divider">
        <h4>Previously Played</h4>
      </div>
      <pv2-mix-list [items]="timeline.timelineMixes" [displayPlayButtons]="false"
                    [displayDurations]="false"></pv2-mix-list>
    }
  </div>
</div>
