import {Component, Injector, Input} from "@angular/core";
import {
  ContentItem,
  PlaybackService,
  StationManagerService,
  TimelineSegment,
  TimelineSegmentItem
} from "@splixer/player";
import {uString} from "@hidat/huijs-interfaces";

@Component({
  selector: 'pv2-current-track',
  templateUrl: 'current-track.component.html',
  styleUrls: ['../player-v2-styles.scss',  'current-track.component.scss']
})
export class CurrentTrackComponent {
  @Input() nowPlaying: TimelineSegmentItem | undefined;
  @Input() nowPlayingSegment: TimelineSegment | undefined;

  playback: PlaybackService;
  station: StationManagerService;

  imageUrl: string | undefined;
  content: ContentItem | undefined;
  headlineLeft: uString;
  headlineRight: uString;
  altContent: uString;

  constructor(injector: Injector) {
    this.playback = injector.get(PlaybackService);
    this.station = injector.get(StationManagerService);
    this.playback.contentPathChangedSource.subscribe(results => {
      this.buildComponent();
    })
  }

  ngOnChanges() {
    this.buildComponent();
  }

  /**
   * Builds the current information to be displayed.
   * The goal is to provide the most specific information available.
   * @protected
   */
  protected buildComponent() {
    let displayShowInfo = true;
    let displayHost = true;
    let imageUrl: uString;
    let contentImageUrl: uString;
    let mixName: uString;
    let showName: uString;
    let hostName: uString;
    let segmentName: uString;
    let broadcastedAt: uString;

    // Grab current mix from Playback Service, not nowPlaying, as it is assigned before nowPlaying
    const cm = this.playback.currentMix;
    if (cm) {
      mixName = cm.name;
      imageUrl = cm.imageUrl;
      displayShowInfo = cm.displayShow;
      displayHost = cm.displayHost;
    }

    const np = this.nowPlaying;
    if (np) {
      if (np.show) {
        showName = np.show.name;
        if (!imageUrl && np.show.imageUrl) {
          imageUrl = np.show.imageUrl;
        }
        hostName = np.show.hostName;
      }

      // If we don't have an image, lets see if we have a program
      if (cm && cm._program) {
        if (!imageUrl) {
          //@TODO: Once launched, switch to program.imageUrl?
          if (cm._program.branding?.avatarImageUrl) {
            imageUrl = cm._program.branding.avatarImageUrl;
          } else if (cm._program.imageUrl) {
            imageUrl = cm._program.imageUrl;
          }
        }
      }

      // If still no, image, let's try the host as last resort
      if (np.host && !hostName) {
        hostName = np.host.name;
        if (!imageUrl && np.host.imageUrl) {
          imageUrl = np.host.imageUrl;
        }
      }
      if (np.segment) {
        if (np.segment.name) {
          segmentName = np.segment.name;
        }
        if (np.segment.broadcastedAt) {
          broadcastedAt = this.station.formatMediumDate(np.segment.broadcastedAt);
        }
      }

      if (np.content && !np.content.default) {
        this.content = np.content;
        if (np.content.coverArtUrl) {
          contentImageUrl = np.content.coverArtUrl;
        }
      } else {
        this.content = undefined;
      }
    }

    // Now lets figure out what to display
    if (showName) {
      this.headlineLeft = showName
    } else if (mixName) {
      this.headlineLeft = mixName
    }

    if (displayHost && hostName) {
      this.headlineRight = hostName;
    } else {
      this.headlineRight = undefined;
    }

    if (!this.content) {
      if (segmentName) {
        this.altContent = segmentName;
        if (broadcastedAt) {
          this.altContent += ' from ' + broadcastedAt;
        }
      } else if (broadcastedAt) {
        this.altContent = 'From ' + broadcastedAt;
      }
    }
    if (contentImageUrl) {
      this.imageUrl = contentImageUrl;
    } else {
      this.imageUrl = imageUrl;
    }

  }

}
