import {Component, Input, OnChanges} from "@angular/core";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {nString} from "@hidat/huijs-interfaces";
import * as DOMPurify from 'dompurify';

const template = `
<div class="safe-html-wrapper">
    <div [innerHTML]="sanitizedHtml"></div>
</div>
`
@Component({
  selector: 'hng-safe-html',
  template
})
export class HngSafeHtmlComponent implements OnChanges {
  @Input() htmlString: nString;

  protected sanitizedHtml: SafeHtml | undefined;

  constructor(protected sanitizer: DomSanitizer) {
  }

  ngOnChanges() {
    if (this.htmlString) {
      this.sanitizedHtml = this.sanitizer.bypassSecurityTrustHtml(DOMPurify.sanitize(this.htmlString));
    }
  }

}
