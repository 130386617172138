import { v4 as uuidv4 } from 'uuid';

/**
 * UUID
 * A very simple guid type, and possibly some helper methods down the road.
 */
export type Uuid = string;
export function CreateUuid(): Uuid {
  const uuid = uuidv4() as unknown;

  return uuid as Uuid;
}
