import {Component, DestroyRef, inject, Input} from "@angular/core";
import {Pv2ContentMenuItem} from "../menu-item.interface";
import {PlaybackService} from "@splixer/player";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Component({
  selector: 'pv2-playable-list-item',
  templateUrl: 'playable-list-item.component.html',
  styleUrls: ['../../../../styles/content-list.scss'],
})
export class PlayableListItemComponent {
  @Input() item: Pv2ContentMenuItem | undefined;
  @Input() displayPlayButton = true;
  @Input() displayDuration = true;
  protected playbackService = inject(PlaybackService);
  protected destroyRef = inject(DestroyRef);

  playItem = (event?: Event) => {
    if (event) {
      event.preventDefault();
    }
    if (this.item) {
      console.debug('Play ', this.item.contentId)
      this.playbackService.playMix(this.item.contentId).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(res => {
        console.debug('Started playing ', this.item?.contentId)
      })
    }
  }

}
