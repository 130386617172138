import {ChangeDetectionStrategy, Component, Input, OnChanges, ViewEncapsulation} from "@angular/core";
import {Pv2ContentMenuItem} from "../menu-item.interface";

@Component({
  selector: 'pv2-button-menu',
  templateUrl: 'button-menu.component.html',
  styleUrl: 'button-grid-menu.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonMenuComponent implements OnChanges {
  @Input({required: true}) items: Pv2ContentMenuItem [] | undefined;
  @Input() scrollOnMobile = true;
  @Input() largeOnMobile = false;

  protected menuItems: Pv2ContentMenuItem [] = [];
  protected gridKlasses: string [] = [];

  ngOnChanges() {
    this.buildMenu();
  }

  buildMenu() {
    this.gridKlasses = [];
    if (this.scrollOnMobile) {
      this.gridKlasses.push('scroll-on-mobile');
    }

    if (this.largeOnMobile) {
      this.gridKlasses.push('large-on-mobile');
    }

    const menuItems: Pv2ContentMenuItem [] = [];
    if (this.items) {
      for (const item of this.items) {
        menuItems.push(item);
      }
    }
    this.menuItems = menuItems;
  }

}
