import { JsonObject, JsonProperty } from 'json2typescript';
import {DayJSConverter} from "@hidat/ng-api";
import * as dayjs from "dayjs";
import {HostInterface} from "@splixer/interfaces";

/**
 * Host/DJ
 */
@JsonObject('PlayerHost')
export class PlayerHost implements HostInterface {
  @JsonProperty('id', Number, true)
  id: number | undefined = undefined;

  @JsonProperty('name', String, true)
  name: string | undefined = undefined;
  @JsonProperty('air_name', String, true)
  airName: string | undefined = undefined;
  @JsonProperty('image_url', String, true)
  imageUrl: string | undefined = undefined;

  @JsonProperty('duration', Number, true)
  duration: number | undefined = undefined;
  @JsonProperty('invalid_segments', Number, true)
  invalidSegments: number | undefined = undefined;
  @JsonProperty('station_id', Number, true)
  stationId: number | undefined = undefined;
  @JsonProperty('status', Number, true)
  status: number | undefined = undefined;

  @JsonProperty('created_at', DayJSConverter, true)
  createdAt: dayjs.Dayjs | undefined = undefined;
  @JsonProperty('updated_at', DayJSConverter, true)
  updatedAt: dayjs.Dayjs | undefined = undefined;
}
