<div class="modal-play-button-wrapper">
  <a class="modal-play-button" (click)="modalPlayEvent()">
    <div class="explanation">
      Autoplay has been disabled by your browser.<br>
      Click here to start playback.
    </div>
    <div class="big-icon">
      <fa-icon [icon]="faPlay"></fa-icon>
    </div>
  </a>
</div>
