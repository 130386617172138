.modal-play-button-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.80);
  color: white;
  transition: opacity 200ms;
  text-align: center;
  font-size: 1.5rem;

  a.modal-play-button {
    cursor: pointer;
    color: white;
    position: relative;
    top: 30%;
    color: white;

    .big-icon {
      margin: 2rem;
      svg {
        font-size: 6rem;
      }
    }
  }

  a.modal-play-button:hover {
    color: white;
  }
}
