import {Howl, Howler, HowlOptions} from 'howler';
import {interval, Subscription} from "rxjs";
import {HuiAudioPlayer} from "./audio-player";
import {HuiMediaToPlay, HuiPlaybackErrorTypes} from "./player-types";

export class HuiHowlerPlayer extends HuiAudioPlayer {
  protected sound: Howl | undefined;
  private playbackProgressTimer$ = interval(100);
  private progressSubscription: Subscription | undefined;
  private lastPosition = 0;

  constructor() {
    super();
    console.log('Loading Howler Player');
    this.init();
  }

  private startProgressTracking() {
    this.lastPosition = 0;
    if (this.progressSubscription == null) {
      //console.debug('Starting playback progress')
      this.progressSubscription = this.playbackProgressTimer$.subscribe((val) => {
        if (this.sound != null && this.mediaDuration > 0) {
          const position = this.sound.seek() as number;
          if (position !== this.lastPosition) {
            this.lastPosition = position;
            this._onPlaybackProgress(this.mediaDuration, position);
          }
        }
      })
    }
  }

  private stopProgressTracking() {
    if (this.progressSubscription != null) {
      //console.debug('Stopping playback progress')
      this.progressSubscription.unsubscribe();
      this.progressSubscription = undefined;
    }
  }

  private _createSound(playbackURL: string []): Howl {
    const config: HowlOptions = {
      src: playbackURL,
      volume: this.volume,
      html5: true,
      loop: false,
      preload: true,
      autoplay: true,
      mute: false,
      rate: this.currentPlaybackRate,

      onplay: (soundId: number) => {
        this._onPlay();
        this.startProgressTracking();
      },

      onplayerror: (soundId: number, errorMsg: unknown) => {
        if (typeof errorMsg === 'string') {
          this.handleError(HuiPlaybackErrorTypes.PLAYBACK, errorMsg);
        }
      },

      onpause: (soundId: number) => {
        this._onPaused();
        this.stopProgressTracking();
      },

      onload: () => {
        if (this.sound) {
          this._onLoaded(this.sound.duration());
          this.startProgressTracking();
        }
      },

      onloaderror: (soundId: number, errorMsg: unknown) => {
        if (typeof errorMsg === 'string') {
          this.handleError(HuiPlaybackErrorTypes.LOAD, errorMsg);
        }
        this.stopProgressTracking();
      },

      /* No longer supported?
      onresume: (soundId: number) => {
        this._onResumed();
        this.startProgressTracking();
      },*/

      onstop: (soundId: number) => {
        this._onStopped();
        this.stopProgressTracking();
      },

      onend: () => {
        this._onFinished();
        this.stopProgressTracking();
      },
/*
      onvolume: (soundId: number) => {
        console.debug('Volume Changed');
      },

      onrate: (soundId: number) => {
        console.debug('Rate Changed');
      },

      onseek: (soundId: number) => {
        console.debug('Seeking');
      },
*/
      onunlock: (soundId: number) => {
        console.log('Audio Unlocked');
      },

    }

    //-- Will this destroy the current sound? --//
    if (this.sound) {
      this.sound.unload();
      this.sound = undefined;
    }
    this.sound = new Howl(config)
    return this.sound
  }

  init(config = {}): boolean {
    if (typeof Howler === 'undefined') {
      alert('Please include Howler Library!');
      return false;
    }

    this.playerEngineReady = true;
    return true;
  }

  play(itemToPlay: HuiMediaToPlay): void {
    //console.log('Play: ', itemToPlay.playback?.url)
    if (this.playerEngineReady) {
      this.stop();
      const playbackURL = itemToPlay.getPlaybackURL()
      if (playbackURL && playbackURL.length > 0) {
        this.mediaToPlay = itemToPlay;
        this.sound = this._createSound(playbackURL);
        if (this.sound != null) {
          this.loadingSound();
        }
      }
    }
  }

  retryPlay(): void {
    if (this.playerEngineReady && this.mediaToPlay) {
      //this.stop();
      const playbackURL = this.mediaToPlay.getPlaybackURL()
      if (playbackURL && playbackURL.length > 0) {
        this.sound = this._createSound(playbackURL);
        if (this.sound != null) {
          this.loadingSound();
        }
      }
    }
  }

  stop(): void {
    //console.debug("Stopping player")
    if (this.sound != null) {
      this.sound.stop();
    }
  }

  togglePlayback(): void {
    if (this.playerEngineReady) {
      if (this._playbackStartError) {
        this.retryPlay();
      } else if (this.sound != null) {
        if (this._playing) {
          if (!this.reallyPlaying) {
            this.sound.pause();
            this.sound.play();
          } else if (this._paused) {
            //console.debug('Turning playback on');
            this.sound.play()
          } else {
            //console.debug('Pausing Playback')
            this.sound.pause()
          }
        }
      }
    }
  }

  /**
   * Returns 0 if never played
   * Returns 1 if currently playing
   * Returns 2 if playback has occurred, but not currently playing
   **/
  playbackStatus(): number {
    let status = 0;
    if (this.itemsPlayed > 0) {
      status = 2
      if (this._playing && !this._paused) {
        status = 1
      }
    }
    return status;
  }

  back(milliseconds: number): number {
    let newPosition = 0;
    if (this._playing && this.sound != null) {
      newPosition = this.sound.seek() as number * 1000;
      if (newPosition < milliseconds) {
        newPosition = 0;
      } else {
        newPosition = newPosition - milliseconds;
      }
      this.sound.seek(newPosition / 1000);
    }
    return newPosition;
  }

  forward(milliseconds: number): number {
    let newPosition = 0;
    if (this._playing && this.sound != null) {
      newPosition = this.sound.seek() as number * 1000;
      if (newPosition + milliseconds < this.sound.duration() * 1000) {
        newPosition = newPosition + milliseconds;
        this.sound.seek(newPosition / 1000);
      }
    }
    return newPosition;
  }

  position(): number {
    let position = 0;
    if (this._playing && this.sound != null) {
      const rp = this.sound.seek() as number;
      position = Math.round(rp * 10) / 10;
    }
    return position;
  }

  setPosition(timeAsMilliseconds: number): void {
    if (this._playing && this.sound != null) {
      this.sound.seek(timeAsMilliseconds / 1000);
    }
  }

  setPlaybackRate(rate: number): void {
    if (rate < 0.5) {
      rate = 0.5;
    } else if (rate > 4.0) {
      rate = 4.0;
    }
    this.currentPlaybackRate = rate;
    if (this.sound != null) {
      this.sound.rate(rate);
    }
  }

  pause(): void {
    if (this._playing && this.sound != null) {
      this.sound.pause();
    }
  }

  resume(): void {
    if (this.sound != null) {
      if (this._playing || (this._playbackStartError && (this.queuedToPlay || this.mediaToPlay))) {
        this.sound.play();
      }
    }
  }

}
