import {JsonObject, JsonProperty} from "json2typescript";
import {DayJSConverter} from "@hidat/ng-api";
import * as dayjs from "dayjs";
import {UserInterface} from "@splixer/interfaces";
import {AnyHash} from "@hidat/huijs-interfaces";
/**
 * Player User
 * This is a subset of the full user, as used by the player, vs. an admin....
 */
@JsonObject('PlayerUser')
export class PlayerUser implements UserInterface {
  @JsonProperty('id', String, true)
  id: string | undefined = undefined; //guid

  @JsonProperty('name', String, true)
  name: string | undefined = undefined;
  @JsonProperty('email_address', String, true)
  email: string | undefined = undefined;
  @JsonProperty('email_config', Object, true)
  emailConfig: AnyHash = {};
  @JsonProperty('external_id', String, true)
  externalId: string | undefined = undefined;

  @JsonProperty('current_station_id', Number, true)
  currentStationId: number | undefined = undefined;
  @JsonProperty('status', Number, true)
  status = 100;

  @JsonProperty('created_at', DayJSConverter, true)
  createdAt: dayjs.Dayjs | undefined = undefined;
  @JsonProperty('updated_at', DayJSConverter, true)
  updatedAt: dayjs.Dayjs | undefined = undefined;
}

/**
 * Simple interface used to send user info to API
 */
export interface UserHash {
  email_address: string,
  name: string,
  send_mail: boolean,
}
