import { JsonConverter, JsonCustomConvert } from 'json2typescript';
import {DayJsUtils} from "@hidat/huijs-dates";
import * as dayjs from "dayjs";

@JsonConverter
export class DayJSConverter
  implements JsonCustomConvert<dayjs.Dayjs | undefined>
{
  serialize(data: dayjs.Dayjs): string | undefined {
    let res: string | undefined;
    if (data) {
      res = DayJsUtils.toIsoDateStr(data);
    }
    return res;
  }

  deserialize(data: string | undefined): dayjs.Dayjs | undefined {
    let res;
    //console.log('Parsing: ', data);
    if (data) {
      res = DayJsUtils.toDayjs(data);
    }
    return res;
  }
}
