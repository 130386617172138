import {ChangeDetectionStrategy, Component, inject, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {Pv2ContentMenuItem} from "../menu-item.interface";
import {Router} from "@angular/router";
import {PlaybackService} from "@splixer/player";
import {AnyHash} from "@hidat/huijs-interfaces";
import {faPlay} from "@fortawesome/pro-regular-svg-icons/faPlay";

@Component({
  selector: "pv2-button-menu-item",
  templateUrl: 'button-menu-item.component.html',
  styleUrl: "button-grid-menu.scss",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonMenuItemComponent implements OnInit {
  @Input({required: true}) item: Pv2ContentMenuItem | undefined;

  protected readonly faPlay = faPlay;
  protected router = inject(Router);
  protected playbackService = inject(PlaybackService);
  protected buttonStyles: AnyHash = {};
  protected buttonClasses = "";

  ngOnInit() {
    //console.debug('menu it ', this.item?.id)
    if (this.item) {
      if (this.item.backgroundImage) {
        this.buttonStyles = {
          'background-image': "url('" + this.item.backgroundImage + "')"
        }
      }
      if (this.item.styleClass) {
        this.buttonClasses = this.item.styleClass;
      }
    }
  }

  protected clickEvent = (event: Event) => {
    if (event) {
      event.preventDefault();
    }
    if (this.item) {
      console.debug('button clicked')
      if (this.item.routerLink) {
        let link: string [];
        if (typeof this.item.routerLink === "string") {
          link = [this.item.routerLink];
        } else {
          link = this.item.routerLink;
        }
        this.router.navigate(link);
      }
    }
  }

  protected playEvent = (event: Event) => {
    if (event) {
      event.preventDefault();
    }
    if (this.item && this.item.playable && this.item.contentId) {
      this.playbackService.playMix(this.item.contentId)
        .subscribe(res => {
          if (res) {
            this.buttonClasses += "playing";
          }
        })
    }
  }
}
