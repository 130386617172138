import { JsonConvert } from 'json2typescript';
import { Observable } from 'rxjs';
import { UserHash } from '../../models/player-user.model';
import { HttpApiInterface } from '@hidat/ng-api';
import { Injector } from '@angular/core';
import { PlayerInstance } from '../../models/player-instance.model';
import {
  AnyHash,
  StringHash,
} from '@hidat/huijs-interfaces';
import {DataSet, SerializationLevel} from "@hidat/huijs-api";

export class InstancesApiInterface extends HttpApiInterface<PlayerInstance> {
  constructor(injector: Injector, baseUrl: string, jsonConvert: JsonConvert) {
    super(injector, baseUrl, 'instances', jsonConvert, PlayerInstance);
  }

  sendFeedback(
    instanceId: string,
    message: string,
    stationId: string | undefined
  ): Observable<AnyHash> {
    const payload = {
      message: message,
      station_id: stationId,
    };
    return this.postOne(
      [instanceId, 'feedback'],
      payload,
      SerializationLevel.None
    ) as Observable<AnyHash>;
  }

  destroyUserMix(
    instanceId: string,
    mixId: number
  ): Observable<DataSet<AnyHash | undefined>> {
    const url = [instanceId.toString(), 'destroy_mix', mixId.toString()];
    return this.delete(url, false) as Observable<DataSet<AnyHash | undefined>>;
  }

  destroyUserTag(
    instanceId: string,
    tagId: number
  ): Observable<DataSet<AnyHash>> {
    const url = [instanceId.toString(), 'destroy_tag', tagId.toString()];
    return this.delete(url, false) as Observable<DataSet<AnyHash>>;
  }

  archiveUserTag(
    instanceId: string,
    tagId: number
  ): Observable<DataSet<AnyHash>> {
    const url = [instanceId, 'archive_tag'];
    const payload = {
      user_tag_id: tagId,
    };
    return this.put(url, payload, SerializationLevel.None) as Observable<
      DataSet<AnyHash>
    >;
  }

  getAllBookmarks(
    instanceId: string,
    includeArchived = false
  ): Observable<DataSet<AnyHash>> {
    const url = [instanceId, 'bookmarks'];
    const q: AnyHash = {
      includes: 'content',
    };
    if (includeArchived) {
      q['include_archived'] = 1;
    }
    return this.get(url, false, q) as Observable<DataSet<AnyHash>>;
  }

  tagResource(
    instanceId: string,
    tagType: string,
    resourceId: string
  ): Observable<DataSet<AnyHash>> {
    const q = {
      tag_type: tagType,
      resource_id: resourceId,
    };
    return this.post(
      [instanceId, 'tag_resource'],
      q,
      SerializationLevel.None
    ) as Observable<DataSet<AnyHash>>;
  }

  flagSegment(
    instanceId: string,
    segmentId: number,
    flagType: string
  ): Observable<DataSet<AnyHash>> {
    const q = {
      flag_type: flagType,
      segment_id: segmentId,
    };
    return this.post(
      [instanceId, 'flag_set'],
      q,
      SerializationLevel.None
    ) as Observable<DataSet<AnyHash>>;
  }

  loadUserInfo(
    instanceId: string,
    includes?: string
  ): Observable<DataSet<AnyHash>> {
    let q: StringHash | undefined;
    if (includes) {
      q = {
        includes: includes,
      };
    }
    return this.get([instanceId, 'user'], false, q) as Observable<
      DataSet<AnyHash>
    >;
  }

  //@TODO: what is userInfo?
  recoverUser(userInfo: UserHash): Observable<DataSet<AnyHash>> {
    const q = {
      user: userInfo,
    };
    return this.post(['recover'], q, SerializationLevel.None) as Observable<
      DataSet<AnyHash>
    >;
  }

  sendProfileLink(instanceId: string): Observable<DataSet<AnyHash>> {
    return this.post(
      [instanceId, 'send_profile_link'],
      {},
      SerializationLevel.None
    ) as Observable<DataSet<AnyHash>>;
  }
}
