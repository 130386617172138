import {DestroyRef, Directive, EventEmitter, inject, Input, Output, SimpleChanges} from "@angular/core";
import {nString, uString} from "@hidat/huijs-interfaces";
import {faPlay} from "@fortawesome/pro-regular-svg-icons/faPlay";
import {PlaybackService} from "@splixer/player";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

/**
 * Playback button base controller
 *
 * Styling Variables:
 *   --play-button-background
 *   --play-button-color
 *   --play-button-hover-color
 *   --play-button-hover-background
 */
@Directive()
export class PlayButtonController {
  @Input() backgroundImage: nString;
  @Input() hint = "Play Show";
  @Input() mixId: uString;
  @Input() handlePlayback = false;
  @Output() buttonClicked = new EventEmitter();
  protected playbackService = inject(PlaybackService);
  protected destroyRef = inject(DestroyRef);

  protected faPlay = faPlay;
  protected buttonStyles = {}

  protected buttonClickedEvent = () => {
    if (this.handlePlayback && this.mixId) {
      this.playbackService.playMix(this.mixId).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(res => {
        console.debug('Started playing ', this.mixId)
      });
    }
    this.buttonClicked.emit();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['backgroundImage']) {
      if (this.backgroundImage) {
        this.buttonStyles = {
          'background-image': 'url("' + this.backgroundImage + '")',
          'background-position': 'center'
        }
      }
    }
  }

}
