import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HngCommonModule} from "@hidat/ng-common";
import {NgbToastModule} from "@ng-bootstrap/ng-bootstrap";
import {NotifyComponent} from "./notify/notify.component";

const components = [
  NotifyComponent
]

@NgModule({
  imports: [
    CommonModule,
    HngCommonModule,
    NgbToastModule
  ],
  declarations: components,
  exports: components
})
export class HngBootstrapModule {}
