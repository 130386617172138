import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {
  CurrentTrackComponent,
  ModalPlayButtonComponent, NowPlayingPageComponent,
  TrackInfoComponent,
  V2PlayerComponent
} from "./playback";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {HngCommonModule} from "@hidat/ng-common";
import {
  RoundPlayButtonComponent, ButtonMenuComponent, ButtonMenuItemComponent, MixDetailsComponent,
  MixListComponent,
  MobileBackLinkComponent, PlayableListComponent, PlayableListItemComponent,
  ProgramDetailsComponent,
  TagNavComponent, DurationPlayButtonComponent, MixListItemComponent
} from "./content";
import {RouterModule} from "@angular/router";
import {ProgramListItemComponent} from "./content/programs/list-item/program-list-item.component";
import {CurrentMixComponent} from "./playback/current-mix/current-mix.component";
import {SegmentTimelineComponent} from "./playback/timelines/segment-timeline.component";
import {TrackBannerComponent} from "./playback/track-info/track-banner.component";
import {Pv2MixPlayPage, Pv2ProgramPlayPage} from "./pages";

const components = [
  V2PlayerComponent,
  CurrentMixComponent,
  CurrentTrackComponent,
  TrackInfoComponent,
  MixListComponent,
  MixListItemComponent,
  MixDetailsComponent,
  ProgramDetailsComponent,
  RoundPlayButtonComponent, DurationPlayButtonComponent,
  ProgramListItemComponent,
  ModalPlayButtonComponent,
  TagNavComponent,
  ButtonMenuComponent,
  ButtonMenuItemComponent,
  SegmentTimelineComponent,
  TrackBannerComponent,
  MobileBackLinkComponent,
  PlayableListComponent, PlayableListItemComponent,
  NowPlayingPageComponent,
  Pv2MixPlayPage, Pv2ProgramPlayPage
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HngCommonModule,
    FontAwesomeModule,
  ],
  declarations: components,
  exports: components
})
export class PlayerUiV2Module {}
