import {Injectable, Injector} from '@angular/core';
import {DeviceStoreService} from '../services/device-store.service';
import {PlayerApiService} from './api/player-api.service';
import {StationManagerService} from './station-manager.service';
import {catchError, EMPTY, map, Observable, of, throwError} from 'rxjs';
import {AnyHash} from '@hidat/huijs-interfaces';
import {TimelineSegmentItem} from "../services/timeline.service";
import {PlayerInstance, PlayerUser} from "../models";

/**
 * Instance and User Service
 * This should probably be renamed 'Device Manager'
 * Manages the current 'instance', which is a browser/device based unique identifier.
 * This also manages the current user, since the user exists within the context of the instance.
 */
@Injectable({providedIn: 'root'})
export class InstanceManagerService {
  private deviceStore: DeviceStoreService;
  private api: PlayerApiService;
  private station: StationManagerService;

  private readonly recentListsSize = 12; // Number of items on the recent lists
  private _loaded = false;
  public instanceId: string | undefined;
  public userId: string | undefined;
  public user: PlayerUser | undefined;
  public userMixes = [];
  public likedSets = [];
  public likedCount = 0;
  public likedDuration = 0;
  public bookmarkedCount = 0;
  public mixCount = 0;
  public bookmarks: any[] = [];
  public recentBookmarks: any[] = [];

  constructor(injector: Injector) {
    this.deviceStore = injector.get(DeviceStoreService);
    this.api = injector.get(PlayerApiService);
    this.station = injector.get(StationManagerService);
  }

  /**
   * Loads the given instance.  Returns true on success, false on failure.
   * @param instanceId
   */
  public loadInstance(instanceId: string): Observable<boolean> {
    return this.api.instances
      .getOne([instanceId], true, {include: 'user'})
      .pipe(
        catchError((err) => {
          if (err && err.status === 404) {
            this.deviceStore.clear();
          }
          return of(undefined);
        }),
        map((results) => {
          if (results) {
            this.load(results as PlayerInstance);
            return true;
          } else {
            console.debug('Session not found, clearing it')
            this.deviceStore.clear();
          }
          return false;
        })
      );
  }

  /**
   * Loads the instance from the given api hash, and saves the instance id if it has changed.
   * @param instance
   */
  public load(instance: PlayerInstance): void {
    if (instance.id) {
      this.instanceId = instance.id;
      this.userId = instance.userId;

      if (instance._user) {
        this.user = instance._user;
      }
      if (this.deviceStore.updateInstance(instance.id)) {
        this.deviceStore.userId(this.userId);
      }
      this._loaded = true;
    }
  }

  /**
   * Valid Instance?
   * Returns true if there is current an instance loaded
   */
  public loaded(): boolean {
    return this._loaded;
  }

  /**
   * Send feedback
   * @param message
   */
  public sendFeedback(
    message: string
  ): Observable<AnyHash | undefined> | undefined {
    if (this.instanceId) {
      return this.api.instances.sendFeedback(
        this.instanceId,
        message,
        this.station.id
      );
    }
    return undefined;
  }

  /**
   * Removes the given bookmark from the local cache, resets the recentBookmarks if need be
   * If called without a bookmark, simply resets the recentBookmarks list
   * @TODO: What is 'bookmark' type
   * @param bookmark
   */
  private updateBookmarkCache(bookmark?: any) {
    if (bookmark) {
      const index = this.bookmarks.findIndex((item) => {
        return item.id == bookmark.id;
      });
      if (index >= 0) {
        this.bookmarks.splice(index, 1);
      }
    }

    // Rebuild recent bookmarks cache
    let i = 0;
    const recents = [];
    for (const item of this.bookmarks) {
      i++;
      if (i > this.recentListsSize) {
        break;
      }
      recents.push(item);
    }
    this.recentBookmarks = recents;
  }

  /* GIW
  flagSet(set: TimelineSegmentItem, flagType?: string): Promise<any> {
    return this.api.instances.flagSegment(this.instance.instanceId, set.segment.id, null).then((results) => {
      this.toasterService.info("Thanks for flagging this set, we'll have someone check it out.", 'Set Flagged');
    });
  }

  favoriteSet(set: TimelineSegmentItem) {
    this.logger.debug('Set Favorited: ', set.segment.id)
    this.api.instances.tagResource(this.instance.instanceId, 'favorite_set', set.segment.id).then((results) => {
      this.toasterService.info("Set liked!", 'Set Favorited');
    }, (error) => {
      this.toasterService.error('There was an error liking the set!');
      return (error);
    })
  }*/

  bookmarkTrack(item: TimelineSegmentItem): void {
    console.log('Item Bookmarked: ', item.segmentIndex);
    if (this.instanceId && item.playbackLogId) {
      this.api.instances.tagResource(this.instanceId, 'bookmark_content', item.playbackLogId).subscribe((results) => {
        //this.notify.success('Track saved!');
      }, (error) => {
        //this.notify.error('There was an error bookmarking the track!');
      })
    }
  }

  /**
   * Cache All Bookmarks
   * Gets all the bookmarks for the current user/instance.
   * Does a check to see if we currently have them, and does not refresh them if we do.
   * if 'force' is true, then the bookmarks will be reloaded not matter what....
   */
  /* GIW
  public cacheAllBookmarks(includeArchived = false, force = false) {
    const needToLoad = (force || (this.bookmarks.length < this.bookmarkedCount));
    if (needToLoad) {
      let p = this.api.getAllBookmarks(this.instanceId, includeArchived)
      p.then((results) => {
          this.bookmarks = results;
          this.bookmarkedCount = results.length;
          this.updateBookmarkCache();
          return results;
        },
        (error) => {
          this.notify.systemError("There was an error while retrieving the track.");
        })
    }
  }

  public destroyBookmark(userTag, notifyResults = true): Promise<any> {
    console.debug('Destroying Bookmark: ', userTag.id);
    let p = this.api.destroyUserTag(this.instanceId, userTag.id);
    p.then((results) => {
        this.bookmarkedCount--;
        this.updateBookmarkCache(results);
        if (notifyResults) {
          this.notify.success('Track Deleted!')
        }
        return results;
      }
      ,
      (error) => {
        if (notifyResults)
          this.notify.systemError("There was an error while deleting the track.");
      }
    );
    return p;
  }

  public archiveBookmark(userTag, notifyResults = true): Promise<any> {
    console.debug('Destroying Bookmark: ', userTag.id);
    let p = this.api.archiveUserTag(this.instanceId, userTag.id);
    p.then((results) => {
        this.bookmarkedCount--;
        this.updateBookmarkCache(results);
        if (notifyResults) {
          this.notify.success('Track Deleted!')
        }
        return results;
      }
      ,
      (error) => {
        if (notifyResults)
          this.notify.systemError("There was an error while deleting the track.");
      }
    );
    return p;
  }

  public destroyLikedSet(userTag, notifyResults = true): Promise<any> {
    let p = this.api.destroyUserTag(this.instanceId, userTag.id);
    p.then((results) => {
        // Try and delete the mix from the user mixes
        const index = this.likedSets.findIndex((item) => {
          return item.id == results.id
        });
        if (index >= 0) {
          this.likedSets.splice(index, 1);
        }
        if (notifyResults) {
          this.notify.success('Set Deleted!')
        }
        return results;
      }
      ,
      (error) => {
        if (notifyResults)
          this.notify.systemError("There was an error while deleting the set.");
      }
    );
    return p;
  }

  public destroyUserMix(userMix, notifyResults = true): Promise<any> {
    let p = this.api.instances.destroyUserMix(this.instanceId, userMix.id);
    p.then((results) => {
        // Try and delete the mix from the user mixes
        const index = this.userMixes.findIndex((item) => {
          return item.id == results.id
        });
        if (index >= 0) {
          this.userMixes.splice(index, 1);
        }
        if (notifyResults) {
          this.notify.success('Mix Deleted!')
        }
        return results;
      }
      ,
      (error) => {
        if (notifyResults)
          this.notify.systemError("There was an error while deleting the mix.");
      }
    );
    return p;
  }
  */

  /**
   * Loads the instance AND the user information.
   * This includes any custom mixes, tags, etc.
   * If a user has yet to be defined, then only the custom data associated with the instance is returned.
   * @param {string} instanceId
   * @param {string} userId
   * @param {string} includes
   * @returns {Promise<void>}
   */
  /* GIW
  public getUserInfo(instanceId: string, includes?: string) {
    return this.api.loadUserInfo(instanceId, includes).then(results => {
      this.instanceId = results.id;
      this.userId = results.user_id;
      this.user = results._user;
      this.station = results._station;
      this.mixCount = results._mix_count;
      this.userMixes = results._user_mixes;
      this.likedSets = results._liked_sets;
      this.likedCount = results._liked_count;
      if (results._liked_duration > 0) {
        this.likedDuration = (results._liked_duration / 3600);
      }
      this.bookmarkedCount = results._bookmarked_count;
      this.bookmarks = results._bookmarks;
      this.updateBookmarkCache();
      return (results);
    })
  }
   */
}
