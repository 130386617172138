import {ChangeDetectionStrategy, Component, Input, OnChanges} from "@angular/core";
import {
  PlayerMix,
  PlayerProgramBase,
  PlayerShowBase,
} from "@splixer/player";
import {uString} from "@hidat/huijs-interfaces";
import {FlatBrandedContent} from "@splixer/player-ui-v2";

/**
 * A columnar focused rendering of the 'current-mix'.
 * Displays information about the mix, and associated program/show (if the mix has one...)
 * Does not display the playlist!
 */
@Component({
  selector: 'pv2-current-mix',
  templateUrl: 'current-mix.component.html',
  styleUrls: ['../player-v2-styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CurrentMixComponent implements OnChanges {
  @Input({required: true}) mix: PlayerMix | undefined;
  @Input() show: PlayerShowBase | undefined;

  protected flatItem: FlatBrandedContent | undefined;

  ngOnChanges() {
    this.buildComponent();
  }

  protected buildComponent() {
    if (this.mix) {
      this.flatItem = new FlatBrandedContent(this.mix);
    }
  }

}
