@if (item) {
  <div class="program-details">
    @if (displayName) {
    <div class="content-name">
      {{ item.name }}
    </div>
    }
    <div class="item-details">
      <div class="content-tag-line">{{ item.tagLine }}</div>
      <div class="host">
        {{ hostName }}
      </div>
      <div class="broadcast-time">
        {{ broadcastTimes }}
      </div>

      <div class="description" *ngIf="item.description">
        {{ item.description }}
      </div>
    </div>
  </div>
}
