import {JsonObject, JsonProperty, PropertyConvertingMode} from 'json2typescript';
import {DayJSConverter} from "@hidat/ng-api";
import * as dayjs from "dayjs";
import {ShowBrandingInterface, ShowInterface} from "@splixer/interfaces";
import {AnyHash, nString} from "@hidat/huijs-interfaces";
import {PlayerExtendedBranding} from "./extended-branding.model";

@JsonObject('PlayerShowBranding')
export class PlayerShowBranding extends PlayerExtendedBranding implements ShowBrandingInterface {
  @JsonProperty('credits', String, PropertyConvertingMode.IGNORE_NULLABLE)
  credits?: nString = undefined;
  @JsonProperty('performers', String, PropertyConvertingMode.IGNORE_NULLABLE)
  performers?: nString = undefined;
  @JsonProperty('urls', Object, PropertyConvertingMode.IGNORE_NULLABLE)
  urls?: AnyHash | undefined = undefined;
}

@JsonObject('PlayerShowBase')
export class PlayerShowBase implements ShowInterface {
  @JsonProperty('uid', String, true)
  id: number | undefined = undefined;
  @JsonProperty('status', Number, true)
  status = 100;
  @JsonProperty('created_at', DayJSConverter, true)
  createdAt: dayjs.Dayjs | undefined = undefined;
  @JsonProperty('updated_at', DayJSConverter, true)
  updatedAt: dayjs.Dayjs | undefined = undefined;

  //@ToDo The rest....
  @JsonProperty('name', String, true)
  name: string | undefined = undefined;
  @JsonProperty('tag_line', String, true)
  tagLine: string | undefined = undefined;
  @JsonProperty('description', String, true)
  description: string | undefined = undefined;
  @JsonProperty('image_url', String, true)
  imageUrl: string | undefined = undefined;
  @JsonProperty('extended', PlayerShowBranding, true)
  branding: PlayerShowBranding = {};
  @JsonProperty('host_name', String, true)
  hostName: string | undefined = undefined;

  @JsonProperty('started_at', DayJSConverter, true)
  startedAt: dayjs.Dayjs | undefined = undefined;
  @JsonProperty('ended_at', DayJSConverter, true)
  endedAt: dayjs.Dayjs | undefined = undefined;
  @JsonProperty('duration', Number, true)
  duration = 0;
  @JsonProperty('valid_segments', Number, true)
  validSegments: number | undefined = undefined;
  @JsonProperty('invalid_segments', Number, true)
  invalidSegments: number | undefined = undefined;

  @JsonProperty('channel_id', Number, true)
  channelId: number | undefined = undefined;
  @JsonProperty('host_id', Number, true)
  hostId: number | undefined = undefined;
  @JsonProperty('program_id', Number, true)
  programId: number | undefined = undefined;
  @JsonProperty('station_id', Number, true)
  stationId: number | undefined = undefined;
  @JsonProperty('remote_id', Number, true)
  remoteId: number | undefined = undefined;
  @JsonProperty('raw_show_id', Number, true)
  rawShowId: number | undefined = undefined;
}
