<footer>
  <div class="container-lg">
    <div class="d-md-flex">
      <div class="logo-col">
        <a aria-label="open knkx website" href="https://www.knkx.org" class="logo" target="_blank">
          <img src="assets/images/footer-logo.png" alt="KNKX Logo">
        </a>
        <div class="copyright">
          &copy; {{copyrightYear}} Pacific Public Media
        </div>
      </div>
      <div class="links-col">
        <div>
          <a href="mailto:odfeedback@knkx.org" target="_blank">Send Feedback</a>
        </div>
        <div>
          <a href="https://player.amperwave.net/856" target="_blank">Listen to KNKX Live</a>
        </div>
        <div>
          <a href="https://player.amperwave.net/854" target="_blank">Listen to Jazz 24 Live</a>
        </div>
      </div>
    </div>
  </div>

</footer>
