import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {PlayerProgram} from "@splixer/player";
import {nString, uString} from "@hidat/huijs-interfaces";

@Component({
  selector: 'pv2-program-details-component',
  templateUrl: 'details.component.html',
  styleUrl: 'details.component.scss'
})
export class ProgramDetailsComponent implements OnChanges {
  @Input({required: true}) item: PlayerProgram | undefined;
  @Input() displayName = false;

  protected hostName: uString;
  protected broadcastTimes: nString;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['item']) {
      this.setupProgram();
    }
  }

  private setupProgram() {
    console.debug('Loading program')
    if (this.item) {
      if (this.item._host?.name) {
        this.hostName = 'Hosted By ' + this.item._host.name;
      }
      const branding = this.item.branding;
      if (branding) {
        this.broadcastTimes = branding.broadcastTimes;
      }
    }
  }
}
