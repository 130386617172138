import {Inject, Injectable, LOCALE_ID} from "@angular/core";
import {formatNumber} from "@angular/common";

@Injectable({providedIn: "root"})
export class DisplayHelperService {

  constructor(@Inject(LOCALE_ID) protected locale: string) {
  }

  /**
   * Seconds to Minutes
   * Converts seconds to a nicely formatted H:MM:SS
   */
  public secondsToMinutes(passedSeconds: number | string | undefined | null, showFractions = false): string | undefined {
    let res: string | undefined = undefined;
    let seconds: number;

    if (passedSeconds == null) {
      return res;
    }

    //-- Lets make sure we are working with a number --//
    if (typeof passedSeconds === 'string') {
      seconds = parseFloat(passedSeconds);
      if (Number.isNaN(seconds)) {
        return res;
      }
    } else {
      seconds = passedSeconds;
    }

    if (seconds) {
      if (!showFractions) {
        seconds = Math.round(seconds);
      }
      let h = null;
      if (seconds >= 3600) {
        const hours = Math.floor(seconds / 3600);
        h = hours.toString() + ':';
        seconds = seconds % 3600;
      }
      const minutes = Math.floor(seconds / 60);
      const secondsPart = seconds % 60;
      res = minutes + ":";
      if (secondsPart < 10) {
        res += '0'
      }
      res += secondsPart;
      if (h) {
        if (minutes < 10) {
          res = h + '0' + res;
        } else {
          res = h + res;
        }
      }
    } else {
      res = '0:00';
    }
    return res;
  }

  /**
   * Hours or Minutes
   * Converts the given seconds to an approximate duration based on the total time:
   * Less then a minute, then 'x seconds'
   * Less than an hour, then 'x minutes'
   * else 'x hours'
   * If the time falls midway through a minute, and showMinuteFractions is true, or if the time falls midway through
   * an hour, then it will say x.5 minutes/hours
   * @param passedSeconds
   * @param showMinuteFractions
   */
  public hoursOrMinutes(passedSeconds: number | string, showMinuteFractions: boolean): string | undefined {
    let res: string | undefined = undefined;
    let seconds: number;
    const halfPastString = '.5';  //'\u00BD'

    if (passedSeconds == null) {
      return res;
    }

    //-- Lets make sure we are working with a number --//
    if (typeof passedSeconds === 'string') {
      seconds = parseFloat(passedSeconds);
      if (Number.isNaN(seconds)) {
        return res;
      }
    } else {
      seconds = passedSeconds;
    }
    seconds = Math.ceil(seconds);  // Make sure we have at least one second

    if (seconds) {
      if (seconds < 55) {
        // less than an minute
        res = Math.round(seconds).toString() + ' seconds';
      } else if (seconds < 3300) {
        // less than an hour
        let minutes = seconds / 60;
        const s = seconds % 60;
        let halfPast = false;

        if (showMinuteFractions) {
          if (s > 15 && s < 45) {
            halfPast = true;
            minutes = Math.trunc(minutes);
          } else if (s < 15) {
            minutes = Math.trunc(minutes);
          } else {
            minutes = Math.round(minutes);
          }
        } else {
          minutes = Math.round(minutes);
        }
        res = minutes.toString();
        if (halfPast) {
          res += halfPastString;
        }
        res += ' minutes'
      } else {
        let hours = seconds / 3600;
        const minutes = seconds % 3600;
        let halfPast = false;
        if (minutes > 15 && minutes < 45) {
          halfPast = true;
          hours = Math.trunc(hours);
        } else if (minutes < 15) {
          hours = Math.trunc(hours);
        } else {
          hours = Math.round(hours);
        }
        res = hours.toString();
        if (halfPast) {
          res += halfPastString;
        }
        res += ' hours'
      }
    }
    return res;
  }


  /**
   * Yes/No
   * Displays Yes or NO (or blank) for a boolean
   * @param value
   */
  public yesNo(value: boolean | undefined | null): string {
    if (value == null) {
      return '';
    }
    if (value) {
      return 'Yes';
    } else {
      return 'NO';
    }
  }

  public formatInt(value: number | undefined | null): string {
    if (value == null) {
      return '';
    }
    const d = Math.round(value);
    return d.toString();
  }

  /**
   * Format Decimal
   * Formats the given number using angulars formatNumber and the current locale.  Defaults to two places to the right.
   * @param value
   * @param digitsInfo  See Angular's formatNumber for definition
   */
  public formatDecimal(value: number | undefined | null, digitsInfo = "1.2-2"): string {
    if (value == null) {
      return '';
    }
    return formatNumber(value, this.locale, digitsInfo);
  }

  public secondsToHours(value: number | undefined | null, digitsInfo = "1.1-1"): string {
    if (value == null) {
      return '';
    }
    return formatNumber(value / 3600.0, this.locale, digitsInfo);
  }
}
