import {JsonObject, JsonProperty} from "json2typescript";
import {DayJSConverter} from "@hidat/ng-api";
import dayjs from "dayjs";
import {PlayerHost} from "./host.model";
import {PlayerShow} from "./show.model";
import {AnyHash} from "@hidat/huijs-interfaces";

/***********************************************************************************************************************
 * External and Internal Scheduler Models
 **********************************************************************************************************************/

@JsonObject('SchedulingSegment')
export class SchedulingSegment {
  @JsonProperty('id', Number, true)
  id: number | undefined = undefined;
  @JsonProperty('name', String, true)
  name: string | undefined = undefined;
  @JsonProperty('duration', Number, true)
  duration = 0;
  // When was the item originally broadcasted at
  @JsonProperty('broadcasted_at', DayJSConverter, true)
  broadcastedAt: dayjs.Dayjs | undefined = undefined;
  @JsonProperty('segment_type', String, true)
  segmentType: string | undefined = undefined;
  @JsonProperty('licensing_klass', String, true)
  licensingKlass: string | undefined = undefined;
}

/**
 * Content Item
 * The details/metadata about a piece of content.
 * This is for display purposes.
 */
@JsonObject('ContentItem')
export class ContentItem {
  @JsonProperty('name', String, true)
  name: string | undefined = undefined;
  @JsonProperty('performer_name', String, true)
  performerName: string | undefined = undefined;
  @JsonProperty('release_name', String, true)
  releaseName: string | undefined = undefined;
  @JsonProperty('label_name', String, true)
  labelName: string | undefined = undefined;
  @JsonProperty('release_date', String, true)
  releaseDate: string | undefined = undefined;
  @JsonProperty('cover_art_url', String, true)
  coverArtUrl: string | undefined = undefined;
  @JsonProperty('comment', String, true)
  comment: string | undefined = undefined;
  @JsonProperty('content_type', String, true)
  contentType: string | undefined = undefined
  @JsonProperty('default', Boolean, true)
  default = false;

  // When was the item originally broadcasted at
  @JsonProperty('broadcasted_at', DayJSConverter, true)
  broadcastedAt: dayjs.Dayjs | undefined = undefined;
}

/**
 * ContentToPlay
 * Represents a scheduled piece of content to be played
 */
@JsonObject('ContentToPlay')
export class ContentToPlay {
  @JsonProperty('id', String, true)
  id: string | undefined = undefined;   // Random GUID
  @JsonProperty('seq_num', Number, true)
  seqNum: number | undefined = undefined;
  @JsonProperty('scheduled_at', DayJSConverter, true)
  scheduledAt: dayjs.Dayjs | undefined = undefined;
  @JsonProperty('audio_offset', Number, true)
  audioOffset = 0;
  @JsonProperty('content', ContentItem, true)
  content: ContentItem | undefined = undefined;

  // Non-persisted items
  startedAt: dayjs.Dayjs | undefined;
  finishedAt: dayjs.Dayjs | undefined;
}

/**
 * Playback Item
 * Defines a media file to played.
 * If it is a timed URL, expiresAt is the time that it expires
 * URL's should be ordered in preference order
 */
@JsonObject('Playback')
export class Playback {
  @JsonProperty('media_item_id', Number, true)
  mediaItemId: number | undefined = undefined;
  @JsonProperty('duration', Number, true)
  duration = 0;
  @JsonProperty('expires_at', DayJSConverter, true)
  expiresAt: dayjs.Dayjs | undefined = undefined;
  @JsonProperty('url', [String], true)
  url: string [] = [];
}

/**
 * Media to Play
 * Defines a media item to play.
 * This is a media file, along with the content items in the media file.
 * This entity is both returned by the backend API scheduler, as well as used by the internal scheduler.
 * There are additional fields and methods for use by the internal scheduler.
 */
@JsonObject('MediaToPlay')
export class MediaToPlay /*extends HuiMediaToPlay*/ {
  @JsonProperty('id', String, true)
  id: string | undefined = undefined;   // Random GUID
  @JsonProperty('seq_num', Number, true)
  seqNum: number | undefined = undefined;
  @JsonProperty('media_type', String, true)
  mediaType: string | undefined = undefined;
  @JsonProperty('scheduled_at', DayJSConverter, true)
  scheduleAt: dayjs.Dayjs | undefined = undefined;

  // @ToDo  Subset of Segment
  @JsonProperty('item', SchedulingSegment, true)
  segment: SchedulingSegment | undefined = undefined;

  @JsonProperty('contents', [ContentToPlay], true)
  contents: ContentToPlay [] = [];

  @JsonProperty('host', PlayerHost, true)
  host: PlayerHost | undefined = undefined;
  @JsonProperty('show', PlayerShow, true)
  show: PlayerShow | undefined = undefined;
  @JsonProperty('playback', Playback, true)
  playback: Playback | undefined = undefined;

  // Non-persisted
  currentContentItem: ContentToPlay | undefined;
  currentContent: AnyHash | undefined;
  currentContentStart = 0;
  currentContentEnd = 0;

  getContentPlayingAt(position: number, assignCurrentContent = true): ContentToPlay | undefined  {
    if (!position || !this.contents) {
      return undefined;
    }

    if (position >= this.currentContentEnd || position < this.currentContentStart) {
      let bestCtp: ContentToPlay | undefined;
      let endsAt: number | undefined;
      for (const ctp of this.contents) {
        if (ctp.audioOffset != null && ctp.audioOffset > position) {
          endsAt = ctp.audioOffset;
          break;
        } else {
          bestCtp = ctp;
        }
      }

      if (bestCtp && assignCurrentContent) {
        this.currentContentItem = bestCtp;
        this.currentContent = bestCtp.content;
        this.currentContentStart = bestCtp.audioOffset ?? 0;
        this.currentContentEnd = endsAt ?? this.playback?.duration ?? this.currentContentStart;
      }

      return bestCtp;
    }
    return this.currentContentItem;
  }

}
