import { JsonObject, JsonProperty } from 'json2typescript';
import {DayJSConverter} from "@hidat/ng-api";
import * as dayjs from "dayjs";
import {PlayerMix} from "./player-mix.model";
import {PlayerHost} from "./host.model";
import {PlayerUserMix} from "./user-mix.model";
import {PlayerProgram} from "./program.model";
import {StationInterface} from "@splixer/interfaces";

/**
 * Player Station
 * A readonly station model that is return by the player API.
 * This is the current station, along with all the associated hosts/programs/mixes
 */
@JsonObject('PlayerStation')
export class PlayerStation {
  @JsonProperty('uid', String, true)
  uid: string | undefined = undefined;
  @JsonProperty('station_code', String, true)
  stationCode: string | undefined = undefined;
  @JsonProperty('created_at', DayJSConverter, true)
  createdAt: dayjs.Dayjs | undefined = undefined;
  @JsonProperty('status', Number, true)
  status = 100;

  @JsonProperty('name', String, true)
  name: string | undefined = undefined;
  @JsonProperty('description', String, true)
  description: string | undefined = undefined;
  @JsonProperty('tag_line', String, true)
  tagLine: string | undefined = undefined;
  @JsonProperty('brand_name', String, true)
  brandName: string | undefined = undefined;
  @JsonProperty('brand_url', String, true)
  brandUrl: string | undefined = undefined;

  @JsonProperty('timezone', String, true)
  timezone: string | undefined = undefined;
  @JsonProperty('image_url', String, true)
  imageUrl: string | undefined = undefined;
  @JsonProperty('favicon', String, true)
  favicon: string | undefined = undefined;
  @JsonProperty('ga_code', String, true)
  gaCode: string | undefined = undefined;
  @JsonProperty('access_mode', Number, true)
  accessMode = 1;



  @JsonProperty('hosts', [PlayerHost], true)
  _hosts: PlayerHost [] = [];
  @JsonProperty('mixes', [PlayerMix], true)
  _mixes: PlayerMix [] = [];
  @JsonProperty('featured_mixes', [PlayerMix], true)
  _featuredMixes: PlayerMix [] = [];

  @JsonProperty('user_mixes', [PlayerUserMix], true)
  _userMixes: PlayerUserMix [] = [];
  @JsonProperty('programs', [PlayerProgram], true)
  _programs: PlayerProgram [] = [];
}
