/**
 * API Interface Base
 * The base class for API Interfaces.
 * This class primarily deals with serializing/deserializing objects, and to define minimal functionality.
 */
import { Observable } from 'rxjs';
import { DataSet } from './client/data-set';
import { AnyHash, StringHash } from '@hidat/huijs-interfaces';

export enum SerializationLevel {
  None,
  Input,
  Output,
  Both,
}

export interface ApiInterface<T> {
  /***** Raw network access routines to be implemented **************************************************************/
  getRaw(
    fullUrl: string,
    responseType: string,
    queryParams?: StringHash,
    httpOptions?: AnyHash
  ): Observable<any>;

  putRaw(
    fullUrl: string,
    payload: any,
    responseType: string,
    queryParams?: StringHash,
    httpOptions?: AnyHash
  ): Observable<any>;

  postRaw(
    fullUrl: string,
    payload: any,
    responseType: string,
    queryParams?: StringHash,
    httpOptions?: AnyHash
  ): Observable<any>;

  deleteRaw(
    fullUrl: string,
    responseType: string,
    queryParams?: StringHash,
    httpOptions?: AnyHash
  ): Observable<any>;

  /**
   * URL For
   * Generates a fully qualified URL from the given array of url 'segments'
   */
  urlFor(urlSegments: string[]): string;

  /**
   * Processes the API Results
   * Exposed as a helper function for instances when we are getting HTTP results outside the API
   * @param rawJson
   * @param deserialize
   */
  processApiResults(
    rawJson: any,
    deserialize: boolean
  ): DataSet<T | AnyHash | undefined>;

  /**
   * Generic JSON Get
   * Sends a get request to the given URL under the current base URL.
   * Returns a DataSet.
   * Results can be optionally deserialized to T.
   * @param endpointUrl  An array of url segments to be added the endpoint to generate the final url
   * @param deserialize  If true, results will be deserialized to T
   * @param queryParams  Optional query params to pass through.
   */
  get(
    endpointUrl: string[],
    deserialize: boolean,
    queryParams?: StringHash
  ): Observable<DataSet<T | AnyHash | undefined>>;
  getOne(
    endpointUrl: string[],
    deserialize: boolean,
    queryParams?: StringHash
  ): Observable<T | AnyHash | undefined>;
  getMany(
    endpointUrl: string[],
    deserialize: boolean,
    queryParams?: StringHash
  ): Observable<(T | AnyHash | undefined)[] | undefined>;

  /**
   * Generic JSON Post
   * Sends a post request to the given URL under the current base URL.
   * Returns a DataSet.
   * Results can be optionally deserialized to T.
   * @param endpointUrl  An array of url segments to be added the endpoint to generate the final url
   * @param item  A resource object of T or a AnyHash to be passed as the payload
   * @param serialize
   * @param queryParams  Optional query params to pass through.
   */
  post(
    endpointUrl: string[],
    item: T | AnyHash,
    serialize: SerializationLevel,
    queryParams?: StringHash
  ): Observable<DataSet<T | AnyHash | undefined>>;
  postOne(
    endpointUrl: string[],
    item: T | AnyHash,
    serialize: SerializationLevel,
    queryParams?: StringHash
  ): Observable<T | AnyHash | undefined>;

  /**
   * Generic JSON Put
   * Sends a put request to the given URL under the current base URL.
   * Returns a DataSet.
   * Results can be optionally deserialized to T.
   * @param endpointUrl  An array of url segments to be added the endpoint to generate the final url
   * @param item  A resource object of T or a AnyHash to be passed as the payload
   * @param serialize
   * @param queryParams  Optional query params to pass through.
   */
  put(
    endpointUrl: string[],
    item: T | AnyHash,
    serialize: SerializationLevel,
    queryParams?: StringHash
  ): Observable<DataSet<T | AnyHash | undefined>>;
  putOne(
    endpointUrl: string[],
    item: T | AnyHash,
    serialize: SerializationLevel,
    queryParams?: StringHash
  ): Observable<T | AnyHash | undefined>;

  /**
   * Generic JSON Delete
   * Sends a delete request to the given URL under the current base URL.
   * Returns a DataSet, which may have no items in it, but should have counts/error info.
   * Results can be optionally deserialized to T.
   * @param endpointUrl  An array of url segments to be added the endpoint to generate the final url
   * @param deserialize  If true, results will be deserialized to T
   * @param queryParams  Optional query params to pass through.
   */
  delete(
    endpointUrl: string[],
    deserialize: boolean,
    queryParams?: StringHash
  ): Observable<DataSet<T | AnyHash | undefined>>;
}
