@if (content) {
  <div class="track-info">
    <div class="played-at-time">
      <span *ngIf="startedAt">{{ startedAtFormatted() }}</span>
    </div>
    <div class="metadata-wrapper">
      <div class="title-and-performer">
        <div class="track-name">{{ content.name }}</div>
        @if (content.performerName) {
        <div class="divider"> -by-</div>
        <div class="performer-name">{{ content.performerName }}</div>
        }
      </div>
      <div class="release-name">{{ content.releaseName }}</div>
      <div class="label">
        @if (content.releaseDate) {
          {{ content.releaseDate | slice:0:4 }}
          <span *ngIf="content.labelName"> - </span>
        }
        {{ content.labelName }}
      </div>
    </div>
  </div>
}
